import styled from 'styled-components';

export const Container = styled.div`
  @-webkit-keyframes loading-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
  }
  @keyframes loading-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }

  svg.RawSpinner-inner {
    width: 32px;
    animation: loading-spin 3s linear infinite;
  }

  .RawSpinner {
    height: 48px;
    width: 48px;
    padding: 8px;
    border: 0;
    border-radius: 32px;
    -ms-box-shadow: 0 1px 4px 0 rgba(0,0,0,.54);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.54);
    box-sizing: border-box;
  }

  .RawSpinner-inner-circle {
    stroke: #0091ea;
    fill: transparent;
    stroke-width: 24px;
    stroke-linecap: round;
    stroke-dasharray: 300;
    stroke-miterlimit: 10;
    stroke-dashoffset: 0;
  }
  .PageSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    box-sizing: border-box;
  }
`
