
import React from 'react';
import {connect} from 'react-redux';
import KeyboardControlled from '../../keyboardControlled';

import Tooltip from '../../uiElements/tooltip';

import QuestionDynamic from '../questionDynamic';
import { Button } from '../../../components/globalStyle';

import { OkWrapper, Label, QuestionWrapperDOM } from './style';

import isMobile from '../../../services/mobileDetect';
import translateService from '../../../services/translateService';

let translateDictionary = new translateService();
let t = translateDictionary.getTranslateFunction();

function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
        confirmAnswer( variableId, val , status, reference ){
            return dispatch({
                type: "SET_ANSWER",
                payload: {
                    variableId,
                    answer: val,
                    reference,
                    status
                }
            });
        },
        activeQuestion( blockId, variableId ){
            return dispatch({
                type: "NAV_GOTO_PUSH_URL",
                payload: { blockId, variableId }
            })
        },
        navigateNext(){
            return dispatch({
                type: "NAV_NEXT_VARIABLE",
                payload: { }
            });
				}
    }
}

class QuestionWrapper extends KeyboardControlled {
    constructor(props){
      super(props);

      let answers = this.props.interview.answers[ this.props.id ];
      let value = '';
      let status = false;

      // All variables at wrapper is stored at index 0
      // Loop in compexes are abstracted by internal computation of onChange
      // In individual varialbes, loop itens are stored at index 0 too
      if (answers && answers[0]) {
        value = answers[0].value;
        status = answers[0].status;
      }

      this.state = {
        value,
        status,
        scrollFocus: false,
        reference: {index: 0},
        connected: navigator.onLine
      };

      this.focus = this.focus.bind(this);
			this.onStart = this.onStart.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.onChange = this.onChange.bind(this);
      this.verifyScroll = this.verifyScroll.bind(this);
      this.onUpdateStatus = this.onUpdateStatus.bind(this);
      this.onRequestToSubmit = this.onRequestToSubmit.bind(this);
      this.doOnCallScrollUpdate = this.doOnCallScrollUpdate.bind(this);
      this.childComponentDidMount = this.childComponentDidMount.bind(this);
      this.toggleConnectionStatus = this.toggleConnectionStatus.bind(this);


      this.registerKey('Escape', () => {
        const curElem = document.activeElement;
        if( curElem && curElem.blur ) curElem.blur();
      }, true, true);

      this.registerKey('Tab', (e) => {
        //Focus on ok button if...
        //actual question not allow looping and is trying to focus on ok button
        if((!this.props.allowLooping && this.state.status && this.refs.confirmBtn && !e.shiftKey) ||
            //the ok button is focused and user is trying to focus on next element
            (this.refs.confirmBtn === document.activeElement && !e.shiftKey) ||
            //actual container is focused and user is trying to get out of it
            (this.refs.questionContainer === document.activeElement && e.shiftKey)) {
          e.preventDefault();
          this.focus();
        } else if (!this.state.status) {
          e.preventDefault();
        }
      }, false, true);
    }

    componentDidMount () {
      this.letUserSeeMe();
      window.addEventListener('offline', this.toggleConnectionStatus );
      window.addEventListener('online', this.toggleConnectionStatus );
      window.addEventListener('detect-scroll', this.verifyScroll);
      window.addEventListener('request-submit', this.onRequestToSubmit);
    }

    componentWillUnmount () {
      window.removeEventListener('offline', this.toggleConnectionStatus );
      window.removeEventListener('online', this.toggleConnectionStatus );
      window.removeEventListener('detect-scroll', this.verifyScroll);
      window.removeEventListener('request-submit', this.onRequestToSubmit);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if( prevProps.nav.currentVariable !== this.props.nav.currentVariable ) {
        this.letUserSeeMe();
      }
    }

    childComponentDidMount(ref) {
      this.childQuestionComponent = ref;
    }

    focus() {
      if(this.refs.confirmBtn) this.refs.confirmBtn.focus({preventScroll: true});
    }

    verifyScroll(e){
      const dom = this.refs.questionContainer;
      if( !dom ) return false;
      const top = dom.offsetTop ;
      if( top > e.detail.ref ) return this.toggleActiveStatus( false );
      const height = dom.getBoundingClientRect().height;
      if( top + height < e.detail.ref ) return this.toggleActiveStatus( false );
      this.setState({ focused: e.detail.focused });
      return this.toggleActiveStatus( true );
    }

    toggleConnectionStatus( evt ){
      this.setState({ connected: navigator.onLine });
    }

    doOnCallScrollUpdate(justUpdate) {
      window.dispatchEvent(new CustomEvent('request-scroll', {
        detail: {
          justUpdate,
          target: this.refs.questionContainer
        }
      }));
    }

    letUserSeeMe () {
			// if (this.isCurrentVariable()) {
			// 	if( this.props.onCallScroll ){
      //     this.doOnCallScrollUpdate();
			// 	}
			// } else {
      //   this.setState({ focused: false });
      // }
    }

    toggleActiveStatus( atScrollFocus ){
      if( atScrollFocus !== this.state.scrollFocus ) this.setState({ scrollFocus : atScrollFocus });
      if( atScrollFocus && !this.isCurrentVariable() ){
        this.props.activeQuestion( this.props.nav.currentBlock, this.props.navId );
      }
    }

    isCurrentVariable (literal) {
      if(literal) {
        return this.props.nav.currentVariable === this.props.navId;
      } else {
        return this.props.nav.currentVariable.startsWith(this.props.navId);
      }
		}

    isInterviewEnding() {
      return this.props.type === "QUESTION_END_BLOCK" && (!this.props.nav.nextBlock || !this.props.nav.nextBlock.block_id)
    }

		onStart(state){
			this.setState(state);
		}

    onChange(id, value, status, reference){
      this.setState({ status, value, reference });
    }

    onUpdateStatus(status) {
      this.setState({status});
    }

    onRequestToSubmit(evt) {
      let { nextHook, reason } = evt.detail

      if(this.isCurrentVariable()) {
        this.onSubmit(true, reason).then((canNavNext) => {
          if(canNavNext === true || nextHook.includes('prev') ) {
            window.dispatchEvent(new CustomEvent(nextHook))
          }
        }).catch(() => {
          if(nextHook.includes('prev') && this.isCurrentVariable(true)) {
            window.dispatchEvent(new CustomEvent(nextHook))
          }
        })
      }
    }

    submit(noNav, skipGroup) {
      return new Promise((resolve, reject) => {
        //this.setState({ scrollFocus: false });
        this.props.confirmAnswer(this.props.id, this.state.value, this.state.status, this.state.reference);
        setTimeout(() => {
          if(!noNav) {
            window.dispatchEvent(new CustomEvent('request-nav-next', { detail: { skipGroup } }))
          }
          resolve(true);
        }, 200);
      })
    }

    processSubmit(noNav, reason) {
      if(this.isInterviewEnding()) {
        if(reason !== 'nav') {
          this.props.onSubmitInterview()
        }
        return Promise.resolve(true)
      }

      if(typeof noNav !== 'boolean') noNav = false

      if(this.props.type === 'COMPLEX') {
        return this.childQuestionComponent.onRequestToSubmitValue().then((willGoOutComplex) => {
          if( (willGoOutComplex && this.state.status) || reason === 'ok' ) {
            return this.submit(noNav, true)
          } else if( !willGoOutComplex ) {
            this.isSubmiting = false
            return Promise.resolve(true)
          } else {
            return Promise.resolve(false)
          }
        }).catch(() => {
          this.setState({status: false});
        })
      } else {
        if(this.state.status) {
          return this.childQuestionComponent.onRequestToSubmitValue().then(() => {
            return this.submit(noNav);
          }).catch(() => {
            this.setState({status: false});
          })
        } else {
          return Promise.reject();
        }
      }
    }

    onSubmit(noNav, reason){
      if(this.isSubmiting) return Promise.reject()
      this.isSubmiting = true
      return this.processSubmit(noNav, reason).finally(flag => {
        this.isSubmiting = false
        return flag
      })
    }

    renderOk(isInterviewEnding) {
      //if (  this.props.active || this.state.scrollFocus  ) {
        const buttonLabel = this.props.type === "QUESTION_END_BLOCK" ? ( isInterviewEnding ? 'Send Interview' : 'Continue Interview' ) : this.props.labelButton ;
        let okMsg = t( buttonLabel ) || ( this.props.type !== "COMPLEX" ? t("Ok") : t("Continue") );
        let onSubmit = isInterviewEnding ? this.props.onSubmitInterview : this.onSubmit
        let enterMsg =  !isMobile &&
                        this.props.type !== "LT" &&
                        !this.props.isInjected &&
                        this.props.type !== "COMPLEX" &&
                        <Label className={!this.state.status? "disabled" : ""} color={this.props.color}>{t("or press")} <strong color={this.props.color}>{t("Enter")}</strong></Label>;
        return  <OkWrapper open={( this.state.scrollFocus )}>
                  <Button ref="confirmBtn" tabIndex="0" color={this.props.color} disabled={!this.state.status} onClick={() => onSubmit(false, 'ok')}>{ okMsg }</Button>
                  { this.props.type !== "COMPLEX" && enterMsg }
                </OkWrapper>
      //}
    }

    render(){
      const answers = this.props.interview.answers[ this.props.id ];
      const isInterviewEnding = this.isInterviewEnding();

			return  <QuestionWrapperDOM ref="questionContainer" color={this.props.color} tabIndex="0" onKeyDown={this._keyDown} onClick={this.requestFocus} >
                <QuestionDynamic
                  isInterviewEnding={isInterviewEnding}
                  onChange={this.onChange}
                  onSizeChange={this.doOnCallScrollUpdate}
                  onUpdateStatus={this.onUpdateStatus}
                  onGoNext={this.onSubmit}
                  onStart={this.onStart}
                  onRejectFocus={this.focus}
                  { ...this.props }
                  answers={ answers }
                  active={ this.props.active || this.state.scrollFocus }
                  focused={ this.state.focused }
                  isLoading={this.state.isLoading}
                  connectionStatus={this.state.connected}
                  childComponentDidMount={this.childComponentDidMount}
                >
                { this.renderOk(isInterviewEnding) }
                </QuestionDynamic>
								<Tooltip color={this.props.color} place={'bottom'} effect="solid" delayShow={100}/>
							</QuestionWrapperDOM>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(QuestionWrapper);
