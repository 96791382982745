import update from 'immutability-helper';

export const initialState = {
    interview: null,
    variables: null,
    services: null,
    conditions: null,
    blocks: null,
    answers: {}
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_INTERVIEW":
            let jsonRoot = action.payload;
            let interview = jsonRoot.interview;

            let variables = jsonRoot.variables;

            let blocks = interview.blocks.reduce(( result, block )=>{
                result[ block.id ] = block; return result;
            }, {});
            let conditions = jsonRoot.conditions.reduce(( result, cond )=>{
                result[ cond.id ] = cond; return result;
            }, {});

            let answers = action.payload.answers ? action.payload.answers : {};
            const services = jsonRoot.services || {};

            return update(state, {
                interview: { $set: interview },
                variables: { $set: variables },
                conditions: { $set: conditions },
                services: { $set: services },
                blocks: { $set: blocks },
                answers: { $set: answers }
            });
        case "SET_DATA":
            return update(state, {
              variables:{ [ action.payload.variableId ]: { data: { $set: action.payload.data } } }
            })
        case "SET_LIST":
            return update(state, {
              variables:{ [ action.payload.variableId ]: { list: { $set: action.payload.list } } }
            })
        case "SET_ANSWERS":
            return update(state, {
              answers: { $set: action.payload }
            });
        case "SET_ANSWER":
            return update(state, {
                answers: {
                  [ action.payload.variableId ]: {
                    $apply: (val) => {
                      // if is empty, create new entry, else, update actual setting the new value
                      if (!val) {
                        let arr = [];
                        arr[action.payload.reference.index] = {
                          value: action.payload.answer,
                          status: action.payload.status,
                          touched: action.payload.noTouch ? false : true
                        };
                        return arr;
                      } else {
                        let newArr = update([], {$merge: val});

                        newArr[action.payload.reference.index] = {
                          value: action.payload.answer,
                          status: action.payload.status,
                          touched: action.payload.noTouch ? false : true
                        }

                        return newArr;
                      }
                    }
                  }
                }
            });
        case "DROP_COMPLEX_INDEX": {
          let newState = state;
          let subVars = state.variables[action.payload.variableId].subVariables;
          subVars.forEach(id => {
            if(newState.answers[id]) {
              newState = update(newState, {
                answers: {
                  [ id ]: {
                    $splice: [[action.payload.index, 1 ]]
                  }
                }
              });
            }
          });
          return newState;
        }
        default:
            return state;
    }
};

export default rootReducer;
