
import moment from 'moment';

import DefaultRoutes from './DefaultRoutes';

/* Makes the interface between front-end data representation and API
 * representation.
 */
class ApiDataInterface {

  _getComplexVariables(variables) {

    let complexVars = []

    Object.keys(variables).forEach(id => {
      if(variables[id].type === 'COMPLEX'){
        complexVars.push(variables[id])
      }
    })

    return complexVars
  }

  _getComplexSubVariables(complexVar) {
    return complexVar.subVariables.map(subVar => subVar.id || subVar)
  }

  _getLocalComplexQtdOfLoops(id, answers) {
    if (answers[id] && answers[id][0] && answers[id][0].value) {
      return answers[id][0].value.length
    } else {
      return 0
    }
  }

  _getAPIComplexQtdOfLoops(id, answers) {
    if (answers[id] && answers[id].value) {
      return answers[id].value.length
    } else {
      return 0
    }
  }

  _createComplexAPIRepresentation(id, variables, answers) {
    let complex = [ ]
    let qtdOfLoops = this._getLocalComplexQtdOfLoops(id, answers)

    // Generate answers with complex labels
    if (variables[id].allowLooping && qtdOfLoops > 0) {
      // If has looping allowed and has been answered, set labels
      complex = [...answers[id][0].value]
    } else {
      //If no looping allowed, use complex label
      complex[0] = variables[id].label
    }

    return complex
  }

  _getVarRepresentation(id, answers) {
    let representation = { id }
    representation.answers = answers.raw
  }

  _isEmptyArray(arr) {
    let isAllNull = (arr) => {
      for (var i = 0; i < arr.length; i++) {
        if(arr[i] && Array.isArray(arr[i]) && arr[i].length > 0) return false
        else if(!Array.isArray(arr[i]) && arr[i] !== null && arr[i] !== '') return false
      }
      return true
    }

    return arr.length === 0 || isAllNull(arr)
  }

  answersToApi(variables, answers) {
    // Shallow copy of answers
    let toProcessAnswers = {...answers}
    let processedAnswers = { }

    // Process all complex first
    this._getComplexVariables(variables).forEach(item => {
      let subVars = this._getComplexSubVariables(item)
      let qtdLoops = this._getLocalComplexQtdOfLoops(item.id, answers)

      // if has at least one loop
      if (qtdLoops > 0) {
        // Create subVars representation
        subVars.forEach(subVarId => {
          // Generates api representation of subvar
          // Each loop will be a position into value array
          let subVar = [ ]

          if (!item.allowLooping) {
            if(answers[subVarId] && answers[subVarId][0]) {
              switch(variables[subVarId].type) {
                case 'DATE':
                  subVar = [...answers[subVarId][0].value.value]
                  if(subVar.lastItem === '') subVar.pop()
                  break
                case 'MUL':
                  subVar = [...answers[subVarId][0].value.raw]
                  break;
                default:
                  subVar = [...answers[subVarId][0].value.raw]
              }
            }
          } else {
            for (var i = 0; i < qtdLoops; i++) {
              if (answers[subVarId] && answers[subVarId][i] && answers[subVarId][i].value.raw){
                switch(variables[subVarId].type) {
                  case 'DATE':
                    subVar[i] = answers[subVarId][i].value.value[0]
                    break;
                  case 'MUL':
                    subVar[i] = answers[subVarId][i].value.raw.length === 1 ? answers[subVarId][i].value.raw[0] : [...answers[subVarId][i].value.raw]
                    break;
                  default:
                    subVar[i] = answers[subVarId][i].value.raw[0]
                }
              } else {
                subVar[i] = null
              }
            }
          }

          // Drop subVar from toProcessAnswers and add it to processedAnswers
          processedAnswers[subVarId] = subVar
          delete toProcessAnswers[subVarId]
        });
      } else {
        // if has no loops, just remove subvars from toProcessAnswers
        subVars.forEach(subVarId => {
          delete toProcessAnswers[subVarId]
        })
      }

      // Drop complex from toProcessAnswers
      // OBS: Complex labels isn't send to api
      delete toProcessAnswers[item.id]
    })

    // Process individual vars
    Object.keys(toProcessAnswers).forEach(id => {
      if (variables[id] && !this._isNavigationVariable(variables[id]) && toProcessAnswers[id] && toProcessAnswers[id][0] && toProcessAnswers[id][0].value.raw) {
        switch(variables[id].type) {
          case 'DATE':
            processedAnswers[id] = [...toProcessAnswers[id][0].value.value]
            if(processedAnswers[id].lastItem === '') processedAnswers[id].pop();
            break;
          case 'MUL':
            //MUL with multiple itens need to be sended in array of array
            processedAnswers[id] = toProcessAnswers[id][0].value.raw.length === 1 ? [toProcessAnswers[id][0].value.raw[0]] : [[...toProcessAnswers[id][0].value.raw]]
            break;
          default:
            processedAnswers[id] = [...toProcessAnswers[id][0].value.raw]
        }
      }
    })

    //Clean empty arrays and arrays with all items equal null or empty strings
    let processedIds = [...Object.keys(processedAnswers)]
    processedIds.forEach(id => {
      if(this._isEmptyArray(processedAnswers[id])) delete processedAnswers[id]
    })

    return processedAnswers;
  }

  _createLocalAnwerRepresentation(answer) {
    return {
      id: answer.id,
      status: answer.status !== undefined ? answer.status : true,
      value: {
        raw: answer.value,
        value: answer.value
      }
    }
  }

  _vectorToHash(arr, key) {
    let hash = { }

    arr.forEach(item => {
      hash[item[key]] = item
    })

    return hash
  }

  answersFromApi(variables, answers) {
    // Shallow copy of answers
    let processedAnswers = { }
    let toProcessAnswers = this._vectorToHash(answers, 'id')

    // process first complexes and its subvariables
    this._getComplexVariables(variables).forEach(item => {
      let subVars = this._getComplexSubVariables(item)

      subVars.forEach(subVarId => {
        processedAnswers[subVarId] = this._createLocalAnwerRepresentation(answers[subVarId])
        delete toProcessAnswers[subVarId]
      })

      delete toProcessAnswers[item.id]
    })

    // process individual answers
    Object.keys(toProcessAnswers).forEach(id => {
      processedAnswers[id] = this._createLocalAnwerRepresentation(answers[id])
    })

    return processedAnswers
  }

  _apiDateToRaw (date) {
    return moment(date, 'YYYY-MM-DD').utc().startOf('day').valueOf()
  }

  _convertVariableDataToAnswers(variable) {
    if( variable.data && variable.data.length ){

      let raw = []
      let value = []

      if (variable.type === "MUL") {
        variable.data[0].forEach( index => {
          raw.push(variable.list[index])
          value.push(index)
        })
      } else if (variable.type === "YN") {
        raw = [ variable.data[0] ]
        value = [ variable.data[0] === 0 ? 1 : 0 ]
      } else if (variable.type === "DATE") {
        variable.data.forEach(item => {
          value.push(moment(item, 'YYYY-MM-DD').format('DD/MM/YYYY'))
          raw.push(this._apiDateToRaw(item))
        })
      } else {
        raw = [...variable.data]
        value = [...variable.data]
      }

      if(variable.allowLooping && value.length > 0 && value.lastItem !== '') value.push('')

      const data = {
        value: { raw, value }
      };

      return data
    }
  }

  // Receives interview data from api and process variables depending on
  // application rules.
  _processInterviewVariablesFromApi(interview) {

    interview.variables.forEach((variable) => {
      // convert variableType attribute
      if(variable.type === undefined && variable.variableType !== undefined) {
        variable.type = variable.variableType
      }

      variable.data = this._convertVariableDataToAnswers(variable);
    })

    //var visibleBlocks = interview.interview.blocks.filter( block => block.is_visible && block.variables_ids.length );
    //let firstBlock = visibleBlocks[0];
    //let lastBlock = visibleBlocks[ visibleBlocks.length - 1 ];
    //let firstVar = interview.variables.find(variable => variable.id === firstBlock.variables_ids[0])
    //let lastVar = interview.variables.find(variable => variable.id === lastBlock.variables_ids[lastBlock.variables_ids.length - 1])

    interview.variables = interview.variables.reduce(( result, vars ) => {
        if(vars.type === 'COMPLEX' && vars.subVariables.length === 0) {
          interview.interview.blocks.forEach(block => {
            block.variables_ids = block.variables_ids.filter(id => id !== vars.id)
          })
        } else {
          result[ vars.id ] = vars;
        }
        return result;
    }, {});

    /*
    if(firstVar.type === "MSG" ||
      firstVar.type === "MESSAGE") {
      firstVar.intro = true
      firstVar.is_visible = false
      interview.variables['intro'] = firstVar
    }

    if(lastVar.type === "MSG" ||
      lastVar.type === "MESSAGE") {
      lastVar.finish = true
      lastVar.is_visible = false
      interview.variables['finish'] = lastVar
    }
    */

    Object.keys(interview.variables).forEach(id => {
      // if is complex and allow looping, disable looping in child variables,
      // also process complex mandatory flg
      if(interview.variables[id].type === "COMPLEX") {
        let hasMandatory = false;

        interview.variables[id].subVariables.forEach(subId => {
          // Disable looping if parent has looping enabled
          if(interview.variables[id].allowLooping) {
            interview.variables[subId].allowLooping = false;
          }

          // if not is mandatory and has mandatory child, fix it
          if(!hasMandatory && interview.variables[subId].mandatory) {
            hasMandatory = true;
          }

          interview.variables[subId].hasParent = true;
          interview.variables[subId].complexParent = id;
        });

        if(hasMandatory) {
          interview.variables[id].mandatory = true;
        }
      }

      // If is date variable, force date mask
      if(interview.variables[id].type === "DATE" && !interview.variables[id].mask) {
        interview.variables[id].mask = '99/99/9999';
        interview.variables[id].prohibitiveMask = true;
      }
      
      if (interview.variables[id].mask){
        interview.variables[id].prohibitiveMask = true;
      }
      
    });

    return interview;
  }

  _processInterviewConditionsFromApi(interview) {

    interview.conditions = interview.conditions.map(item => {
      item.condition.map(condition => {
        if(condition.variable_id_ax && condition.value) {
          switch (interview.variables[condition.variable_id_ax].type) {
            case 'DATE':
              condition.value = this._apiDateToRaw(condition.value);
              break;
            case 'NUM':
              condition.value = parseFloat(condition.value);
              break;
            default:
          }
        }

        return condition;
      });
      return item;
    });

    return interview;
  }

  _isNavigationVariable(variable) {
    return variable.isInjected
  }

  _getNavigationVariable(type, id) {

    let upperType = type.toUpperCase();
    //let lowerType = type.toLowerCase();

    if(['BLOCK', 'INTERVIEW'].indexOf(upperType) === -1) return;

    return {
      id: `end-block-` + id,
      type: `QUESTION_END_BLOCK`,
      question: `end-block-title`,
      description: `end-block-description`,
      labelButton: 'Continue Interview',
      isInjected: true
    }
  }

  _injectNavigationVariables(interview) {
    let injectedVar = {};
    let visibleBlocks = interview.interview.blocks.filter( block => block.is_visible && block.variables_ids.length );

    for (var i = 0; i < (visibleBlocks.length - 1); i++) {
      injectedVar = this._getNavigationVariable('block', visibleBlocks[i].id);

      interview.variables[injectedVar.id] = injectedVar;
      visibleBlocks[i].variables_ids.push(injectedVar.id);
    }

    injectedVar = this._getNavigationVariable('interview', visibleBlocks[visibleBlocks.length - 1].id);
    interview.variables[injectedVar.id] = injectedVar;
    visibleBlocks[visibleBlocks.length - 1].variables_ids.push(injectedVar.id);

    return interview;
  }

  interviewFromApi(interview) {

    interview.answers = interview.answers || { };

    interview = this._processInterviewVariablesFromApi(interview);
    interview = this._processInterviewConditionsFromApi(interview);
    interview = this._injectNavigationVariables(interview);

    // Setup default routes
    if(interview.setup && interview.setup.routes) {
      Object.keys(interview.setup.routes).forEach(route => {
        if(!interview.setup.routes[route].url && DefaultRoutes[route]) {
          interview.setup.routes[route].url = DefaultRoutes[route].url;
        }
      });
    }

    return interview;
  }
}

export default ApiDataInterface;
