import React, { Component } from 'react';

import styled from 'styled-components';
import globalStyle from '../../globalStyle';

const Bar = styled.div`
    display: none;
    position: fixed;
    top: 15px;
    bottom: 75px;
    width: 15px;
    right: 10px;
    border: 1px solid ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    background: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    border-radius: 10px;

    overflow: hidden;

    @media screen and ( ${ globalStyle.desktop } ){
        display: block;
    }
`

const Scroll = styled.div`
    position: absolute;
    border-radius: 10px;
    top: ${props=>(props.y * 100)}%;
    width: 15px;
    height: ${props=>(props.height * 100)}%;
    background: white;
    transition: height .2s;

`

export default class ScrollBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            dragging: false
        };
        this.stopDrag = this.stopDrag.bind(this);
        this.onDrag = this.onDrag.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mouseup', this.stopDrag);
    }

    componentWillUnmount () {
       window.removeEventListener('mouseup', this.stopDrag);
       window.removeEventListener('mousemove', this.onDrag);
    }

    cancelEvent(e){
        if (e === undefined) return false
        if (e.stopPropagation) e.stopPropagation()
        if (e.preventDefault) e.preventDefault()
        e.cancelBubble = true
        e.returnValue = false
        return false
    }

    startDrag(e){
        this.setState({ dragging: true });
        this.startPoint = e.pageY;
        if( this.props.onDragStart ){ this.props.onDragStart(); }
        window.addEventListener('mousemove', this.onDrag);

        this.cancelEvent(e);
    }
    stopDrag(e){
        this.setState({ dragging: false });
        this.endPoint = e.pageY;
        window.removeEventListener('mousemove', this.onDrag);
        this.cancelEvent(e);
    }
    onDrag(e){
        if( !this.state.dragging ) return false;

        this.endPoint = e.pageY;
        const totalSize = this.refs.scrollbar.getBoundingClientRect().height;
        const dist = ( this.startPoint - this.endPoint ) / totalSize;

        if( this.props.onDrag ){ this.props.onDrag( dist ); }
        this.cancelEvent(e);
    }

    scrollTo(e){
        const absY = e.target.offsetTop;
        const height = e.target.clientHeight - absY;
        const y = e.clientY - absY;

        const pos = ( y / height ) * this.props.contentSize * -1;

        if( this.props.onClick ){ this.props.onClick( pos ); }
        this.cancelEvent(e);
    }

    render(){
        return <Bar
                ref="scrollbar"
                onClick={(e)=>{ this.scrollTo(e) }}
                color={this.props.color}
            >
            <Scroll
                y={ this.props.propPos }
                height={ this.props.propSize }

                onMouseDown={(e)=>{ this.startDrag(e); }}
                onClick={(e)=>{ this.cancelEvent(e) }}
            ></Scroll>
        </Bar>
    }
}
