import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export default class IconList extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                        <defs>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <path fill={this.props.color.secondary} d="M4.788 16.678a1.078 1.078 0 0 1-1.48 0 .98.98 0 0 1-.021-1.406l7.205-6.849L11.982 7l1.497 1.416 7.212 6.862a.982.982 0 0 1 .022 1.41 1.087 1.087 0 0 1-1.505.02L11.982 9.84l-7.194 6.838z"/>
                        </g>
                    </svg>
                </Wrapper>;
    }
}