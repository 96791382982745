import React from 'react';
import styled from 'styled-components';

import { TextInput } from '../../globalStyle';

import KeyboardControlled from '../../keyboardControlled'

const Wrapper = styled.div`
    width: 100%;
    .react-autosuggest__container{
        width: 100%;
    }
`

const Animation = styled.div`
    margin-top: 3px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2), 0 -4px 11px 0 rgba(0, 0, 0, 0.1);
    border-radius: ${ props => props.color.material ? "4px" : "10px" };
    max-height: ${ props => props.open ? "215px" : "0px" };
    overflow: hidden;
    transition: max-height .3s;

    width: calc( 100% - 4px );
`

const SuggestionItem = styled.div`
    padding: 10px 10px;
    background: white;
    cursor: pointer;

    &:hover{
        background: ${ props => props.color.material ? props.color.secondary : props.color.main };
        color: white;
    }
`

const SuggestionContainer = styled.div`
    padding: 10px 0px;
    background: white;
    z-index: 1;

    max-height: 200px;
    overflow: auto;

    li{
        padding: 7.5px 10px;

        &:before{
            display: none;
        }
    }

    :empty{
        display: none;
    }
`



export default class AutocompleteInput extends KeyboardControlled {

    state = {
        focused: false,
        open: false,
        hoverSelectList: false
    }

    constructor(props){
        super(props);
        this.registerKey('Enter', () => {
            this.close();
        }, false, false);
    }

    focus(attr){
        if(this.refs.input) this.refs.input.focus(attr)
        this.open()
    }
    onSelectionSuggestion(event, suggestion){
        this.props.onChange({ value: suggestion.text });
        this.focus();
    }
    onBlur(){
        if( !this.state.hoverSelectList ) this.close()
    }
    onFocus(){
        if( this.props.onFocus ) this.props.onFocus();
        this.open()
    }
    onSelectList( inside ){
        this.setState({ hoverSelectList: inside });
    }
    // ===== 
    open(){
        this.setState({ focused: true, open: true })
        setTimeout(()=>{
            if( this.props.onSizeChange ) this.props.onSizeChange(true)
        }, 300)
    }
    close(){
        // forced delay to wait the selection click before hide the options
        this.setState({ open: false, hoverSelectList: false })

        setTimeout(()=>{
            if( this.props.onBlur ) this.props.onBlur();
            this.setState({ focused: false })
            if( this.props.onSizeChange ) this.props.onSizeChange(true)
        }, 300)
    }

    render(){
        const list = this.props.suggestions ? this.props.suggestions.map((item)=>({text: item})) : []

        const inputProps = {
            placeholder: this.props.placeholder,
            value: this.props.value || "",
            onChange: this.props.onChange.bind(this),
            onBlur: this.onBlur.bind(this)
        }

        return  <Wrapper>
                    <TextInput 
                        ref="input"
                        color={this.props.color} 
                        isLoading={this.props.isLoading}
                        isInvalid={this.props.isInvalid}
                        hasAnswer={this.props.hasAnswer}
                        onFocus={this.onFocus.bind(this)} 
                        onKeyDown={this._keyDown}
                        {...inputProps}
                    ></TextInput>
                    <Animation open={list.length > 0 && this.state.open } color={this.props.color}>
                        { list.length > 0 && this.state.focused && <SuggestionContainer rel="scrollarea" >
                            { list.map((suggestion, i)=>{
                                return <SuggestionItem 
                                                key={i} 
                                                onClick={(evt)=>{ this.onSelectionSuggestion( evt, suggestion ) }}  
                                                color={this.props.color} 
                                                rel="scrollarea"
                                                onMouseEnter={()=>(this.onSelectList(true))}
                                                onMouseLeave={()=>(this.onSelectList(false))}
                                        >
                                            {suggestion.text}
                                        </SuggestionItem>
                                })
                            }
                        </SuggestionContainer> }
                    </Animation>
                </Wrapper>
    }
}