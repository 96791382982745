import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Sidebar, Cover, Blocks, Pre, CloseArrowWrapper } from './style';

import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'

import KeyboardControlled from '../keyboardControlled';

import InterviewNavMenu from './interviewNavMenu';
import Block from './interviewNavBlock';
import InterviewContent from '../interviewContent';

import isMobile from '../../services/mobileDetect';

function mapStateToProps(state){
    const { app, nav, interview, router } = state;
    return { app, nav, interview, router };
}

const mapDispatchToProps = dispatch => {
    return {
        goTo(destiny){
            return dispatch(push(destiny));
        },
        saveAnwers( ){
            return dispatch({
                type: "DONE_LATER",
                payload: { }
            });
        },
        changeCurrentBlock( blockId ){
            return dispatch({
                type: "CHANGE_CURRENT_BLOCK",
                payload: {
                    blockId
                }
            });
        },
        openLoading(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        showSuccess(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Interview saved!", text: "", type: "success" }
            })
        },
        showError(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Fail to save Interview!", text: "The interview can't be saved at this moment. Please, try again later.", type: "error" }
            })
        },
        hideSuccess(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Interview saved!", text: "", type: "success" }
            })
        },
        closeLoading(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        showCloseAlert(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Edit section finished", text: "You can close this tab now or continue editing.", type: "modal" }
            })
        }
    }
}

class CloseArrow extends Component {
    render(){
        return <CloseArrowWrapper onClick={ this.props.onClick }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <path fill={this.props.color.secondary} d="M4.788 16.678a1.078 1.078 0 0 1-1.48 0 .98.98 0 0 1-.021-1.406l7.205-6.849L11.982 7l1.497 1.416 7.212 6.862a.982.982 0 0 1 .022 1.41 1.087 1.087 0 0 1-1.505.02L11.982 9.84l-7.194 6.838z"/>
                        </g>
                    </svg>
                </CloseArrowWrapper>;
    }
}
class InterviewNav extends KeyboardControlled {

    constructor(props) {
        super(props);

        const path = props.location && props.location.pathname;
        const tab = path && path.split("/").length > 1 ? path.split("/")[1] : "cover";

        this.state = {
            saving: false,
            currentTab: tab,
            sidebarClose: !props.app.defaultSideBarOpen
        };
    }

    componentDidMount(){
        this.registerKey('Enter', () => {
          window.dispatchEvent(new CustomEvent('request-submit', {detail: {nextHook: 'request-nav-next', reason: 'confirm'}}))
        }, true, true);
        this.registerKey('ArrowUp', () => {
          window.dispatchEvent(new CustomEvent('request-submit', {detail: {nextHook: 'request-nav-prev', reason: 'nav'}}))
        }, true, true);
        this.registerKey('ArrowDown', () => {
          window.dispatchEvent(new CustomEvent('request-submit', {detail: {nextHook: 'request-nav-next', reason: 'nav'}}))
        }, true, true);
        this.registerKey('ArrowLeft', () => {
          this.getInterviewContentRef().onNavPrevBlock()
        }, false, true);
        this.registerKey('ArrowRight', () => {
          this.getInterviewContentRef().onNavNextBlock()
        }, false, true);
        document.title = "iDox Inteview" + ( this.props.interview.interview ? ' | ' + this.props.interview.interview.label : "" );
    }

    componentWillUnmount(){
        if( this.updateSaveDialogDelay ) clearTimeout( this.updateSaveDialogDelay );
        if( this.updateSaveInterviewDelay ) clearTimeout( this.updateSaveInterviewDelay );
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.app.savingAnswers && !this.props.app.savingAnswers){
        this.updateSaveDialogContent()
      }

      if( prevProps.location.pathname !== this.props.location.pathname ){
        const path = this.props.location && this.props.location.pathname;
        const tab = path && path.split("/").length > 1 ? path.split("/")[1] : "cover";
        if( tab !== this.state.currentTab ){
            this.setState({ currentTab: tab });
            this.showTab( tab, true );
        }
      }
    }

    getInterviewContentRef() {
      return this.interviewContent.getWrappedInstance()
    }

    toggleMenu(){
        this.setState({ sidebarClose: !this.state.sidebarClose });
    }

    closeMenu(){
        this.setState({
            sidebarClose: true,
            currentTab: "dashboard"
        });
    }

    openMenu(){
        this.setState({
            sidebarClose: false
        });
    }

    showTab(tab, ignoreState){
        const destiny = this.state.currentTab !== tab || this.state.sidebarClose ? tab : 'cover';
        if(!ignoreState) this.setState({ sidebarClose: false });

        const path = ([destiny, this.props.nav.currentBlock, this.props.nav.currentVariable]).filter(value=>(value!==undefined)).join("/")

        this.props.goTo({
            pathname: "/"+path+"/",
            search: `?h=${ this.props.hash }`
        });
    }

    changeCurrentBlock( blockId ){
        if( isMobile ) this.setState({ sidebarClose: true });
        this.props.changeCurrentBlock(blockId);
    }

    updateSaveDialogContent() {
        if(this.props.app.saveError) {
          this.props.showError();
        } else {
          this.props.showSuccess();
          setTimeout(() => {
            window.location.replace(this.props.app.redirectUrl);
          }, 2300);
        }
        setTimeout(()=>{
          this.props.closeLoading();
        }, 1000 );
    }

    saveInterview(){
        this.updateSaveInterviewDelay = setTimeout(()=>{
            this.props.openLoading();
            this.props.saveAnwers();
        }, 1 );
    }

    getBlock( blockId ){
        return this.props.interview.blocks[ blockId ];
    }

    getTotalQuestions(navTree){
        return navTree.variablesCount;
    }

    getAnsweredQuestions(navTree){
        return navTree.answeredCount;
    }

    renderSidebarBlocks(){
        let allBlocks = this.props.nav.currentNavTree ? this.props.nav.currentNavTree : [];
        let currentBlockId = this.props.nav.currentBlock;
        let stillUnblock = true;

        return <nav>
            <Blocks>
                { allBlocks && allBlocks.map((blockTree, i)=>{
                        const questionsCount = this.getTotalQuestions(blockTree);
                        const questionAnswerdCount = this.getAnsweredQuestions(blockTree);
                        const block = this.getBlock( blockTree.id );
                        const active = block.id === currentBlockId;
                        stillUnblock = stillUnblock === true && !active;
                        const allowed = this.props.nav.allowedBlocks[ block.id ] || active || stillUnblock;

                        return <Block
                                    color={this.props.color}
                                    navTree={blockTree}
                                    key={block.id}
                                    {...block}
                                    count={i+1}
                                    questionsCount={questionsCount}
                                    questionAnswerdCount={questionAnswerdCount}
                                    blocked={ !allowed }
                                    active={ active }
                                    onClick={()=>{this.changeCurrentBlock(block.id)}}
                                />
                    })
                }
            </Blocks>
        </nav>
    }

    renderSidebarBlockCover(){
        let title = this.props.nav.currentBlock !== null ? this.getBlock( this.props.nav.currentBlock ).label : "";
        let description = this.props.nav.currentBlock !== null ? this.getBlock( this.props.nav.currentBlock ).description : "";

        return  <Cover color={this.props.color}>
                    <div>
                        { isMobile && <CloseArrow onClick={ this.closeMenu.bind(this)} color={this.props.color} /> }
                        <h1>{title}</h1>
                        { description && <p>{description}</p> }
                    </div>
                </Cover>
    }

    renderSidebarPreview(){
        return <Pre>{ JSON.stringify(this.props.interview.answers, null, "  ") }</Pre>
    }

    componentWillReceiveProps( nextProps ){
        if ( nextProps.interview &&
             nextProps.interview.interview &&
             nextProps.interview.interview.label &&
             JSON.stringify( nextProps.interview )!== JSON.stringify( this.props.interview ) ) {

            document.title = ( nextProps.interview.interview ? nextProps.interview.interview.label + ' | ' : "" ) + "iDox Inteview";
        }
    }

    render(){
        const tab = !this.state.sidebarClose && ( this.props.nav.currentPage || "cover" );

        return  <Container tabIndex="1" onKeyDown={this._keyDown}>
                    <Sidebar color={this.props.color} className={ this.state.sidebarClose ? "close" : "" }>
                        <InterviewNavMenu
                            color={this.props.color}
                            close={this.state.sidebarClose}
                            saving={this.state.saving}
                            savingText={this.state.savingText}
                            activeTab={tab}
                            onMenuToggle={()=>{ this.toggleMenu() }}
                            onShowDashboard={()=>{ this.showTab('dashboard') }}
                            onShowPreview={()=>{ this.showTab('preview') }}
                            onContinueLater={()=>{ this.saveInterview() }}
                        />
                        { tab === "dashboard" && this.renderSidebarBlocks(this) }
                        { tab === "cover" && this.renderSidebarBlockCover(this) }
                        { tab === "preview" && this.renderSidebarPreview(this) }
                    </Sidebar>
                    <InterviewContent ref={comp => {this.interviewContent = comp}} block={ this.props.nav.currentBlock } color={this.props.color} />
                </Container>;
    }
}

export default withRouter( connect(mapStateToProps, mapDispatchToProps)(InterviewNav) );
