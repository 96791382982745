import React, { Component } from 'react';

import Dropzone from './Dropzone';

import styled from 'styled-components';
import globalStyle from '../../globalStyle';


const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100% !important;

    cursor: ${ props => props.disabled ? "default" : "pointer" };

    & > div.dropzone{
        position: relative;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        flex-direction: column;

        text-align: center;
        line-height: 1.5em;
        padding: 35px;

        border-radius: ${ props => props.color.material ? "4px" : "10px" };
        border: dotted 2px;

        color: ${ props => props.disabled ? "#d4d4d4" : props.color && props.color.main ? props.color.main : globalStyle.default };
        border-color: ${ props => props.disabled ? "#d4d4d4" : props.color && props.color.main ? props.color.main : globalStyle.default };

        transition: all .2s;

        path{
            fill: ${ props => props.disabled ? "#d4d4d4" : props.color && props.color.main ? props.color.main : globalStyle.default };
            transition: all .2s;
        }

        .effect{
            right: 0px;
            left: 0px;
            top: 0px;
            bottom: 0px;
            border-radius: 5px;
            position: absolute;
            opacity: 0;
            transition: all .2s;

            background: ${ props => props.disabled ? "transparent" : props.color && props.color.main ? props.color.main : globalStyle.default };

        }
        &:hover .effect{
            opacity: 0.2;
            right: 10px;
            left: 10px;
            top: 10px;
            bottom: 10px;
        }
        span.effect.show{
            opacity: 0.2;
            right: 10px;
            left: 10px;
            top: 10px;
            bottom: 10px;
        }

        input{
            width: 100%;
        }
    }
`

export default class UploadInput extends Component{
    constructor(props){
        super(props);

        const propValue = this.props.value ? this.props.value : "" ;
        this.state = {
            value: propValue,
            focused: false
        }

        this.parentOnChange = this.props.onDrop;
        this.onChange = this.onChange.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toogleFocus = this.toogleFocus.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value && nextProps.value !== this.state.value ) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    focus(attr) {
      this.refs.input.focus(attr);
      this.toogleFocus(true);
    }

    isFocused() {
      return this.refs.input === document.activeElement;
    }

    onDrop(files) {
        this.setState({
            value: files.map((file)=>{ return file })
        });
        this.parentOnChange( {type:"onDrop", value: files}, files );
    }

    onCancel() {
        this.setState({
            value: []
        });
    }

    onChange(files){
        let values;

        values = files && files.map ? files.map((file)=>{ return file.name }) : [""];

        this.parentOnChange( {type:"onDrop", value: values}, values );
        if( this.props.onBlur ) this.props.onBlur({type:"onBlur", value: values});
        this.setState({value: values});
    }

    toogleFocus(state){
        this.setState({ focused: state })
    }

    render(){
        const props = {...this.props};
        delete props.value;
        delete props.onBlur;
        delete props.onChange;
        return <Wrapper color={this.props.color} disabled={this.props.disabled} onFocus={this.focus.bind(this)}>
                    <Dropzone className={"dropzone"} ref="input" onDrop={this.onDrop} style={{}} multiple={this.props.multiple} disabled={this.props.disabled} onFocus={()=>{this.toogleFocus(true)}} onBlur={()=>{this.toogleFocus(false)}}>
                        <div>
                            <svg width="30" height="30" viewBox="0 0 30 30">
                                <g fill="none" fillRule="evenodd">
                                    <path id="a" d="M15.268 3.165l3.764 3.825a.687.687 0 0 1 0 .956.648.648 0 0 1-.932 0l-2.53-2.57v13.7a.669.669 0 0 1-.66.677.669.669 0 0 1-.661-.676V5.408l-2.5 2.538a.664.664 0 0 1-.932 0 .686.686 0 0 1 0-.956l3.764-3.825c.218-.22.471-.22.687 0m7.581 9.567v12.683c0 .875-.71 1.585-1.585 1.585H8.584C7.71 27 7 26.29 7 25.415V12.732c0-.876.71-1.585 1.585-1.585h3.387v1.358H9.188a.83.83 0 0 0-.83.83v11.476c0 .458.372.83.83.83h11.473a.83.83 0 0 0 .83-.83V13.335a.83.83 0 0 0-.83-.83h-2.793v-1.358h3.396c.875 0 1.585.709 1.585 1.585"/>
                                </g>
                            </svg>
                        </div>
                        <p>{ this.props.placeholder }</p>
                        <span className={ (this.state.focused ? "show" : "hide") + " effect" }/>
                    </Dropzone>
                </Wrapper>
    }
}
