
export default class CompilerInterface {

  /* Get given Rule object and transform to an format recognizable to _parse
   * method.
   */
  _tokenize () {
    throw new Error(`Unimplemented _tokenize method at ${this.constructor.name}`)
  }

  /* With tokens list generate an abstract syntax tree (AST).
   */
  _parse(tokens) {
    let opStack = [ ]
    let outStack = [ ]

    // Associativity rules
    var associativity = {
      "!" : "right",
      "&" : "left",
      "|" : "left"
    }

    // Precedence rules
    var precedence = {
      "!" : 3,
      "&" : 2,
      "|" : 1
    }

    // Add new node to ast tree
    let addNode = (ast, operator) => {
      let leftChildNode = ast.pop()
      // Unary operators, as the name indicates, only take one operand
      let rightChildNode = operator.unary ? undefined : ast.pop()
      ast.push([leftChildNode, operator, rightChildNode])
    }

    // Peek last item of array
    let peekArr = (arr) => {
      return arr.slice(-1)[0]
    }

    // For each token, process it
    tokens.forEach(token => {
      switch (token.type) {
        // If is an id, push it to output stack
        case 'id':
          outStack.push([undefined, token, undefined])
          break
        // If is an, operator...
        case 'op':
          // while there is an operator token at the top of the operator stack and
          while ( peekArr(opStack) && peekArr(opStack).type === 'op' &&
                  // if actual token is left associative and it's precedence is <= of operator at the top of the stack
                ( (associativity[token.value] === 'left' && precedence[token.value] <= precedence[peekArr(opStack).value]) ||
                  // or actual token is right associative and it's precedence < of operator at the top of the stack
                  (associativity[token.value] === 'right' && precedence[token.value] < precedence[peekArr(opStack).value]) )
                ) {
            // Add operator to output stack
            addNode(outStack, opStack.pop())
          }
          // Add actual token to operator stack
          opStack.push(token)
          break
        // If is left parentesis, add it to operator stack
        case 'lp':
          opStack.push(token)
          break
        // If is right parentesis
        case 'rp':
          // While token at the top of operator stack isn't left parentesis
          while(peekArr(opStack) && peekArr(opStack).type !== 'lp') {
            // Add it to output stack
            addNode(outStack, opStack.pop())
      		}
          // Remove left parentesis from operator stack
          opStack.pop()
          break
        default:
          throw new Error(`${this.constructor.name} - Invalid token received: ${JSON.stringify(token)}`)
      }
    })

    // Add all resulting operators
    while(peekArr(opStack)) {
      addNode(outStack, opStack.pop())
    }

    return outStack.pop()
  }

  /* Generates evaluable object based on abstract syntax tree (AST) generated by
   * parser.
   */
  _generateEvaluable (ast) {
    throw new Error(`Unimplemented _generateEvaluable method at ${this.constructor.name}`)
  }

  /* Generates evaluable object based on rule object provided by api.
   */
  compile (compilable) {
    return this._generateEvaluable(this._parse(this._tokenize(compilable)))
  }
}
