import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export default class IconList extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <path fill={this.props.color.secondary} d="M15 7C7.865 7 3.257 14.369 3.173 14.503a.8.8 0 0 0-.04.938C3.147 15.469 7.015 23 15 23c7.951 0 11.814-7.457 11.86-7.548a.8.8 0 0 0-.034-.948C26.744 14.368 22.136 7 15 7zm0 2.4a5.6 5.6 0 1 1 0 11.2 5.6 5.6 0 0 1 0-11.2zm0 3.2a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8z"/>
                        </g>
                    </svg>
                </Wrapper>;
    }
}