import { push } from 'connected-react-router'
import navService from '../../services/navService'

export const navigationMiddleware = store => next => action => {
    switch (action.type) {
        case "CHANGE_CURRENT_BLOCK":
          store.dispatch(push( navService.getBlockUrlByStore( store.getState(), action.payload.blockId ) ));
          break;

        case 'NAV_INTRO':
          store.dispatch(push( navService.getIntroUrl( store.getState() ) ));
          break;

        case 'NAV_FINISH':
          store.dispatch(push( navService.getFinishUrl( store.getState() ) ));
          break;

        case 'NAV_FIRST_VARIABLE': {
          const state = store.getState()
          const url = navService.getFirstVariableUrlByStore( state );
          const { page, var_id } = navService.getStoreByUrl(url.pathname, state);
          if(state.nav.currentVariable !== var_id || state.nav.currentPage !== page) {
            store.dispatch(push( url ));
          }
          break;
        }

        case 'PROCESS_NEXT_PREVIOUS_FLAGS': {
          const currentLibState = store.getState();
          const payload = navService.processNextPrevious( currentLibState.nav, currentLibState.interview );
          store.dispatch({ type: "SET_NAVIGATION_IDS", payload: payload });
          break;
        }

        case 'NAV_GOTO_BY_PATHNAME': {
          const state = store.getState()
          let navigation = navService.getStoreByUrl(action.payload.url, store.getState());
          if(state.nav.currentVariable !== navigation.var_id || state.nav.currentPage !== navigation.page) {
            store.dispatch({ type: "NAV_GOTO", payload: { ...navigation } });
          }
          break;
        }

        case 'NAV_NEXT_VARIABLE': {
          const state = store.getState()
          if(!state.nav.nextVariable.var_id) return
          const nextVariableURL = navService.getNextUrlByStore( state, action.payload.skipGroup );
          if( nextVariableURL ) store.dispatch(push( nextVariableURL ));
          break;
        }

        case 'NAV_PREV_VARIABLE': {
          const state = store.getState()
          if(!state.nav.previousVariable.var_id) return
          const prevVariableURL = navService.getPreviousUrlByStore( state, action.payload.skipGroup );
          if( prevVariableURL ) store.dispatch(push( prevVariableURL ));
          break;
        }

        case 'NAV_UNANSWERED': {
          const variableURL = navService.getUnansweredUrlByStore( store.getState() );
          if( variableURL ) store.dispatch(push( variableURL ));
          break;
        }

        case 'NAV_NEXT_BLOCK':
          store.dispatch(push( navService.getNextBlockUrlByStore( store.getState() ) ));
          break;

        case 'NAV_PREV_BLOCK':
          store.dispatch(push( navService.getPreviousBlockUrlByStore( store.getState() ) ));
          break;

        case 'NAV_GOTO_PUSH_URL': {
          let variableURL = { }
          const state = store.getState()
          const var_id = action.payload.variableId || action.payload.var_id || action.payload.navId

          if(state.nav.currentVariable !== var_id) {
            variableURL = navService.getVariableUrlByStore( state, {
              var_id,
              page: action.payload.page,
              block_id: action.payload.blockId || action.payload.block_id
            } );
            if( variableURL ) store.dispatch(push( variableURL ));
          }
          break;
        }

        case 'NAV_GOTO':
          store.dispatch({
            type: "UPDATE_NAV_CURRENT",
            payload: {
              currentPage: action.payload.page,
              currentBlock: action.payload.blockId || action.payload.block_id,
              currentVariable: action.payload.variableId || action.payload.var_id || action.payload.navId
            }
          })

          store.dispatch({ type: "PROCESS_NEXT_PREVIOUS_FLAGS", payload: { }});
          break;

        default:
            next(action);
    }
}

export default navigationMiddleware;
