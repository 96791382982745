
import ApiInterface from './ApiInterface';

import lStorage from '../localStorageService';

class LocalAPI extends ApiInterface{
    constructor(){
        super();

        if (!LocalAPI.instance) {
          this._loaded = {};
          LocalAPI.instance = this;
        }

        return LocalAPI.instance;
    }

    _loadKey(key){
      let itens = lStorage.get(key);

      if( itens ){
          itens = JSON.parse( itens );
          this._loaded[key] = itens;
      } else {
          this._loaded[key] = [];
      }
    }

    _saveKey(key) {
      lStorage.set(key, JSON.stringify(this._loaded[key]));
    }

    _setItem(key, item){
      if (!this._loaded[key]) this._loadKey(key);

      this._loaded[key] = this._loaded[key].filter(loaded => loaded.hash !== item.hash);
      this._loaded[key].push(item);

      this._saveKey(key);
    }

    _getItem(key, hash) {
      if (!this._loaded[key]) this._loadKey(key);
      return this._loaded[key].find(item => item.hash === hash);
    }

    // public
    validateHash(hash){
      if( !hash ) return Promise.reject();

      return new Promise((resolve, reject)=>{
          let interview = this._getItem(lStorage.INTERVIEWS, hash);
          if(interview){
              resolve(interview);
          }else{
              reject( 'Offline mode - Interview not founded' );
          }
      });
    }

    storeInterview(hash, content){
      this._setItem(lStorage.INTERVIEWS, {hash, content});
    }

    storeAnswers(hash, answers){
      this._setItem(lStorage.ANSWERS, {hash, content: answers, time: Date.now()});
    }

    clearAnswers(hash){
      this._setItem(lStorage.ANSWERS, {hash});
    }

    hasLocalAnswers(hash){
      let currTime = Date.now();
      let maxLifeTime = 1000 * 60 * 15; // 15 minutes
      let localAnswers = this.loadAnswers(hash);

      if( !localAnswers.content ||
          !localAnswers.time ||
          (localAnswers.time + maxLifeTime) < currTime ) {
        this.clearAnswers(hash);
        return false;
      }

      return true
    }

    loadAnswers(hash){
      return this._getItem(lStorage.ANSWERS, hash) || { };
    }

    static getInstance() {
      return new LocalAPI();
    }
}

export default LocalAPI;
