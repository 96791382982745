import update from 'immutability-helper';
import navFlowService from '../../services/navFlowService';

export const initialState = {
    currentPage: 'cover',
    currentInterview: null,
    currentBlock: null,
    currentVariable: null,
    currentNavTree: [],
    nextVariable: {},
    previousVariable: {},
    nextBlock: {},
    previousBlock: {},
    interviewFinished: false,
    unansweredVariable: {},
    untouchedVariable: {},
    allowedBlocks: {}
}

const rootReducer = (state = initialState, action) => {

    let blocks, variables, answers, conditions, flow, navTree;
    let payload = {};

    switch (action.type) {
        case "UPDATE_NAV": // action WITH middlewares
        case "UPDATE_NAV_VARS": // action WITHOUT middlewares
            if( action.payload.interview ){
                payload.currentInterview = { $set: action.payload.interview };
            }

            blocks = action.payload.blocks;
            variables = action.payload.variables;
            conditions = action.payload.conditions;
            answers = action.payload.answers ? action.payload.answers : { };

            if(!action.payload.currentNavTree) {
              flow = navFlowService( variables, blocks,  conditions, answers);
              navTree = flow.getNavTree();
            }

            payload.currentNavTree = { $set: navTree || action.payload.currentNavTree };

        // eslint-disable-next-line
        case "SET_NAVIGATION_IDS":

            if (action.payload.nextBlock) {
                payload.nextBlock = { $set: action.payload.nextBlock };
            }

            if (action.payload.prevBlock){
                payload.previousBlock = { $set: action.payload.prevBlock };
            }

            if (action.payload.nextVariable){
                payload.nextVariable = { $set: action.payload.nextVariable };
            }

            if (action.payload.prevVariable){
                payload.previousVariable = { $set: action.payload.prevVariable };
            }

            if (action.payload.nextVariableIgnoringGroup){
                payload.nextVariableIgnoringGroup = { $set: action.payload.nextVariableIgnoringGroup };
            }

            if (action.payload.prevVariableIgnoringGroup){
                payload.prevVariableIgnoringGroup = { $set: action.payload.prevVariableIgnoringGroup };
            }

            if (action.payload.unansweredVariable) {
                payload.unansweredVariable = { $set: action.payload.unansweredVariable };
            }

            if (action.payload.untouchedVariable) {
                payload.untouchedVariable = { $set: action.payload.untouchedVariable };
            }

            if(action.payload.interviewFinished !== undefined) {
                payload.interviewFinished = { $set: action.payload.interviewFinished };
            }

            return update( state, payload );
        case "CHANGE_PAGE":
            return update(state, {
                currentPage: { $set: action.payload.page ? action.payload.page : 'cover' }
            });
        case "CHANGE_VARIABLE":
            return update(state, {
                currentVariable: { $set: action.payload.id }
            });
        case "CHANGE_BLOCK":
            return update(state, {
                currentBlock: { $set: action.payload.id },
                allowedBlocks:{ [ action.payload.id ]: { $set: true } }
            });
        case "SET_FINISHED_FLAG":
            return update(state, {
                interviewFinished: { $set: action.payload }
            });
        case "UPDATE_NAV_CURRENT":
            if( action.payload.currentPage ) payload.currentPage = { $set: action.payload.currentPage };
            if( action.payload.currentBlock ) payload.currentBlock = { $set: action.payload.currentBlock };
            if( action.payload.currentBlock ) payload.allowedBlocks = { [ action.payload.currentBlock ]: { $set: true } } ;
            if( action.payload.currentVariable ) payload.currentVariable = { $set: action.payload.currentVariable };

            return update(state, payload );

        default:
            return state;
    }
};

export default rootReducer;
