import styled from 'styled-components';
import globalStyle, { Button } from '../../globalStyle';

export const TutorialTipContent = styled.div`
  width: 200px;
  text-align: left;
`
export const Key = styled.p`
  color: #797979;
  display: inline-block;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 5px;
  box-shadow: 0 3px 0 0 #dcdcdc;
  border: solid 1px #dcdcdc;
  background-color: white;
  margin: 5px 0px 5px 5px;
  text-align: center;
  font-size: 22px;
  line-height: 1.33;
  font-weight: bold;
`

export const TipButton = styled(Button)`
  border: 1px solid transparent;
  font-weight: normal;

  @media screen and ( ${ globalStyle.desktop } ){
    font-size: 12px;
    line-height: 1.2;
    padding: 5px 7px;
    min-width: 10px;
    min-height: calc(1.5em + 10px);
    margin: 10px 5px 10px 0px;
  }
`

export const InfoButton = styled.button`
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;

  svg {
    width: 20px;
    height: 20px;
  }

  &:focus {
    outline: none;
  }
`
