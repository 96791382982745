import React, { Component } from 'react';

import styled from 'styled-components';

const SwiperWrapper = styled.div`
  touch-action: none;
`

export default class Swiper extends Component {

  constructor (props) {
    super(props)
    this.state = {
      tracking: false
    }
    this.startPoint = 0;
    this.endPoint = 0;
    this.height = 0;
    this.updateWindowHeight = this.updateWindowHeight.bind(this)
    this.cancelEvent = this.cancelEvent.bind(this)

    this.onWheel = this.onWheel.bind(this)

    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)

    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
  }

  componentDidMount () {
    this.updateWindowHeight()
    window.addEventListener('resize', this.updateWindowHeight)
    this.refs.swiperWrapper.addEventListener('wheel', this.onWheel, { passive: false })
    this.refs.swiperWrapper.addEventListener('mouseup', this.onMouseUp)
    this.refs.swiperWrapper.addEventListener('mousedown', this.onMouseDown)
    this.refs.swiperWrapper.addEventListener('mousemove', this.onMouseMove)
    this.refs.swiperWrapper.addEventListener('touchend', this.onTouchEnd, { passive: false })
    this.refs.swiperWrapper.addEventListener('touchmove', this.onTouchMove, { passive: false })
    this.refs.swiperWrapper.addEventListener('touchstart', this.onTouchStart, { passive: false })
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowHeight)
    this.refs.swiperWrapper.removeEventListener('wheel', this.onWheel)
    this.refs.swiperWrapper.removeEventListener('mouseup', this.onMouseUp)
    this.refs.swiperWrapper.removeEventListener('mousedown', this.onMouseDown)
    this.refs.swiperWrapper.removeEventListener('mousemove', this.onMouseMove)
    this.refs.swiperWrapper.removeEventListener('touchstart', this.onTouchStart)
    this.refs.swiperWrapper.removeEventListener('touchend', this.onTouchEnd)
    this.refs.swiperWrapper.removeEventListener('touchmove', this.onTouchMove)
  }
  updateWindowHeight () {
    this.height = window.innerHeight * 0.9;
  }

  // util
  cancelDetection(e){
    return (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA" || (e.target.attributes['rel'] && e.target.attributes['rel'].value === "scrollarea") || (e.target.attributes['role'] && e.target.attributes['role'].value === "slider") || ( e.target.className && e.target.className.indexOf && e.target.className.indexOf("rc-slider") > -1 ) )
  }

  cancelEvent (e) {
    if (e === undefined) return false
    if (e.stopPropagation) e.stopPropagation()
    if (e.preventDefault) e.preventDefault()
    e.cancelBubble = true
    e.returnValue = false
    return false
  }

  // Touch
  onTouchStart (event) {
    if( this.cancelDetection(event) ) return false
    let startPoint = event.targetTouches[0].clientY
    this.onSwipeStart(startPoint)
  }
  onTouchMove (event) {
    if( this.cancelDetection(event) ) return false
    let endPoint = event.targetTouches[0].clientY
    this.onSwipeMove(endPoint)
  }
  onTouchEnd (event) {
    if( this.cancelDetection(event) ) return false
    this.onSwipeEnd()
  }
  // Mouse
  onMouseDown (event) {
    if( this.cancelDetection(event) ) return false
    let startPoint = event.clientY
    this.onSwipeStart(startPoint)
    //this.cancelEvent(event)
  }
  onMouseMove (event) {
    if( event.buttons && !this.cancelDetection(event) ){
        let endPoint = event.clientY;
        this.onSwipeMove(endPoint);
        this.cancelEvent(event);
    }
  }
  onMouseUp (event) {
    if( this.cancelDetection(event) ) return false
    this.onSwipeEnd()
  }

  onWheel(event){
    if( event.target.attributes['rel'] && event.target.attributes['rel'].value ) return false;
    const dist = event.deltaY;

    if ( !this.state.tracking ) { this.onSwipeStart(0) }

    this.endPoint -= dist;
    this.onSwipeMove( this.endPoint );
    if( this.onWheelEndDelay ) clearTimeout( this.onWheelEndDelay );
    this.onWheelEndDelay = setTimeout(()=>{
      if (this.onSwipeEnd) { this.onSwipeEnd() }
    }, 250);
    this.cancelEvent(event)
  }


  // System
  onSwipeStart (startPoint) {
    this.setState({
      tracking: true
    })
    this.startPoint = startPoint;
    this.endPoint = startPoint;
    if (this.props.onSwipeStart) { this.props.onSwipeStart(startPoint) }
  }
  onSwipeMove (endPoint) {
    if (this.state.tracking) {
        let dist = (this.startPoint - this.endPoint);
        let offset = dist / this.height;
        this.endPoint = endPoint;
        if (this.props.onSwipeMove) { this.props.onSwipeMove(offset, dist) }
    }
  }
  onSwipeEnd () {
    this.setState({
      tracking: false
    })
    let isSamePosition = Math.abs(this.startPoint - this.endPoint) < 5
    let anchorPoint = isSamePosition ? this.width / 2 : this.endPoint;
    let offset = (this.startPoint - anchorPoint) / this.height

    if (this.props.onSwipeEnd) { this.props.onSwipeEnd(offset) }
    if (offset < 0) {
      if (this.props.onSwipeDown) this.props.onSwipeDown(offset)
    } else {
      if (this.props.onSwipeUp) this.props.onSwipeUp(offset)
    }
  }

  render () {
    return (<SwiperWrapper ref="swiperWrapper">
      {this.props.children}
    </SwiperWrapper>)
  }
  }
