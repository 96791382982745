import React, { Component, Fragment } from 'react';

import { SimpleLoopButton } from '../../uiElements/LoopButton/style.js';
import Tooltip from '../../uiElements/tooltip';

import styled from 'styled-components';
import globalStyle from '../../globalStyle'

import { t } from '..';

const UploadItemElement = styled(SimpleLoopButton)`
    font-style: italic;
    position: relative;
    overflow: hidden;
    background: ${ props => props.status ? "white" : "#d8a8a8" };
    color: ${ props => props.status ? "#ababab" : "white" };

    &:before{
        content: url(/img/close.svg);
        z-index: 10;
    }
`

const ProgressBar = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${ props => props.progress ? props.progress : 0 }%;
    background: white;
    transition: all .1s;
    overflow: hidden;

    background: ${ props => props.status ? props.color && props.color.main ? props.color.main : globalStyle.default : "#d8a8a8" };
    color: ${ props => props.status ? "#fff" : "white" };

    & span {
        display: inline-block;
        padding: 15px 45px 15px 30px;
    }
`

export default class UploadListItem extends Component {
    constructor( props ){
        super( props );
        this.state = {
            name: "",
            type: "",
            size: null,
            fileUrl: "",
            serverName: "",
            status: true,
            errorMsg: "",
            loading: false,
            progress: 0
        }
        this.uploader = props.uploader;
        this.uploader.onProgress = ( percent )=>{
            this.setState({
              name: props.uploader.name,
              type: props.uploader.type,
              size: props.uploader.size,
              fileUrl: props.uploader.fileUrl,
              serverName: props.uploader.serverName,
              loading: true,
              progress: percent
            });
        }
        this.uploader.onSuccess = ( file )=>{
            this.setState({ loading: false, status: true });
            this.props.onSuccess({
              value: {
                type: this.state.type,
                size: this.state.size,
                fileName: this.state.name,
                fileUrl: this.state.fileUrl,
                serverName: this.state.serverName
              }
            });
        }
        this.uploader.onFail = ( error, file )=>{
            this.setState({ loading: false, status: false, errorMsg: t( "Upload error: ?0", error) });
            this.props.onFail( {
                value: {
                  type: this.state.type,
                  size: this.state.size,
                  fileName: this.state.name,
                  fileUrl: this.state.fileUrl,
                  serverName: this.state.serverName,
                  error: error
                }
              } );
        }

        this.onCancel = this.onCancel.bind(this);
    }
    componentWillUnmount(){
        this.uploader.remove();
    }
    onCancel(){
        this.uploader.remove();
        this.props.onCancel();
    }
    render(){
        const Wrapper = this.state.errorMsg ? Tooltip : Fragment;
        const wrapperOptions = this.state.errorMsg ? { distance: 15, position:"top", title:this.state.errorMsg, arrow: true } : {};

        return  <Wrapper {...wrapperOptions}>
                    <UploadItemElement status={this.state.status} loading={this.state.loading} onClick={this.onCancel} color={this.props.color}>
                        <ProgressBar progress={ ( this.state.progress || 0 ) } status={this.state.status} color={this.props.color}>
                            <span>{ this.state.name || t("Processing file") }</span>
                        </ProgressBar>
                        { this.state.name || t("Processing file") }
                    </UploadItemElement>
                </Wrapper>
    }
}
