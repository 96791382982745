import styled from 'styled-components';
import globalStyle from '../globalStyle';

export const IntroContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-image: ${ props => props.color && props.color.gradient ? props.color.gradient : globalStyle.defaultGradient };
    background-image: url(/img/iStock-889031464.jpg);
    background-size: cover;

    &:before{
      content: "";
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0,0,0,0.5);
      z-index: 1;
    }

    &>div{
      z-index: 2;
      max-width: 650px;
      width: 90vw;
    }

    h1 {
      margin-top: 0px;
      color: white;
    }

    p{
      color: white;
    }

    a{
      color: white;
      text-decoration: none;
      border-bottom: 1px solid white;
      transition: all .5s;

      &:hover{
        color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        border-color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
      }
    }
`
