
import interviewMiddleware from './interview'
import navigationMiddleware from './navigation'

let middlewares = [
  interviewMiddleware,
  navigationMiddleware
];

export default middlewares;
