import React, { Component, Fragment } from 'react';

import QuestionComplex from '../questionComplex';
import QuestionDefault from '../questionDefault';

import QuestionMessage from '../questionMessage';
import QuestionNumber from '../questionNumber';
import QuestionText from '../questionText';
import QuestionMultiChoices from '../questionMultiChoices';
import QuestionDate from '../questionDate';
import QuestionUpload from '../questionUpload';
import QuestionNavigation from '../questionNavigation';

class QuestionDynamic extends Component {
    render(){
        const type = this.props.type;
        switch(type){
            case "MSG":
            case "MESSAGE":
                return <Fragment><QuestionMessage {...this.props} />{this.props.children}</Fragment>
            case 'COMPLEX':
                return <Fragment><QuestionComplex {...this.props} >{this.props.children}</QuestionComplex></Fragment>
            case 'ST':
            case 'LT':
                return <Fragment><QuestionText {...this.props} />{this.props.children}</Fragment>
            case 'NUM':
            case "CASH":
            case "PERCENT":
            case "DAYS":
                return <Fragment><QuestionNumber {...this.props} />{this.props.children}</Fragment>
            case "DATE":
                return <Fragment><QuestionDate {...this.props} />{this.props.children}</Fragment>
            case "MUL":
            case "YN":
                return <Fragment><QuestionMultiChoices {...this.props} />{this.props.children}</Fragment>
            case 'FILE':
                return <Fragment><QuestionUpload {...this.props} />{this.props.children}</Fragment>
            case 'QUESTION_END_BLOCK':
                return <Fragment><QuestionNavigation {...this.props} />{this.props.children}</Fragment>
            default:
                return <Fragment><QuestionDefault {...this.props} />{this.props.children}</Fragment>
        }
    }
}

export default QuestionDynamic ;
