class navService  {
    cleanUrl( ...levels ){
        return "/" + levels.filter((lv)=>lv).join("/") + "/"
    }

    _getUrl( page, ids ){
        return this.cleanUrl( page, ids.block_id, ids.var_id )
    }

    _getNavigationObject(state, ids, page) {
        page = page || state.nav.currentPage;
        const hash = state.app.hash;

        return {
            pathname: this._getUrl( page, ids ),
            search: `?h=${ hash }`
        }
    }

    getFirstVariable( state ){
      return {
        var_id: state.nav.currentNavTree[0].subVariables[0].navId,
        block_id: state.nav.currentNavTree[0].id
      }
    }

    getFirstVariableUrlByStore( state ){
      let firstVarinfo = {
        var_id: state.nav.currentNavTree[0].subVariables[0].navId,
        block_id: state.nav.currentNavTree[0].id
      }
      return this._getNavigationObject(state, firstVarinfo, 'cover');
    }

    getNextUrlByStore( state, skipGroup ){
        if(skipGroup) {
          return state.nav.nextVariableIgnoringGroup.var_id ? this._getNavigationObject(state, state.nav.nextVariableIgnoringGroup) : false;
        } else {
          return state.nav.nextVariable.var_id ? this._getNavigationObject(state, state.nav.nextVariable) : false;
        }
    }

    getPreviousUrlByStore( state, skipGroup ){
        if(skipGroup) {
          return state.nav.prevVariableIgnoringGroup.var_id ? this._getNavigationObject(state, state.nav.prevVariableIgnoringGroup) : false;
        } else {
          return state.nav.previousVariable ? this._getNavigationObject(state, state.nav.previousVariable) : false;
        }
    }

    getVariableUrlByStore( state, variable ) {
        return this._getNavigationObject(state, variable);
    }

    getUnansweredUrlByStore( state ) {
        return this._getNavigationObject(state, state.nav.unansweredVariable);
    }

    getBlockUrlByStore( state, block_id ){
        return this._getNavigationObject(state, {
          block_id,
          var_id: state.nav.currentNavTree.find(block => block.id === block_id).subVariables[0].navId
        });
    }

    getNextBlockUrlByStore( state ){
        return this._getNavigationObject(state, state.nav.nextBlock);
    }

    getPreviousBlockUrlByStore( state ){
        return this._getNavigationObject(state, state.nav.previousBlock);
    }

    isValidUrl( url, state ){
        // eslint-disable-next-line
        let [ domain, page, block_id, var_id ] = url.split("/");

        if(block_id !== undefined && block_id !== '' && isNaN(parseInt(block_id))) {
          return false;
        } else if(var_id === undefined || var_id === ''){
          return false;
        }

        return {
          page,
          var_id,
          block_id: parseInt(block_id)
        };
    }

    getStoreByUrl( url, state ){
        let planifyedTree = this._planifyNavTree(state.nav.currentNavTree)

        url = this.isValidUrl(url)

        if(url) {
          if(url.var_id) {
            let variable = planifyedTree.find(item => (item.navId === url.var_id) || item.navId.startsWith(url.var_id))

            if(variable && variable.blockId === url.block_id) {
              // url has block and varible reference
              // Override var_id is necessary because of url can refear to an variable prefix. This
              // occurs when complex is the first item of an block.
              return {...url, var_id: variable.navId}
            }
          }
          else if(url.block_id) {
            let block = state.nav.currentNavTree.find(block => block.id === url.block_id)

            // url has just block reference
            return {
              page: url.page,
              var_id: block.subVariables[0].navId,
              block_id: block.id
            }
          }
        }

        // the ids are invalid
        return {
          page: url.page,
          var_id: state.nav.currentNavTree[0].subVariables[0].navId,
          block_id: state.nav.currentNavTree[0].id
        }
    }

    getIntroUrl( state ) {
      return {
          pathname: '/intro',
          search: `?h=${ state.app.hash }`
      }
    }

    getFinishUrl( state ) {
      return {
          pathname: '/finished',
          search: `?h=${ state.app.hash }`
      }
    }

    _planifyNavTree(navTree) {
      let result = []
      if(navTree && navTree.length > 0) {
        navTree.forEach(item => {
          // if a block or simple variable
          if(item.subVariables) {
            item.subVariables.forEach(subItem => {
              if(subItem.subVariables && subItem.subVariables.length > 0) {
                result.push(...this._planifyNavTree(subItem.subVariables))
              } else {
                result.push(subItem)
              }
            })
          }
          // if is an structure variable (aka complex var)
          else {
            result.push(...item)
          }
        })
      }

      return result
    }

    processNextPrevious( navState, interviewState ){
      let nextBlock = { };
      let prevBlock = { };
      let nextVariable = { };
      let prevVariable = { };
      let nextVariableIgnoringGroup = { };
      let prevVariableIgnoringGroup = { };

      let { currentNavTree, currentVariable } = navState;
      let planifyedTree = this._planifyNavTree(currentNavTree);
      let currVIdx = planifyedTree.findIndex(item => item.navId === currentVariable);
      
      // This is for when the last option has fields that depends on the choice
      // and return to current index
      if(currVIdx < 0) {
        currVIdx = planifyedTree.length - 4
      }

      currentVariable = planifyedTree[currVIdx];

      let allMandatoryDone = this._findUnansweredVarInBlock( navState, interviewState, currentVariable.blockId, undefined, true ) === false;

      //if has next var, set it
      if(planifyedTree.length > (currVIdx + 1)) {
        nextVariable.var_id = planifyedTree[currVIdx + 1].navId;
        nextVariable.block_id = planifyedTree[currVIdx + 1].blockId;
      }

      //if has prev var, set it
      if(currVIdx > 0) {
        prevVariable.var_id = planifyedTree[currVIdx - 1].navId;
        prevVariable.block_id = planifyedTree[currVIdx - 1].blockId;
      }

      //if actual is into a group, create next var out of current group
      if(nextVariable.var_id) {
        for (let i = (currVIdx + 1); i < planifyedTree.length; i++) {
          if(planifyedTree[i].groupPrefix !== planifyedTree[currVIdx].groupPrefix) {
            nextVariableIgnoringGroup.var_id = planifyedTree[i].navId;
            nextVariableIgnoringGroup.block_id = planifyedTree[i].blockId;
            break;
          }
        }
      }

      //if actual is into a group, create prev var out of current group
      if(prevVariable.var_id) {
        for (let i = (currVIdx - 1); i >= 0; i--) {
          if(planifyedTree[i].groupPrefix !== planifyedTree[currVIdx].groupPrefix) {
            prevVariableIgnoringGroup.var_id = planifyedTree[i].navId;
            prevVariableIgnoringGroup.block_id = planifyedTree[i].blockId;
            break;
          }
        }
      }

      // process next block
      if(nextVariable.var_id) {
        for (let i = (currVIdx + 1); i < planifyedTree.length; i++) {
          if(planifyedTree[i].blockId !== currentVariable.blockId) {
            nextBlock.allowed = allMandatoryDone;
            nextBlock.var_id = planifyedTree[i].navId;
            nextBlock.block_id = planifyedTree[i].blockId;
            break;
          }
        }
      }

      // process prev block
      if(prevVariable.var_id) {
        for (let i = (currVIdx - 1); i >= 0; i--) {
          if(planifyedTree[i].blockId !== currentVariable.blockId) {
            prevBlock.var_id = planifyedTree[i].navId;
            prevBlock.block_id = planifyedTree[i].blockId;
            break;
          }
        }
      }
      let result = {
          nextBlock,
          prevBlock,
          nextVariable,
          prevVariable,
          nextVariableIgnoringGroup,
          prevVariableIgnoringGroup
      };
      
      // interview is finished if last mandatory variable was answered
      if (navState.interviewFinished !== true) {
        result.interviewFinished = this._isLastQuestionTouched(navState, interviewState)
      }

      // if interview has finished or no next variable, find unnanswered mandatory vars
      if( nextVariable.var_id === undefined || navState.interviewFinished || result.interviewFinished ) {
        const unanswered = this.findUnansweredVariable( navState, interviewState );
        result.unansweredVariable = unanswered || {};
      }

      return result;
    }

    _isLastQuestionTouched(navState, interviewState) {
      let answer = {};
      let lastVar = -1;
      let lastBlock = navState.currentNavTree[navState.currentNavTree.length - 1];
      const idBlock = lastBlock.subVariables[0].id;
      
      //if name of last block contains end-block it's not last block
      if(idBlock && 
        typeof idBlock.includes === 'function' && 
        idBlock.includes('end-block')) {
          lastBlock = navState.currentNavTree[navState.currentNavTree.length - 2];
      }
      
      // Find last uninjected var
      for (var i = (lastBlock.subVariables.length - 1); i >= 0 && lastVar === -1 ; i--) {
        let id = lastBlock.subVariables[i].id;
        
        if(!interviewState.variables[id].isInjected) {
          lastVar = id;
        }
      }
      
      answer = interviewState.answers[lastVar];
      return answer && answer[0] && answer[0].touched;
    }

    _isLastMandatoryAnswered(interviewState) {
      let lastMandatory = this._findLastObrigatoryVariable(interviewState);

      if(this._isVariableAnswered(interviewState, lastMandatory)) {
        return true;
      }

      return false;
    }

    _findLastObrigatoryVariable(interview) {
      let ids = Object.keys(interview.blocks).reverse();

      let getLastMandatory = (ids, variables) => {
        for (var i = (ids.length - 1); i > 0; i--) {
          if(variables[ids[i]].mandatory) {
            return ids[i]
          }
        }
      }

      for (var i = 0; i < ids.length; i++) {
        let lastMandatory = getLastMandatory(interview.blocks[ids[i]].variables_ids, interview.variables)
        if(lastMandatory !== undefined) {
          return lastMandatory
        }
      }
    }

    // Verify if variable was touched (confirmed answer with ok)
    _isVariableTouched(interviewState, id) {
      let keys = [];
      let { answers } = interviewState;

      if (!answers[id]) {
        return false
      } else {
        // If has answers, verify all answers in case of loop
        keys = Object.keys(answers[id]);
      }

      let key = keys.find( key => {
        return !answers[id][keys[key]].touched
      });

      return key === undefined
    }

    // Verify if variable has been answered and all of its answers is with status === true
    _isVariableAnswered(interviewState, id) {
      let keys = [];
      let { answers, variables } = interviewState;

      // If has answers, verify all answers in case of loop
      if (answers[id]) {
        keys = Object.keys(answers[id]);
      }
      // else, return answered status based on mandatory flag
      else {
        return !variables[id].mandatory
      }

      let key = keys.find( key => {
        return !answers[id][keys[key]].status && variables[id].mandatory
      });

      return key === undefined
    }

    // Verify if variable is a mandatory
    _isVariableMandatory(interviewState, id) {
      const { variables } = interviewState;
      const variable = variables[ id ];
      return !variable || variable.mandatory;
    }

    // find an untouched variable into a block
    _findUntouchedVarInBlock(navState, interviewState, block_id, maxIndex) {
      let result = false;
      let { currentNavTree } = navState;
      let block = currentNavTree.find(block => (block.id === block_id));

      for(let i = 0; i < block.subVariables.length; i++) {
        if(maxIndex === undefined || i !== maxIndex) {
          //if(!this._isVariableAnswered(interviewState, block.subVariables[i].id)) {
          if(!this._isVariableTouched(interviewState, block.subVariables[i].id)) {
            return block.subVariables[i].id
          }
        }
      }

      return result
    }

    // find an unanswered variable into a block
    _findUnansweredVarInBlock(navState, interviewState, block_id, maxIndex, mandatoryOnly = false) {
      let result = false;
      let { currentNavTree } = navState;
      let block = currentNavTree.find(block => (block.id === parseInt(block_id)));

      for(let i = 0; i < block.subVariables.length; i++) {
        if(maxIndex === undefined || i !== maxIndex) {
          const canIgnore = mandatoryOnly && !this._isVariableMandatory(interviewState, block.subVariables[i].id)
          const dontHasAnswer = !canIgnore && !this._isVariableAnswered(interviewState, block.subVariables[i].id);
          if(dontHasAnswer) {
            return block.subVariables[i].id
          }
        }
      }

      return result
    }

    /* returns:
     * - false if all variables has been touched
     * - { var_id, block_id, is_previous } of untouched variable
     */
    findUnansweredVariable( navState, interviewState ){
    //findUntouchedVariable( navState, interviewState ){
      let unanswered = { }
      let { currentBlock, currentVariable, currentNavTree } = navState;

      let findVariableIndex = (block_idx, var_id) => {
        return currentNavTree[block_idx].subVariables.findIndex(item => item.id === var_id);
      }

      let currBIdx = currentNavTree.findIndex(block => block.id === currentBlock);
      let currVIdx = findVariableIndex(currBIdx, currentVariable);

      for (var i = 0; i < currentNavTree.length; i++) {
        //let variable = this._findUntouchedVarInBlock(navState, interviewState, currentNavTree[i].id);
        let variable = this._findUnansweredVarInBlock(navState, interviewState, currentNavTree[i].id);

        if(variable !== false) {
          unanswered.var_id = variable;
          unanswered.block_id = currentNavTree[i].id;

          if((i < currBIdx) || (i === currBIdx && currVIdx > findVariableIndex(currBIdx, variable))) {
            unanswered.is_previous = true;
          }

          return unanswered;
        }
      }

      // any unanswered variable
      return false;
    }

}
export default new navService();
