import React, { Component } from 'react';

import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;
        animation: ${rotate} 1s ease infinite;

        width: 99px;
        height: 99px;
        border-radius: 50%;
    }
`;

export default class IconSpin extends Component {
    render(){
        return <Loader>
                    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="107" viewBox="0 0 107 107">
                        <defs>
                            <linearGradient id="bg" gradientUnits="userSpaceOnUse" x1="57.5" y1="107" x2="80" y2="55">
                                <stop offset="0" stopColor="white"></stop>
                                <stop offset="1" stopColor="black"></stop>
                            </linearGradient>
                            <mask id="circlesMask">
                                <g fill="none" fillRule="evenodd" strokeWidth="8" transform="translate(4 4)">
                                    <circle stroke="url(#bg)" cx="49.5" cy="49.5" r="49.5"/>
                                </g>
                            </mask>
                        </defs>
                        <g mask="url(#circlesMask)" >
                            <rect x="0" y="53.5" fill={this.props.color.main} width="107" height="53.5" />
                        </g>
                        <path transform="translate(4 4)" stroke={this.props.color.main} fillRule="evenodd" strokeWidth="8" fill="none" strokeLinecap="round" strokeLinejoin="round" d="M49.5 99C22.162 99 0 76.838 0 49.5S22.162 0 49.5 0 99 22.162 99 49.5"/>
                    </svg>
                </Loader>
    }
}