import 'rc-slider/assets/index.css';

import React, { Component } from 'react';
import styled from 'styled-components';

import Slider from './slider';

const Wrapper = styled.div`
    .rc-slider-handle{
        margin-top: -12.5px;
        margin-left: -15px;
        border: 7.5px solid rgb(7, 175, 91);
        width: 30px;
        height: 30px;

        background-color: ${ props => props.color && props.color.main };
        border-color: white;

        box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2), 0 -4px 11px 0 rgba(0, 0, 0, 0.1);
        z-index: 1;

        &:hover,
        &:focus{
            border-color: ${ props => props.color && props.color.main };
            background-color: white;
            box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2), 0 -4px 11px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .rc-slider-mark {
        position: relative;
        top: 20px;
    }

    .rc-slider-mark-text-active{
        color: #4a4a4a;
    }
`;

class SliderInput extends Component {
    constructor(props){
        super(props);

        this.state = {
            defaultValue: Math.round(( this.props.max - this.props.min ) / 2),
            value: Math.round(( this.props.max - this.props.min ) / 2),
            marks: this.createMarks(),
            focused: false
        }

        this.onSliderChange = this.onSliderChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if( this.state.focused !== prevState.focused ){
            if( this.state.focused ){
                this.props.onFocus();
            }else{
                this.props.onBlur();
            }
        }
    }

    focus(attr){
      this.refs.input.focus(attr);
    }
    isFocused() {
      return this.state.focused;
    }
    createMarks(){
        let marks = {};
        marks[ this.props.min ] = this.props.format( this.props.min );
        marks[ this.props.max ] = this.props.format( this.props.max );

        let step = this.props.step || this.props.step === 0 ? this.props.step : ( this.props.max - this.props.min ) / 2;

        if( step ){
            for( let i = this.props.min; i < this.props.max; i += step ){
                marks[ i ] = this.props.format( i );
            }
            return marks;
        }
    }
    onSliderChange(value){
        this.setState({ value: value });
        this.props.onChange({ target: { value: value } })
    }
    onBlur(evt){
        this.setState( { focused: false } );
    }
    onFocus(attr){
        this.refs.input.focus(attr);
        this.setState( { focused: true } );
    }
    render(){
        const step = this.props.step || undefined;
        return <Wrapper color={this.props.color}>
                    <Slider
                        ref="input"
                        value={this.props.value}
                        defaultValue={this.state.value}
                        min={this.props.min}
                        max={this.props.max}
                        step={step}
                        marks={this.state.marks}
                        onChange={this.onSliderChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        dotStyle={ { display: "none" } }
                        railStyle={ { height: "5px", borderRadius: "2.5px", backgroundColor: "#dcdcdc" } }
                        trackStyle={ { height: "5px", borderRadius: "2.5px", backgroundColor: this.props.color.main } }

                    />
                </Wrapper>
    }
}
/*    onAfterChange={this.props.onBlur} */
export default SliderInput;
