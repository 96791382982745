import styled from 'styled-components';
import globalStyle from '../globalStyle';


export const Viewport = styled.div`
    flex: 1;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;

    @media screen and ( ${ globalStyle.desktop } ){
        padding: 0;
        overflow: hidden;
        height: 100vh;
    }
`

export const QuestionsWrapper = styled.div`
    max-width: 600px;
    width: 100%;

    margin: 0 auto;
    padding: 35vh 0;

    @media screen and ( ${ globalStyle.desktop } ){
        padding: 45vh 0;
    }
`
