import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Block, SubTitle, Title, Progress, Icon } from './style';

import { IconConversation, IconLock, IconDone } from './Icons';

function mapStateToProps(state){
    const { nav, interview } = state;
    return { nav, interview };
}
class InterviewNavBlock extends Component {
    render(){
        const questionsCount = this.props.questionsCount;
        const questionAnswerdCount = this.props.questionAnswerdCount;
        const active = this.props.active;
        const blocked = this.props.blocked && !questionAnswerdCount;
        const complete = questionAnswerdCount === questionsCount;
        const CSSClass = [(active ? "active" : ""),(blocked ? "blocked" : ""), (complete ? "complete" : "")].filter((val)=>val).join(" ");

        return  <Block color={this.props.color} className={ CSSClass } disabled={this.props.blocked} onClick={ (e)=>{ e.preventDefault(); this.props.onClick( this.props.id ); } } href={ "#block-" + this.props.id } >
                    <SubTitle>Seção {this.props.count}</SubTitle>
                    <Icon color={this.props.color} active={active}>
                        <IconConversation show={ active } active={ active } color={this.props.color}/>
                        <IconLock show={ blocked } active={ active }  color={this.props.color}/>
                        <IconDone show={ complete && !active } active={ active }  color={this.props.color}/>
                        <IconConversation show={ !active && !blocked && !complete } active={ active }  color={this.props.color}/>
                    </Icon>
                    <Title>{this.props.label}</Title>
                    <Progress color={this.props.color} className={'progressbar'} progress={( questionAnswerdCount / questionsCount ) * 100}>{questionAnswerdCount} / {questionsCount} questões respondidas</Progress>
                </Block>
    }
}

export default connect(mapStateToProps)(InterviewNavBlock);
