
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';

import AppLoader from './components/appLoader';
import AppLoaderFail from './components/appLoaderFail';
import InterviewNav from './components/interviewNav';

import { Router, Route, Switch } from "react-router-dom";

import globalStyle, { AppStyle } from './components/globalStyle';

import './App.css';
import AppDialog, { AppConfirmDialog } from './components/appDialog';
import Favicon from './components/appFavicon';

import { GlobalTooltipStyle } from './components/uiElements/tooltip/style';

import InterviewIntro from './components/interviewIntro';
import InterviewFinish from './components/interviewFinish';

function mapStateToProps(state){
    const { app, nav, interview } = state;
    return { app, nav, interview };
}

const mapDispatchToProps = dispatch => {
    return {
        checkHash: hash => {
            dispatch({ type: "CHECK_HASHVALIDATION", payload:hash });
            return Promise.resolve();
        },
        navGotoByPathName(url) {
          return dispatch({
            type: "NAV_GOTO_BY_PATHNAME",
            payload: { url }
          })
        },
        openAlert(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Dialog title", text: "Dialog description", type: "alert" }
            })
        },
        closeAlert(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Dialog title", text: "Dialog description", type: "alert" }
            })
        },
        loadLocalAnswers(){
            return dispatch({
                type: "LOAD_LOCAL_ANSWERS",
                payload: { }
            })
        },
        cancelLoadLocalAnswers(hash){
            dispatch({ type: "UPDATE_LOCAL_ANSWERS_LOADED", payload: true })
            dispatch({ type: "CLEAR_LOCAL_ANSWERS", payload: { hash } })
        }
    }
}

class App extends Component {

    constructor(props){
        super(props);
        let search = new URLSearchParams(window.location.search);

        this.props.checkHash( search.get('h'));
        this.history = props.history;

        this.state = {
            hash: search.get('h')
        }

        this.onCancelLoad = this.onCancelLoad.bind(this);
        this.onConfirmLoad = this.onConfirmLoad.bind(this);
    }

    componentDidMount(){
        this.history.listen((location, action) => {
            this.automaticUpdataVars(location);
        });
    }

    automaticUpdataVars(location){
        this.props.navGotoByPathName(location.pathname);
    }

    getCurrentBlockTheme(){
      let currentBlock = this.props.nav.currentBlock;
      if( currentBlock === undefined ) return;

      const interview = this.props.interview;
      const blocks = interview ? interview.blocks : [];
      if( !blocks ) return;

      const block = blocks[ currentBlock ];

      if(block && (block.colorMode || block.styleMode)) {
        return {
          colorMode: block.colorMode,
          styleMode: block.styleMode
        };
      }

      return;
    }

    getAppTheme() {
      return {
        colorMode: this.props.app.colorMode,
        styleMode: this.props.app.styleMode
      }
    }

    getTheme(){
        let appTheme = this.getAppTheme();
        let blockTheme = this.getCurrentBlockTheme();
        let defaultTheme = process.env.REACT_APP_DEFAULT_THEME_COLOR
        let defaultColors = [ 'red', 'blue', 'green', 'orange', 'purple' ];

        if(blockTheme) {
          if(blockTheme.styleMode === 'material') return blockTheme.styleMode;
          else return blockTheme.colorMode;
        } else {
          if(appTheme.styleMode === 'material') return appTheme.styleMode;
          else return appTheme.colorMode || defaultColors[ parseInt( this.props.nav.currentBlock ) % defaultColors.length ] || defaultTheme;
        }
    }

    onConfirmLoad(evt) {
      evt.confirmed = undefined;
      this.props.loadLocalAnswers();
    }

    onCancelLoad(evt){
      evt.confirmed = undefined;
      this.props.cancelLoadLocalAnswers(this.state.hash);
    }

    render() {
        const hashCheck = this.props.app.hashValidated;
        const isInit = this.props.app.isInit;
        const localAnswersLoaded = this.props.app.localAnswersLoaded;
        const themeName = this.getTheme();
        const theme = {
            main: globalStyle[ themeName ],
            secondary: globalStyle[ themeName + "Secondary" ] || "white",
            gradient: globalStyle[ themeName + "Gradient" ],
            icon: globalStyle[ themeName + "Favicon" ],
            title: globalStyle[ themeName + "Title" ],
            text: globalStyle[ themeName + "Text" ],
            subText: globalStyle[ themeName + "SubText" ],
            button: globalStyle[ themeName + "Secondary" ] || globalStyle[ themeName ],
            buttonHover: globalStyle[ themeName + "Secondary" ] || globalStyle[ themeName ],
            material: ( themeName === "material" )
        }

        return (
            <AppStyle color={ theme }>

                { ( hashCheck === null ) &&
                    <AppLoader />
                }

                { ( hashCheck === false ) &&
                    <AppLoaderFail/>
                }

                { ( hashCheck === true ) &&
                    <div className="App">
                        <AppConfirmDialog type="yn"
                            title={'load-local-answers-title'}
                            text={'load-local-answers-text'}
                            showConfirm={!localAnswersLoaded}
                            onConfirm={this.onConfirmLoad}
                            onCancel={this.onCancelLoad}
                            color={theme}/>

                        <Favicon color={ theme } />
                        <AppDialog color={ theme } />

                        { isInit && <Router history={this.props.history}>
                            <Switch>
                                <Route path="/intro"><InterviewIntro color={theme}/></Route>
                                <Route path="/finished"><InterviewFinish color={theme}/></Route>
                                <Route path="/">
                                    <Fragment>
                                        <InterviewNav color={ theme } hash={ this.state.hash } />
                                        <GlobalTooltipStyle color={ theme }/>
                                    </Fragment>
                                </Route>
                            </Switch>
                        </Router> }
                    </div>
                }
            </AppStyle>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
