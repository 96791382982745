import React from 'react';
import QuestionBasic, { t, Actions } from '../../questions';
import {connect} from 'react-redux';

import { Title, Description, Question } from '../style';


function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
        ...Actions(dispatch)
    }
}

class QuestionNavigation extends QuestionBasic{
    constructor(props){
        super(props, []);
        this.state.status = true;
        this.autoSubmitTimer = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      super.componentDidUpdate(prevProps, prevState, snapshot);
      if(!prevProps.focused && this.props.focused && !this.props.isInterviewEnding) {
        if (this.autoSubmitTimer) clearTimeout(this.autoSubmitTimer);

        this.autoSubmitTimer = setTimeout(() => {
          if(this.props.active && this.props.focused) this.props.onGoNext();
        }, 2000);
      }
    }

    componentWillUnmount() {
      if (this.autoSubmitTimer) clearTimeout(this.autoSubmitTimer);
    }

    renderHeader(){
        const isEding = this.props.isInterviewEnding;
        const type = isEding ? 'interview' : 'block' ;
        const questionText = t(`end-${type}-title`)
        const descriptionText = t(`end-${type}-description`)
        return <header>
            { this.props.question && <Title color={this.props.color}><span dangerouslySetInnerHTML={{__html: questionText }} /></Title> }
            { this.props.description && <Description color={this.props.color} dangerouslySetInnerHTML={{__html: descriptionText }}/> }
        </header>;
    }

    render(){
        return <Question tabIndex="-1" onFocus={this.onFocus} className={ this.props.active ? "active" : "" } color={this.props.color} id={this.props.navId}>
            { this.renderHeader() }
        </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionNavigation);
