import React, { Component } from 'react';

import anime from 'animejs';
import Swiper from './swiper';

import styled from 'styled-components';

import ScrollBar from './scrollBar';

import globalStyle from '../globalStyle';

import isMobile from '../../services/mobileDetect';

export const Fake = styled.div`
    position: absolute;
    left: 50%;
    top: 0px;
    width: 20px;
    height: 20px;
    border: 5px red solid;
    border-width: 1px 0 0 1px;
    opacity: .5;

    &:before{
        position: absolute;
        top: -20px;
        left: -20px;
        content: " ";
        width: 20px;
        height: 20px;
        border: 1px red solid;
        border-width: 0 1px 1px 0;
    }
`

const Scrolled = styled.div`
    position: absolute;
    top: ${ props => props.y ? Math.round(props.y) : 0 }px;
    width: 100%;
    padding: 0;
`

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    &.unfocused article{
        opacity: .6;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        overflow: hidden;
    }
`

export default class ScrollerBox extends Component {
    constructor(props){
        super(props);
        this.scrolling = false;
        this.autoScroll = false;
        this.scrollAnime = null;
        this.scrollerMargin = 0;

        this.y = 0;
        this.startY = 0;
        this.state = {
            propSize: 1,
            propPos: 0,
            contentSize: ''
        };

        this.move = this.move.bind(this);
    }

    componentDidMount(){
        this.scroller = document.getElementById(this.props.dom);
        this.contentDom = document.getElementById(this.props.contentDom);

        // auto detect
        this.detectionId = setTimeout(this.activeScrollDetection.bind(this), 150);
        window.addEventListener('request-scroll', this.processRequestScroll.bind(this))
    }

    componentWillUnmount(){
        if( this.detectionId ) clearTimeout( this.detectionId );
        if( this.scrollAnime ) this.scrollAnime.pause();
        window.removeEventListener('request-scroll', this.processRequestScroll.bind(this))
    }

    calculateTargetPosition(dom) {
        const minScroll = isMobile ? 50 : 20;
        const top = dom.offsetTop;
        const height = dom.getBoundingClientRect().height;
        const viewportHeight = document.getElementById("interview-content-viewport").getBoundingClientRect().height;
        const relCenter = top - viewportHeight / 2 + height / 2;
        const targetPoint = Math.min( top - minScroll, relCenter );

        const max = top + height;
        const min = targetPoint - viewportHeight / 2;

        return { targetPoint, min, max }
    }

    processRequestScroll(evt) {
        let min, max, targetPoint, containerPosition;
        let { target, container, justUpdate } = evt.detail
        let targetPosition = this.calculateTargetPosition(target)

        if(container) {
          containerPosition = this.calculateTargetPosition(container)
          max = containerPosition.max
          min = containerPosition.min
        } else {
          max = targetPosition.max
          min = targetPosition.min
        }

        targetPoint = targetPosition.targetPoint

        this.scroller = document.getElementById(this.props.dom);
        this.contentDom = document.getElementById(this.props.contentDom);

        if(!justUpdate) {
            this.updateScroll( -1 * targetPoint,  -1 * min,  -1 * max );
        }
        else if(this.scrollMaxFocus !== -1 * max || this.scrollMinFocus !== -1 * min) {
            if(this.scroller) {
                this.scrollMinFocus = -1 * min;
                this.scrollMaxFocus = -1 * max;
            }
        }
    }

    scrollDetection( timestamp, focused ){
        if( !this.scroller || !this.contentDom ) return false;
        if( this.autoScroll ) return false;

        const windowHeight = this.scroller.getBoundingClientRect().height;
        const top = -1 * this.y;
        const max = this.contentDom.getBoundingClientRect().height + this.scrollerMargin - windowHeight;
        const prop = .5; // element height proporction 0 = top, 1 = bottom
        const target =  ( prop * ( windowHeight - this.scrollerMargin ) ) + this.scrollerMargin / 2;
        const ref = top + target;
        // if( this.refs.fake ) this.refs.fake.style.top = this.scrollMaxFocus + "px" ; // to debbug the target
        window.dispatchEvent( new CustomEvent('detect-scroll', { detail: { ref, target, windowHeight, top, max, timestamp, focused } }) );
    }

    activeScrollDetection( focused ){
        if( this.autoScroll ) return false;
        const maxUpdateRate = 200;

        const now = new Date().getTime();
        if( this.detectionId ) clearTimeout( this.detectionId );
        const timestamp = now - this.scrolling;

        if( this.scrolling && timestamp > maxUpdateRate ){
            this.scrollDetection( timestamp );
        }else{
            // one more time to be sure
            this.detectionId = setTimeout(()=>{
                this.scrolling = false;
                this.scrollDetection( 0, focused );
            }, maxUpdateRate * 1);
        }
        this.scrolling = now;
    }

    deactiveScrollDetection(){
        this.scrolling = false;
        if( this.detectionId ) clearTimeout( this.detectionId );
    }

    updateScroll( pos, min, max ){

        if( this.scroller ){
            const duration = Math.min( 1500, Math.abs( this.y - pos ) * 2 + 500 );
            let controller = { y: this.y };

            if( min ) this.scrollMinFocus = min;
            if( max ) this.scrollMaxFocus = max;
            this.autoScroll = true;
            if( this.scrollAnime ) this.scrollAnime.pause();

            this.scrollAnime = anime({
                                    targets: controller,
                                    y: pos,
                                    round: 1,
                                    easing: 'easeInOutQuart',
                                    duration: duration,
                                    update: (anim)=>{
                                        this.changeY( controller.y );
                                        this.activeScrollDetection();
                                    },
                                    complete:()=>{
                                        this.autoScroll = false;
                                        this.activeScrollDetection( true );
                                    }
                                });
        }
    }

    moveStart( startPoint ){
        this.startY = this.y;
    }

    move( prop, dist ){
        if( this.autoScroll ) return false;

        const windowHeight = this.scroller.getBoundingClientRect().height;
        const contentHeight = this.contentDom.getBoundingClientRect().height;

        if( !dist && prop ){
            dist =  -1 * ( contentHeight * prop );
        }

        if( !dist || isNaN( dist ) ) return false;

        const max = contentHeight - windowHeight;
        const resultY = Math.max( -1 * max , Math.min( 0, this.startY - ( dist ) ))

        this.changeY( resultY, max );
        if( Math.abs( dist ) > 1 ) this.activeScrollDetection();
    }

    moveEnd( prop, dist ){}

    updateScrollStats(){
        const windowHeight = this.scroller.getBoundingClientRect().height;
        const contentHeight = this.contentDom.getBoundingClientRect().height;
        const max = contentHeight - windowHeight;

        const propSize = Math.min(1, windowHeight / contentHeight);
        const propPos = Math.abs( this.y  / max ) || 0 ;

        const propPropPos = propPos * ( 1 - propSize ) ;

        this.setState({
            propSize,
            propPos: propPropPos,
            contentSize: max
        });
    }

    changeY(y){
        this.y = y;
        this.refs.scrolled.style.top = this.y + "px";

        this.updateScrollStats();

        if( !this.autoScroll && this.scrollMinFocus < y ) return this.setState({ offLimit: true });
        if( !this.autoScroll && this.scrollMaxFocus > y ) return this.setState({ offLimit: true });
        if( this.state.offLimit ) this.setState({ offLimit: false });
    }

    render(){
        return <Wrapper className={ this.state.offLimit ? "unfocused" : "focused" }>
            <Swiper
                    onSwipeStart={ (startPoint)=>{ this.moveStart( startPoint ); } }
                    onSwipeEnd={ (endPoint)=>{ this.moveEnd(endPoint) } }
                    onSwipeMove={ (prop, dist)=>{ this.move(prop, dist) } }
                >
                <Scrolled ref="scrolled">
                    <Fake ref="fake" id="fake" style={{display: 'none'}}/>
                    {this.props.children}
                </Scrolled>
            </Swiper>
            <ScrollBar
                color={this.props.color}
                propPos={this.state.propPos}
                propSize={this.state.propSize}
                contentSize={this.state.contentSize}

                onDragStart={()=>{ this.moveStart() }}
                onDrag={(prop)=>{ this.move(prop) }}

                onClick={(pos)=>{ this.updateScroll(pos) }}

                />
        </Wrapper>
    }
}
