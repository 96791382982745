import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export default class IconList extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <path fill={this.props.color.secondary} d="M7 8h16a1 1 0 0 1 0 2H7a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z"/>
                        </g>
                    </svg>
                </Wrapper>;
    }
}