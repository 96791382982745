import beta from  './beta.js'
import staging from './staging.js'
import production from './production.js'
import development from './development.js'
const host = process.env.REACT_APP_DEPLOY_HOST

const configs = {
  beta,
  staging,
  production,
  development
}

export default configs[host || 'development']
