import React from 'react';
import QuestionBasic, { t, Actions } from '../../questions';
import {connect} from 'react-redux';

import { Question, InputLine } from '../style';
import LoopButton from '../../uiElements/LoopButton';
import styled from 'styled-components';
import globalStyle from '../../globalStyle';

import UploadInput from '../../uiElements/UploadInput';
import UploadListItem from './uploadListItem';
import Tooltip from '../../uiElements/tooltip';

import isMobile from '../../../services/mobileDetect';
import FileUploadService from '../../../services/fileUploadService';

function mapStateToProps(state){
    const { app, interview, nav } = state;
    return { app, interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
      ...Actions(dispatch)
    }
}

const UploadListElement = styled.div`
    &:empty{
        display: none;
    }
    margin-bottom: 10px;
`

const CompleteListElement = styled(LoopButton)`
    color: white;
    background: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
`


class QuestionUpload extends QuestionBasic{
    constructor(props){
        super(props);

        try{
            const fullUrl = props.app.apiRouters.files.url + props.app.apiRouters.files.route;
            FileUploadService.config( { url: fullUrl } );
        }catch(e){
            console.error( "Error on config Uploader", e );
        }

        this.state.loaders = [];
        this.state.fails = [];
        this.onChange = this.onChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onCancelUpload = this.onCancelUpload.bind(this);
        this.onFail = this.onFail.bind(this);

        this.registerKey('Tab', (e) => {
          // If has no loop and was added one file, tab cannot focus at dropzone,
          // but just in loopbutton and Ok button o  wraper.
          if(!this.props.allowLooping && this.state.values.length > 0) {
            if (e.shiftKey) {
              e.preventDefault();
            }
          }
          // If has loop, prevent shift tab to get out of dropzone element.
          else {
            if (e.shiftKey && !this.hasParent() && e.target.nodeName !== 'BUTTON') {
              e.preventDefault();
            }
          }
          this.renderCurrentError();
        }, false, true);
    }

    onDrop(evt){
        const uploads = evt.value;
        const loaders = FileUploadService.upload( uploads );

        this.setState({
            loaders: loaders
        });
    }

    onCancelUpload(){
        this.setState({
          loaders: FileUploadService.getAll()
        });
    }

    onFail(evt){
      let evtValue = evt.target ? evt.target.value : evt.value;

      // FileName values are used on interface. ServerName values are sended to
      // to api and indicates the path of file into server.
      const fileRaw = {
        fileUrl: evtValue.fileUrl,
        fileSize: evtValue.size,
        fileType: evtValue.type,
        fileName: evtValue.fileName,
        errorMsg: evtValue.error
      };

      let fails = [...this.state.fails];
      if( !fails.find(( file )=>( file.fileName === fileRaw.fileName )) ) fails.push(fileRaw)
      this.setState({ fails })

      this.setState({
        loaders: FileUploadService.getAll()
      });
    }

    onDeleteFail(file){
      let fails = [...this.state.fails];
      fails = fails.filter(( current )=>( current.fileName !== file.fileName ))
      this.setState({ fails })
    }

    onChange(evt){

        let evtValue = evt.target ? evt.target.value : evt.value;

        // FileName values are used on interface. ServerName values are sended to
        // to api and indicates the path of file into server.
        const fileName = evtValue.fileName;
        const fileRaw = {
          fileUrl: evtValue.fileUrl,
          fileSize: evtValue.size,
          fileType: evtValue.type,
          fileName: evtValue.fileName,
        };

        //const fileName = evt.target ? evt.target.value.fileName : evt.value.fileName ;
        //const serverName = evt.target ? evt.target.value.serverName : evt.value.serverName ;

        let fileNameValues = this.state.values ? [...this.state.values] : [];
        fileNameValues = this.props.allowLooping ? fileNameValues.concat( [ fileName ] ) : [ fileName ] ;

        let fileRawValues = this.state.rawValues ? [...this.state.rawValues] : [];
        fileRawValues = this.props.allowLooping ? fileRawValues.concat( [ fileRaw ] ) : [ fileRaw ] ;

        let toSubmitValue = this.getStorable(fileNameValues, fileRawValues);

        this.hasError(fileNameValues, (error)=>{
            this.setState({
                values: fileNameValues,
                rawValues: fileRawValues,
                errorMsg: error,
                status: !error,
                loaders: FileUploadService.getAll()
            });

            if( this.props.onChangeValue )  this.props.onChangeValue( this.props.id, toSubmitValue, !error, this.state.reference );
            if( this.props.onChange )       this.props.onChange( this.props.id, toSubmitValue, !error, this.state.reference );
        });

    }

    onSubmitNewValue(){
      if(this.state.status) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    }

    render(){
        const isMultiple = this.props.allowLooping;
        const fails = this.state.fails;
        const values = this.state.values;
        const disableUpload = !this.props.connectionStatus || ( !isMultiple && ( values.length > 0 || this.state.loaders.length > 0 || fails.length > 0 ));
        const placeholder = !this.props.connectionStatus ? "Without internet connection" :
                            ( !isMultiple && ( values.length > 0 || fails.length > 0 ) ) ? "File just selected" :
                            this.state.loaders.length ? "Uploading..." :
                                                        isMobile ? "Tap to choose a file to upload" :
                                                                   "Drag files or click to upload";
        const inputValue = values;

        const isValid = this.state.touched && this.state.status;
        const hasAnswer = this.state.touched && values;

        return <Question onFocus={this.onFocus} className={ this.props.active ? "active" : "" } color={this.props.color} id={this.props.navId}>
            { this.renderHeader() }
            <div>
                {this.renderWithTutorial(<InputLine ref="inputLine" onKeyDown={this._keyDown} tabIndex="0">
                    <UploadInput
                            ref="input"
                            isInvalid={!isValid}
                            hasAnswer={hasAnswer}
                            multiple={isMultiple}
                            disabled={disableUpload}

                            placeholder={t(placeholder)}
                            color={this.props.color}
                            value={inputValue}
                            onDrop={this.onDrop}
                            onBlur={this.onBlur}
                            id={this.props.id}
                        />
                </InputLine>)}
                { this.renderAlert() }
                <UploadListElement>
                    { this.state.loaders && this.state.loaders.map((loader)=>{
                        return <UploadListItem key={loader.name || Math.random()} uploader={loader} onSuccess={this.onChange} onFail={this.onFail} onCancel={this.onCancelUpload} color={this.props.color} />
                    }) }
                </UploadListElement>
                <div ref="loopButtons" onKeyDown={this._keyDown}>
                  { values && values.map((value, i)=>{
                      return <CompleteListElement color={this.props.color} onRemove={() => {this.onDeleteValue(i)}} key={i} value={value}/>
                    })
                  }
                </div>
                <div ref="loopButtons" onKeyDown={this._keyDown}>
                  { fails && fails.map((value, i)=>{
                      return <Tooltip {...{ distance: 15, position:"top", title:value.errorMsg, arrow: true }} key={i} ><LoopButton className="fail" color={this.props.color} onRemove={()=>{this.onDeleteFail(value)}} value={value.fileName}/></Tooltip>
                    })
                  }
                </div>
            </div>
        </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionUpload);
