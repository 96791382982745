
import React, { Component } from 'react';

import { Tooltip as RcTooltip } from 'react-tippy';

import isMobile from '../../../services/mobileDetect';

import 'react-tippy/dist/tippy.css';

export default class Tooltip extends Component {
  render() {
    if(isMobile) {
      return this.props.children || "";
    } else {
      return <RcTooltip {...this.props}>
              {this.props.children}
            </RcTooltip>
    }
  }
}
