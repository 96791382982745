import React, { Component } from 'react';

import Svg from 'react-inlinesvg';
import Tooltip from '../../uiElements/tooltip';
import { TutorialTipContent, Key, TipButton, InfoButton } from './style.js'

import translateService from '../../../services/translateService';

export const translateDictionary = new translateService();
export const t = translateDictionary.getTranslateFunction();

class TutorialTooltip extends Component {

    constructor(props){
        super(props);

        this.onGotIt = this.onGotIt.bind(this);
    }

    onGotIt() {
      if (this.props.onHide) {
        this.props.onHide();
      }
    }

    renderTooltipContent() {
      return  <TutorialTipContent>
                { this.props.keys.map(key => <Key key={key}>{key}</Key>) }
                <p>{ t(this.props.text) }</p>
                { !this.props.info &&
                  <TipButton onClick={this.onGotIt} color={this.props.color}>{ t('Got it!')}</TipButton>
                }
              </TutorialTipContent>
    }

    render(){
        if(!Array.isArray( this.props.keys ) || this.props.keys.length === 0) return this.props.children || ""
        return  <Tooltip
                  interactive
                  position={ this.props.position ? this.props.position : 'top-start' }
                  html={this.renderTooltipContent()}
                  open={this.props.open}
                  trigger={this.props.info ? 'mouseenter focus' : 'manual'}
                  arrow={true}
                  sticky={true}
                  stickyDuration={100}>
                  { !this.props.info && this.props.children }
                  { this.props.info &&
                    <InfoButton tabIndex="-1">
                      <Svg src={"/img/info.svg"}/>
                    </InfoButton>
                  }
                </Tooltip>
    }
}

export default TutorialTooltip;
