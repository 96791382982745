
import ConditionService from './ConditionService'

class navFlowService {
    constructor( variables, blocks, answers ){
        this.allVariables = variables;
        this.allBlocks = blocks;
        this.allAnswers = answers;
    }
    getAvailableBlocks(){
        let blocksList = Object.keys(this.allBlocks);
        if( !blocksList || ( blocksList.constructor !== Array ) || blocksList.length === 0 ) return [];

        return blocksList.reduce((filtered, id)=>{
            const block = this.allBlocks[id];

            if( block.is_visible &&
                block.variables_ids.length &&
              (block.conditions === undefined || ConditionService.getInstance().evaluate( block.conditions, this.allAnswers, undefined, 0 ))){
                filtered.push(id);
            }

            return filtered;
        }, []);
    }
    getAvailableBlockVariables( block ){
        if( !block ) return [];

        if( block.variables_ids ){
            return this.getAvailableVariables( block.variables_ids, 0 );
        }

        return { variables: [], counter: 0 };
    }
    getAvailableVariables( variableIdList, iteration, ignoreMandatory ){
        if( !variableIdList || ( variableIdList.constructor !== Array ) || variableIdList.length === 0 ) return false;

        let answeredCount = 0;
        let variablesCount = 0;
        let ended = false;
        let variables = variableIdList.reduce((filtered, id)=>{
            if (this.allVariables[id]) {
                let variable = this.allVariables[id];

                if(!variable.intro && !variable.finish) {
                  if(variable.conditions === undefined || ConditionService.getInstance().evaluate(variable.conditions, this.allAnswers, undefined, iteration)) {
                    if(!ended) {
                      let status = false;

                      // if question was answered, get its status flag
                      if(this.allAnswers[id] && this.allAnswers[id][iteration] !== undefined && this.allAnswers[id][iteration] !== null ) {
                        status =  this.allAnswers[id][iteration].status;
                      }

                      // if not ignoring mandatory on render, variable isn't mandatory, variable can be shown and
                      // status is false
                      if(!ignoreMandatory && variable.mandatory && variable.isVisible !== false && !status) {
                        // reached the end of this subtree because of mandatory question without answer or invalid
                        ended = true;
                      }

                      // if status is true, increment answered counter
                      if(status && !variable.isInjected) {
                        answeredCount++;
                      }

                      // add actual variable in filtered array
                      filtered.push(id);
                    }
                    if(!variable.isInjected) variablesCount++;
                  }
                }
            }
            return filtered;
        }, [])

        return {variables, variablesCount, answeredCount};
    }
    getNavTree(){
        let blocks = [];

        this.getAvailableBlocks().forEach((blockId, i, list)=>{
          const isLast = i === list.length - 1;
          const block = this.allBlocks[ blockId ];
          const availableVariables = this.getAvailableVariables( block.variables_ids, 0 );
          const subTree = this.getNavSubTree( availableVariables.variables, `${blockId}`, blockId, '' )
          const lastSubtreeVar = this.allVariables[subTree.subTree[subTree.subTree.length - 1].id]

          if( !isLast && lastSubtreeVar.isInjected){
            // remove end block variable
            subTree.subTree = subTree.subTree.slice(0,-1);
          }

          blocks.push({
              id: block.id,
              subVariables: subTree.subTree,
              answeredCount: availableVariables.answeredCount + subTree.answeredCount,
              variablesCount: availableVariables.variablesCount + subTree.variablesCount
          });
        });

        return blocks;
    }

    // Generates subtree for each variable received in array.
    // This is used mainly to process complexes subtree
    getNavSubTree( vars, navIdPrefix, blockId, groupPrefix ){
        if( !vars ) return;

        let answeredCount = 0;
        let variablesCount = 0;

        let subTree = vars.map( varId => {
            const variable = this.allVariables[varId];
            const subTree = { blockId: parseInt(blockId), id: variable.id, subVariables: [], navId: `${navIdPrefix}-${variable.id}`, groupPrefix };
            // if has subvariables
            if( variable.subVariables ){
                // if is answerd, get subtree for each variable iteration
                // in case of allowLooping === false, variable will have just one iteration
                let iterations = 1;
                const subVariables = variable.subVariables;

                // if variable has been answered, get count of loops
                if (this.allAnswers[variable.id] && this.allAnswers[variable.id][0].value) {
                  iterations = this.allAnswers[variable.id][0].value.length;
                }

                //for each iteration, find available variables and process childs subtree
                //flag true in getAvailableVariables is setted to construct all complex questions ignoring mandatory
                for (let i = 0; i < iterations; i++) {
                  const availableVariables = this.getAvailableVariables( subVariables, i, true );

                  if(availableVariables !== false) {
                    const iterationSubTree = this.getNavSubTree( availableVariables.variables, `${subTree.navId}-${i}`, blockId, `${subTree.navId}` );

                    // update subtree and childs subtree
                    subTree.subVariables[i] = iterationSubTree.subTree;

                    answeredCount += iterationSubTree.answeredCount;
                    answeredCount += availableVariables.answeredCount;

                    variablesCount += iterationSubTree.variablesCount; // Add child counter
                    variablesCount += availableVariables.variablesCount; // Add same level counter ignoring actual item
                  }
                }

                // If is a group of questions (aka complex variable), counter is not affected by this
                // specifc item, but only by child questions
                variablesCount--;

                if (this.allAnswers[variable.id] && this.allAnswers[variable.id][0].status) {
                  answeredCount--;
                }
            }
            return subTree;
        })
        return { subTree, variablesCount, answeredCount };
    }
}

export default function( variables, blocks, conditions, answers ){
    if (conditions) {
      ConditionService.getInstance().preCompile(variables, blocks, conditions)
    }

    return new navFlowService( variables, blocks, answers );
}
