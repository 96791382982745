import React, { Component } from 'react';

import { TextInput } from '../../globalStyle';

export const CurrencyService = {
    currencyToValue( input ){
        if( input === "" || input === undefined || input === null ) return { value: "" };
        if( !isNaN( input ) ) return { value: parseFloat(input) };

        const isNeg = input.indexOf("-") > -1;
        const currencySignal = input.replace(/^([\s-]*)([^0-9-]*)([0-9.,]+)/gi,"$2").trim() || false;
        // const countCommas = ( input.match( RegExp(',','g') ) || [] ).length;
        const countPeriods = ( input.match( RegExp('\\.','g') ) || [] ).length;

        const posComma = input.indexOf(",");
        const posPeriod = input.indexOf(".");

        const centSignal = ( countPeriods === 1 && posComma <= posPeriod ) ? "." : ",";
        const thousandSignal = centSignal === "." ? "," : ".";

        let value = input.replace(/[^0-9,.]*/gi,'');
        if( centSignal === "," ){
            // remove thousands
            value = value.replace(/\.+/gi,"");
            // convert cents
            value = value.replace(/,+/gi,".");
        }else if( countPeriods > 1 ){
            // remove dot thousands
            value = value.replace(/\./gi,"");
        }else if( countPeriods === 1 ){
            // remove cent thousands
            value = value.replace(/,+/gi,"");
        }else{
            // remove all
            value = input.replace(/[^0-9]+/gi,'');
        }

        value = parseFloat( value ) * ( isNeg ? -1 : 1 );

        return {
            value,
            thousandSignal,
            centSignal,
            currencySignal
        }
    },

    valueToCurrency( value, thousandSignal, centSignal ){
        if( isNaN( value ) || value === "" ) return value;
        let number = value || 0;
        const places = 2;
        const thousand = thousandSignal || ",";
        const decimal = centSignal || ".";
        let  negative = number < 0 ? "-" : "",
            i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
            // eslint-disable-next-line
            j = (j = i.length) > 3 ? j % 3 : 0;
        return negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
    },

    formatToCurrencyValue(value, defaultCurrency, changePrefix) {
      let currency = CurrencyService.currencyToValue(value)

      if((!currency.currencySignal || changePrefix) && currency.value !== '') {
        return defaultCurrency + ' ' + currency.value;
      } else {
        return value
      }
    },

    isValid( input ){
        return !isNaN( this.currencyToValue( input ).value );
    }
}

export default class CurrencyInput extends Component{
    constructor(props){
        super(props);

        const propValue = this.props.value ? this.getFormatedValue(this.props.value).value : "" ;
        this.state = {
            value: propValue
        }

        this.parentOnChange = this.props.onChange;
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onByPassChange = this.onByPassChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value === '' && nextProps.value !== this.state.value ) {
          this.setState({value: ''});
        }
        else if ( nextProps.value !== this.props.value && nextProps.value !== this.state.value ) {
            if( !this.isFocused() ){
                this.setState({
                    value: this.getFormatedValue( nextProps.value ).value
                });
            }
        }
    }

    focus() {
      this.refs.input.focus();
    }

    isFocused() {
      return this.refs.input === document.activeElement;
    }

    onByPassChange(evt){
        const scoped = {
            ...evt
        };
        this.setState({value: evt.target.value.replace(/[^,.0-9]/g,'')});
        evt.prefix = undefined;
        setTimeout(()=>{
            this.parentOnChange( scoped );
        }, 1);
    }

    getFormatedValue( input ){
        let values, value;

        values = CurrencyService.currencyToValue( input );
        if( !isNaN(values.value) ){
            value = CurrencyService.valueToCurrency( values.value, values.thousandSignal, values.centSignal );
        }else{
            value = input;
        }

        return {
            ...values,
            raw: values.value,
            value
        }
    }

    onBlur(evt){
        this.onChange(evt);
        this.props.onBlur();
    }

    onChange(evt){
        let values;
        const input = evt.target.value;

        values = this.getFormatedValue(input);
        evt.target.value = values.value;
        evt.prefix = values.value ? values.currencySignal : "undefined";

        this.parentOnChange( evt, values );
        if( this.props.onBlur ) this.props.onBlur(evt);
        this.setState({value: values.value});
    }

    render(){
        const props = {...this.props};
        delete props.value;
        delete props.onBlur;
        delete props.onChange;
        return <TextInput type="text" ref="input" {...props} value={this.state.value} onChange={this.onByPassChange} onBlur={this.onBlur}/>
    }
}
