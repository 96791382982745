
class ApiInterface {
  validateHash(hash) {
    throw new Error('Unimplemented interface method');
  }

  storeInterview(hash, interview) {
    throw new Error('Unimplemented interface method');
  }

  storeAnswers(hash, answers) {
    throw new Error('Unimplemented interface method');
  }
}

export default ApiInterface;
