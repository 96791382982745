
import CompilerInterface from './CompilerInterface.js'

import Evaluable from '../Evaluable.js'
import RuleCompiler from './RuleCompiler.js'

export default class ConditionCompiler extends CompilerInterface{

  constructor () {
    super()
    this.ruleCompiler = new RuleCompiler()
  }

  /* Using expression gived to constructor, generates an array containing
   * objects describing all tokens detected in the string.
   */
  _tokenize (expression) {
    let tokens = [ ]
    let idBuffer = [ ]
    let expr = expression.split('')

    // Token identifiers
    let isDigit = (chr) => /\d/.test(chr)
    let isOperator = (chr) => /(&|\||!)/.test(chr)
    let isUnaryOperator = (chr) => /(!)/.test(chr)
    let isLeftParentesis = (chr) => /\(/.test(chr)
    let isRightParentesis = (chr) => /\)/.test(chr)

    // Save id buffer adding the id generated into tokens list
    let saveId = () => {
      if (idBuffer.length > 0) {
        tokens.push({ type: 'id', value: idBuffer.join('') })
        idBuffer = [ ]
      }
    }

    // For each char of expression...
    expr.forEach((chr) => {
      // If is digit, add it to idBuffer
      if (isDigit(chr)) {
        idBuffer.push(chr)
      }
      // If is operator, save id buffer and add operator to tokens list
      else if (isOperator(chr)) {
        saveId()
		    tokens.push({
          type: 'op',
          value: chr,
          unary: isUnaryOperator(chr) ? true : false
        })
      }
      // If is parentesis, save id buffer and add parentesis to tokens list
      else if (isLeftParentesis(chr)) {
        saveId()
        tokens.push({ type: 'lp', value: chr })
      }
      // If is parentesis, save id buffer and add parentesis to tokens list
      else if (isRightParentesis(chr)) {
        saveId()
        tokens.push({ type: 'rp', value: chr })
      }
      // Anything else isn't recognized by this specific language grammar
      else {
        throw new Error('Unknow token')
      }
    })

    // Save id buffer
    saveId()

    return tokens
  }

  /* Populate abstract syntax tree (AST) using references to comparators id.
   */
  _populateAst(ast, compiledRules) {
    let newAst = [undefined, undefined, undefined]
    // Populate left childs
    if (ast[0]) {
      newAst[0] = this._populateAst(ast[0], compiledRules)
    }
    // Populate right childs
    if (ast[2]) {
      newAst[2] = this._populateAst(ast[2], compiledRules)
    }

    // If actual node is op, set it's value, else insert comparator object.
    if (ast[1].type === 'op') {
      newAst[1] = ast[1].value
    } else if(ast[1].type === 'id') {
      newAst = compiledRules[ast[1].value]
    } else {
      throw new Error('Invalid node in abstract syntax tree')
    }

    return newAst
  }

  // Generates comparators based on conditions hash map
  _compileRules (rules) {
    let compiledRules = { }

    // For each condition ID, generate Comparator object
    Object.keys(rules).forEach((key) => {
      compiledRules[key] = this.ruleCompiler.compile(rules[key])
    })

    return compiledRules
  }

  /* Generates evaluable object based on abstract syntax tree (AST) generated by
   * parser.
   */
  _generateEvaluable (ast, rules) {
    let compiledRules = this._compileRules(rules)
    let newAst = this._populateAst(ast, compiledRules)
    return new Evaluable(newAst)
  }

  /* Generates evaluable object based on conditions expression and comparators
   * generated before based on condition objects provided by api.
   */
  compile (rules, expression) {
    return this._generateEvaluable(this._parse(this._tokenize(expression)), rules)
  }
}
