import React from 'react';
import KeyboardControlled from '../../keyboardControlled';

import Select from './select'

class SelectInput extends KeyboardControlled {

  constructor(props) {
    super(props);
    this.registerKey('ArrowUp', undefined, true, true);
    this.registerKey('ArrowDown', undefined, true, true);
    this.registerKey('Enter', (e) => {
      //if opened, stopPropagation and preventDefault
      if(this.refs.input.isOpen()) {
        e.stopPropagation();
        e.preventDefault();
      }
    }, false, false);
  }

  focus(attr) {
    this.refs.input.focus(attr);
  }

  render() {
    return  <div style={{width: '100%'}} tabIndex="-1" onKeyDown={this._keyDown}> <Select ref="input" {...this.props}/> </div>
  }
}

export default SelectInput;
