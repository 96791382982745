
const PREFIX = "iDox-"

export default class localStorageService {

  static ANSWERS = PREFIX + 'answers';
  static USER_INFO = PREFIX + 'user_info';
  static INTERVIEWS = PREFIX + 'interviews';
  static INTERVIEW_TUTORIAL = PREFIX + 'interview-tutorial';
  static NAVMENU_CLOSE_STATE = PREFIX + 'navmenu-close-state';

  static key(key, sufix) {
    return sufix ? `${key}-${sufix}` : key;
  }

  static get(key, sufix) {
    let item = localStorage.getItem(localStorageService.key(key, sufix))

    if (item === undefined) item = null

    return item
  }

  static set(key, value, sufix) {
    return localStorage.setItem(localStorageService.key(key, sufix), value);
  }
}
