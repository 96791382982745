import React from 'react';
import {connect} from 'react-redux';

import KeyboardControlled from '../keyboardControlled'

import styled from 'styled-components';
import globalStyle, { Button, ButtonLine } from '../globalStyle';

import isMobile from '../../services/mobileDetect';
import translateService from '../../services/translateService';

import IconSpin from './iconSpin';
import IconDone from './iconDone';
import IconFail from './iconFail';

const translateDictionary = new translateService();
const t = translateDictionary.getTranslateFunction();


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${ props => props.type === 'alert' ? 'white' : props.color.material ? props.color.secondary : props.color && props.color.gradient ? props.color.gradient : globalStyle.defaultGradient };
        opacity: ${ props => props.type === 'alert' ? 0.9 : 0.5 };
    }

    z-index: 100;
    transition: opacity 0.5s;
    opacity: 1;

    &.hide{
        opacity: 0;
        pointer-events:none;
    }
`

const Dialog = styled.div`
    background: white;
    padding: 64px 32px 57px;
    border-radius: ${ props => props.color.material ? "4px" : "10px" };
    min-width: 300px;
    width: 550px;
    max-width: 90vw;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate( -50%, -50% );
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    text-align: center;
    z-index: 101;

    h1{
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: black;
        margin-top: 25px;
        white-space: pre-wrap;
    }

    p{
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #797979;
        white-space: pre-wrap;
    }
`

const CloseButton = styled.button`
    cursor: pointer;
    display: block;
    border-radius: ${ props => props.color && props.color.material ? "0px" : "5px" };
    background: url(/img/close.svg) no-repeat center;
    border: 1px solid transparent;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.5;

    overflow: hidden;
    text-indent: -300px;
    transition: all .2s;

    &:hover{
        opacity: 1;
    }

    &:focus{
        outline: none;
        border: 1px solid ${ props => props.color && props.color.main };
    }
`

function mapStateToProps(state){
  const { modals } = state;
  return modals;
}

const mapDispatchToProps = dispatch => {
    return {
          closeModal: modalData => {
              dispatch({ type: "CLOSE_DIALOG", payload:modalData });
              return Promise.resolve();
          },
          openModal: modalData => {
              dispatch({ type: "OPEN_DIALOG", payload:modalData });
              return Promise.resolve();
          }
      }
  }

const AppConfirmSubDialog = class AppComfirmDialog extends KeyboardControlled {
    constructor(props){
        super(props);

        this.state = {
            showConfirm: this.props.showConfirm
        }

        this.registerKey('Escape', (e) => {
          if(this.state.showConfirm) {
            this.onCancel(e);
          }
        }, true, true);

        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    componentDidMount(){
        /* At this life cycle point, toggleConfirm just needs to be called
        * when its intended to open modal, otherwise dialog not will be
        * opened and CLOSE_DIALOG action not will be called unecessarily.
        */
        if(this.state.showConfirm) {
          this.toogleConfirm( this.state.showConfirm );
        }
    }
    componentWillReceiveProps( nextProps ){
        if( this.state.showConfirm !== nextProps.showConfirm ){
            this.toogleConfirm( nextProps.showConfirm );
        }
    }
    toogleConfirm( showModal ){
        if( showModal ){
            this.props.openModal({ title: this.props.title, text: this.props.text, type: "confirm" });
        } else {
            this.props.closeModal({ title: this.props.title, text: this.props.text, type: "confirm" });
        }
        this.setState({ showConfirm: showModal });
    }
    onConfirm( evt ){
        if(this.props.onConfirm){
            evt.confirmed = true;
            this.props.onConfirm(evt);
        }else{
            this.props.closeModal({ title: this.props.title, text: this.props.text, type: "confirm" });
        }
    }
    onCancel( evt ){
        if(this.props.onCancel){
            evt.confirmed = false;
            this.props.onCancel(evt);
        }else{
            this.props.closeModal({ title: this.props.title, text: this.props.text, type: "confirm" });
        }
    }
    render(){
        const show = this.props.showConfirm;
        const hideCancel = this.props.hideCancel;
        const confirmText = this.props.type === 'yn' ? t('Yes') : t("OK") ;
        const cancelText = this.props.type === 'yn' ? t('No') : t("Cancel") ;

        return <div>{ show &&
            <Dialog color={this.props.color} tabIndex="0" onKeyDown={this._keyDown}>
            { this.props.title && <h1>{t(this.props.title)}</h1> }
            { this.props.text && <p>{t(this.props.text)}</p> }
            <ButtonLine>
                <Button onClick={this.onConfirm} value={this.props.showConfirm} color={this.props.color} autoFocus>{confirmText}</Button>
                { !hideCancel && <Button onClick={this.onCancel} value={this.props.showConfirm} color={this.props.color} className={"invert"} >{cancelText}</Button> }
            </ButtonLine>
        </Dialog>}</div>;
    }
}
export const AppConfirmDialog = connect(mapStateToProps, mapDispatchToProps)(AppConfirmSubDialog);

class AppDialog extends KeyboardControlled {
    constructor( props ){
        super( props );

        this.state = {
            title: undefined,
            description: undefined,
            type: undefined,
            oldType: '',
            texts: []
        }

        this.registerKey('Escape', (e) => {
          this.onClose(e);
        }, true, true);

        this.delayShow = undefined;
        this.onClose = this.onClose.bind(this);
    }

    componentWillReceiveProps(nextProps){
        // Create a little delay to avoid show dialog to very fast loadings
        if( this.delayShow ) clearTimeout( this.delayShow );

        const dialogCall = nextProps.dialogCalls[0];
        const title = dialogCall && dialogCall.title ? dialogCall.title : false ;
        const text = dialogCall ? dialogCall.title ? dialogCall.text : dialogCall : false ;
        const type = dialogCall && dialogCall.type ? dialogCall.type : false;

        if( !this.state.description && type === "loading" ){
            // apply delay to avoid loading flash
            this.delayShow = setTimeout(()=>{
                this.setState({
                    title: title,
                    description: text,
                    type: type
                })
            }, 500);
        } else {

            this.setState({
                title: title,
                description: text,
                type: type,
                oldType: this.state.type
            })
        }
        if( type === "success" || type === "error" ){
            // autoclose
            setTimeout(()=>{
                this.props.closeModal({ text, title, type });
            }, 2500);
        }

        this.setState({
            texts: nextProps.texts
        })
    }

    onClose(){
        const { title, description, type } = this.state;
        this.props.closeModal({ title: title, text: description, type: type });
    }

    render(){
        let overlayType = this.state.type === false ? (this.state.oldType === 'alert' ? 'alert' : false ) : this.state.type
        return  <Overlay type={ overlayType + ''} color={ this.props.color } className={ this.state.type ? "" : "hide" }>
                {   ( (this.state.type !== "confirm" && this.state.description) || this.state.type === "loading" ) &&
                    <Dialog color={this.props.color} tabIndex="1" onKeyDown={this._keyDown}>
                        { false && !isMobile && <CloseButton autoFocus onClick={this.onClose} color={this.props.color} >{ t("Close") }</CloseButton> }
                        { this.state.type === "loading" && <IconSpin color={ this.props.color } /> }
                        { this.state.type === "success" && <IconDone color={ this.props.color } /> }
                        { this.state.type === "error" && <IconFail color={ this.props.color } />  }
                        { this.state.title && <h1>{ t(this.state.title) }</h1> }
                        { this.state.description && <p>{ t(this.state.description) }</p> }
                        { this.state.type === "alert" && isMobile && <ButtonLine>
                            <Button onClick={this.onClose} color={this.props.color}>{t("Got it!")}</Button>
                          </ButtonLine> }
                        { ( this.state.type === "modal" || ( this.state.type === "alert" && !isMobile )) && <ButtonLine>
                            <Button autoFocus onClick={this.onClose} color={this.props.color}>{t("OK")}</Button>
                        </ButtonLine> }
                    </Dialog> }
                </Overlay>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppDialog);
