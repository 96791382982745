
import Comparator from './Comparator.js'

/* Uses an evaluable tree generated by ConditionCompiler to determine if there
 * expression compiled is true or false.
 */
export default class Evaluable {
  constructor (evaluableTree) {
    this.value = evaluableTree
  }

  /* Operates left and right operands using operator.
   */
  _operate (operator, left, right) {
    switch (operator) {
      case '!':
        return !left
      case '&':
        return left && right
      case '|':
        return left || right
      default:
        throw new Error(`Unknow operator:`, operator)
    }
  }

  /* Determines if the result of analysis of evaluable tree is true of false
   * using actual variables values.
   */
  _evaluate (node, variables, key) {
    let leftResult = null
    let rightResult = null

    if (node[1].constructor === Comparator) {
      return node[1].test(variables, key).find(item => item) === true
    } else {
      if (node[0]) {
        leftResult = this._evaluate(node[0], variables, key)
      }

      if (node[2]) {
        rightResult = this._evaluate(node[2], variables, key)
      }
      return this._operate(node[1], leftResult, rightResult)
    }
  }
  /* Public interface to _evaluate method.
   */
  evaluate (variables, key) {
    return this._evaluate(this.value, variables, key)
  }
}
