
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import { connectRouter, routerMiddleware } from 'connected-react-router';

import initState from './initState'

import appReducer from './reducers/app'
import navReducer from './reducers/nav'
import interviewReducer from './reducers/interview'
import modalsReducer from './reducers/modals'

import middlewares from './middlewares'

const reducers = (history)=>combineReducers({
    router: connectRouter(history),
    nav: navReducer, 
    app: appReducer, 
    interview: interviewReducer, 
    modals: modalsReducer
})

export default function initializeStore (history, initialState = initState) {
    return createStore(
        reducers(history), 
        initialState, 
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history),
                ...middlewares
            )
        )
    )
}
