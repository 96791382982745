import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 99px;
        height: 99px;
        border-radius: 50%;
    }
`;

export default class IconDone extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="107" viewBox="0 0 107 107">
                        <defs>
                            <linearGradient id="a456" x1="88.747%" x2="12.494%" y1="93.439%" y2="7.314%">
                                <stop offset="0%" stopColor={this.props.color.main}/>
                                <stop offset="100%" stopColor={this.props.color.main}/>
                            </linearGradient>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle cx="49.5" cy="49.5" r="49.5" stroke="url(#a456)" strokeWidth="8" transform="translate(4 4)"/>
                            <g transform="rotate(45 47.879 55.121)">
                                <mask id="c456" fill="#fff">                            
                                    <path d="M46.953 46.953H30.687a1 1 0 0 0-1 1v4.094a1 1 0 0 0 1 1h16.266v16.266a1 1 0 0 0 1 1h4.094a1 1 0 0 0 1-1V53.047h16.266a1 1 0 0 0 1-1v-4.094a1 1 0 0 0-1-1H53.047V30.687a1 1 0 0 0-1-1h-4.094a1 1 0 0 0-1 1v16.266zM50 82.5c-17.95 0-32.5-14.55-32.5-32.5S32.05 17.5 50 17.5 82.5 32.05 82.5 50 67.95 82.5 50 82.5z"/>
                                </mask>
                                <g fill={this.props.color.main} mask="url(#c456)">
                                    <path d="M0 0h100v100H0z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </Wrapper>;
    }
}