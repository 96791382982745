import styled from 'styled-components';
import globalStyle from '../globalStyle';

export const Container = styled.section`
    display: flex;
    flex-direction: column;

    height: 100%;
    overflow: hidden;

    @media screen and ( ${ globalStyle.desktop } ){
        flex-direction: row;
    }
`

export const CloseArrowWrapper = styled.div`
    position: absolute;
    right: 20px;
`

export const Sidebar = styled.aside`
    overflow: auto;
    position: relative;
    z-index: 99;

    width: 100vw;
    background-image: ${ props => props.color && props.color.gradient ? props.color.gradient : globalStyle.defaultGradient };
    box-shadow: ${ props => props.color && props.color.material ? "0 0 2px 2px rgba(0, 0, 0, 0.1)": "0 0 5px 2px rgba(0, 0, 0, 0.3)" };

    padding-top: 100px;
    max-height: 100%;
    box-sizing: border-box;

    &.close{
        padding-top: 70px;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        height: 100vh;
        max-width: 720px;

        box-shadow: none;
        padding-top: 0px;

        width: 30vw;
        min-width: 500px;
        transition: all 1s;

        &.close{
            padding-top: 0px;
            width: 90px;
            min-width: 90px;
        }

    }
`

export const Blocks = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
    box-sizing: border-box;
    flex-wrap: wrap;

    @media screen and ( ${ globalStyle.desktop } ){
        padding: 0 30px 30px;
        max-width: 720px;
        width: 30vw;
        min-width: 500px;
    }
`

export const Cover = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    box-sizing: border-box;

    h1 {
        font-size: 30px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        padding: 0 40px 0 0;
        margin: 0 0 20px;
        color: ${ props => props.color && props.color.material ? props.color.secondary : "white" };
    }

    p{
        font-size: 15px;
        max-width: 425px;
        color: ${ props => props.color && props.color.material ? props.color.subText : "white" };
    }

    @media screen and ( ${ globalStyle.desktop } ){
        height: calc(100vh - 180px);
        max-width: 720px;
        width: 30vw;
        min-width: 500px;
        padding: 0 30px 0px;

        h1{
            font-size: 48px;
            padding: 0;
        }

        p{
            font-size: 16px;
        }
    }
`

export const Pre = styled.pre`
    margin: 0 30px;
    background: rgba(255, 255, 255, 0.6);
    padding: 10px 15px;
    border-radius: 5px;

    font-family: monospace;
    line-height: 1.5em;

    height: calc( 100vh - 180px );

    box-shadow: 0px 2px 2px rgba(0, 0, 0, .5) inset;
`
