import React, { Component } from 'react';
import { Container, Text } from './style';

import translateService from '../../services/translateService';
import RemoteAPI from '../../services/ApiService/RemoteApi';

let translateDictionary = new translateService();
let t = translateDictionary.getTranslateFunction();

export default class AppLoader extends Component {
    constructor(props){
        super(props);
        this.state = {
            seconds: 5,
        }
    }
    
    componentDidMount() {
        this._interval = setInterval(() => {
            const { seconds } = this.state;

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            
            if (seconds === 0) {
                window.location.href = RemoteAPI.getInstance().redirectTo;
                clearInterval(this._interval)
            } 
        }, 1000)
    }
    
    componentWillMount() {
        clearInterval(this._interval);
    }
    
    render(){
        const { seconds } = this.state;
        
        return <Container>
                    <Text>
                        <h2>{ t('this-link-has-already-been-used-and-is-no-longer-valid') }</h2>
                        <p>{ t('we-are-redirecting-you-to-the-main-page') }</p>
                        <p>{` ${t('redirect-in')} ${seconds}s`}</p>
                    </Text>
                    
                </Container>;
    }
}
