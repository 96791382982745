import React from 'react';
import QuestionBasic, { t, Actions } from '../../questions';
import {connect} from 'react-redux';

import { Question, Options, Option } from '../style';

function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
        ...Actions(dispatch)
    }
}

class QuestionMultiChoices extends QuestionBasic{
    constructor(props){
        super( props,
               props.type === 'YN' ? [t("letter-Y"), t("letter-N")] : ['A', 'B','C','D'],
               props.type === 'MUL' ? 'use-keyboard-to-select-itens' : 'use-keyboard-to-select-item'
             );

        let list = this.props.type === "YN" ? [{index: 0, answer: 1, label: t("yes")},{index:1, answer: 0, label: t("no")}] : this.props.list;
        this.state.list = list;
        this.state.focus= undefined;

        // keyboard events
        let keys = [
          'a','b','c','d','e','f','g','h','i','j','k','l','m',
          'n','o','p','q','r','s','t','u','v','w','x','y','z'
        ];

        this.registerKey(keys, (e) => { this.keyboardInput(e); }, true, true);

        this.registerKey('Tab', (e) => {
          if(e.shiftKey && !this.hasParent() && this.refs.input === document.activeElement){
            e.preventDefault();
          }

          if( document.activeElement
            && ( document.activeElement.type === "checkbox" || document.activeElement.type === "radio" )
            && this.refs.input !== document.activeElement ){
            e.stopPropagation();
          }
          
          this.renderCurrentError();
        }, false, false);

        this.registerKey(['ArrowLeft', 'ArrowRight'], undefined, false, true);
    }

    hasError(values, callback){
        if( this.props.mandatory && ( !values || values.length === 0 )) return callback(t("This is a obligatory field"), false);

        this.triggerAPIEvent('validation', values ).then((response)=>{
            const error = response && response.length ? response[0].result === false ? response[0].message : false : false ;
            callback( error, false );
        });
        return callback( true, true );
    }

    isValid(values){
        return !( this.props.mandatory && ( !values || values.length === 0 ));
    }

    getStorable(values) {
      let raw = [ ];

      // YN question uses just 0 or 1 value
      if(this.props.type === 'YN') {
        if(values.length > 0) {
          raw = [ values[0] === 0 ? 1 : 0 ]
        }
      } else {
        raw = values.map((value) => {
          return value
        });
      }
      return {
        value: values,
        raw: this.allowLooping ? [raw] : raw
      };
    }

    keyboardInput (evt) {
      let key = evt.key.toLowerCase();
      let index = -1;

      //if is yes or no, just S and N is used
      if(this.props.type === 'YN'){
        if(key === t("letter-Y").toLowerCase()) index = 0;
        if(key === t("letter-N").toLowerCase()) index = 1;
      }
      // else, if exists an item at index position, select it
      else {
        // a has key code 65, z is 90
        index = evt.keyCode - 65;
      }

      if (this.state.list[index]) {
        this.onBlur();
        this.toggleItem(this.state.list[index].index);
      }
    }

    onChangeInputValue(evt) {
      this.toggleItem(evt.target.value);
    }

    onSubmitNewValue(autoConfirm) {
      if(autoConfirm === true) {
        window.dispatchEvent(new CustomEvent('request-submit', {detail: {nextHook: 'request-nav-next', reason: 'confirm'}}))
      }
      return Promise.resolve();
    }

    toggleItem (item) {
      let value = [];
      let index = -1;

      item = parseInt(item);
      if( this.props.allowMultipleItems ){
          // checkboxes
          index = this.state.values.indexOf(item);
          if( index === -1 ){
              value = [...this.state.values, item];
          }else{
              value = [...this.state.values];
              value.splice(index, 1);
          };
      }else{
          if(this.state.values[0] === item) {
            value = [];
          } else {
            value = [item];
          }
      }

        this.hasError( value, (error)=>{
            this.setState({ values: value, errorMsg: error, status: !error });
            this.props.onChange( this.props.id, this.getStorable(value), !error, this.state.reference );

            this.triggerAPIEvent('alert', value, !error ).finally(() => {
              if(!this.props.allowMultipleItems && !error) {
                this.onSubmitNewValue(true)
              }
            });
        });
    }

    onBlur(){
      this.setState({
        touched: true,
        focus: undefined
      });

      if(this.props.onChange && this.hasParent()) {
        this.props.onChange(
          this.props.id,
          this.getStorable(this.state.values, this.state.rawValues),
          this.isValid( this.state.values ),
          this.state.reference
        )
      }
    }

    onSetFocus(id){
      this.setState({ focus: id });
    }

    render(){
        var uniqueName = Math.floor( Math.random() * 1000 );
        var inputType = this.props.allowMultipleItems ? 'checkbox' : 'checkbox';
        var isLoading = this.state.isLoading;
        var optionLetters = this.props.type === "YN" ? [ t("letter-Y"), t("letter-N") ] : [];
        return  <Question onFocus={this.onFocus} onKeyDown={this._keyDown} className={ ( this.props.active ? "active" : "" ) } color={this.props.color} id={this.props.navId}>
                    { this.renderHeader() }
                        { this.renderWithTutorial(<Options className={ "itens-" + this.state.list.length }>
                          { this.state.list.map((option, i)=>{
                              const multiple = this.props.allowMultipleItems;
                              const name =  "question-" + this.props.id + "-" + ( multiple ? "-" + i : "" ) + "-" + uniqueName;
                              const selected = this.state.values.indexOf(option.index) > -1;
                              // const focus = this.state.focus === i;
                              const OptionClass = [
                                ( selected ? "selected" : "" ),
                                ( multiple ? "multiple" : "single" ),
                                ( this.props.type === "YN" ? "yes-no" : "" ),
                                //( focus ? "focus" : "" )
                              ].filter((c)=>c).join(" ");
                              return <Option htmlFor={name + i}
                                              ref={ i === 0 ? "input" : undefined }
                                              key={i}
                                              className={ OptionClass }
                                              data-letter={ optionLetters[ i ] }
                                              isLoading={isLoading} color={this.props.color}>
                                          <input id={name + i}
                                                tabIndex="0"
                                                color={this.props.color}
                                                name={name}
                                                type={inputType}
                                                value={option.index}
                                                checked={selected}
                                                onFocus={()=>{ this.onSetFocus(i) }}
                                                onBlur={(evt)=>{ this.onBlur() }}
                                                onChange={(evt)=>{this.onChangeInputValue(evt);}} />
                                          { option.label }
                                      </Option>
                          })
                        }
                      </Options>)}
                    { this.renderAlert() }
                </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionMultiChoices);
