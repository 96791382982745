import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  svg {
    transform-origin: center;

    display: inline-block;

    width: 22px;
    height: 22px;
    padding: 4px;
    border-radius: 50%;
  }
`;

export default class IconList extends Component {
  render() {
    return (
      <Wrapper>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 512 512"
        >
          <path
            fill={this.props.color.secondary}
            d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"
          />
        </svg>
      </Wrapper>
    );
  }
}
