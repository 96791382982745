import './utils/lucky-orange.js';

import 'core-js';
import 'react-app-polyfill/ie9';
import './utils/custom-event.polyfill.js';
import 'prevent-pull-refresh';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createBrowserHistory } from 'history';

import store from './redux/store';
import { Provider } from 'react-redux';

// Import langs and init translate service
import ptbrTerms from './translate/ui-terms.ptbr.json';
import enusTerms from './translate/ui-terms.enus.json';

import TranslateService from './services/translateService';
TranslateService.getInstance().init({
  'pt-br': ptbrTerms,
  'en-us': enusTerms
}, window.navigator.userLanguage || window.navigator.language);

const history = createBrowserHistory();
const storeHistory = store(history);

ReactDOM.render(
    <Provider store={storeHistory}>
        <App history={history} />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

document.getElementById('root').classList.remove('basic-loader');
