import { createGlobalStyle } from 'styled-components'
import globalStyle from '../../globalStyle';

export const GlobalTooltipStyle = createGlobalStyle`
  .tippy-popper .tippy-tooltip {
    background-color: #fff !important;
    color: ${ props => props.color && props.color.material ? props.color.text : props.color.main ? props.color.main : globalStyle.default } !important;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.1);
    font-size: 14px !important;
    line-height: 1.71 !important;
    padding: 5px 15px !important;
  }

  .tippy-popper[x-placement^=bottom] [x-arrow] {
    border-bottom: 7px solid #fff !important;
  }

  .tippy-popper[x-placement^=top] [x-arrow] {
    border-top: 7px solid #fff !important;
  }

  .tippy-popper[x-placement^=top-start] [x-arrow] {
    left: 15px !important;
  }

  .tippy-popper[x-placement^=left] [x-arrow] {
    border-left: 7px solid #fff !important;
  }

  .tippy-popper[x-placement^=right] [x-arrow] {
    border-right: 7px solid #fff !important;
  }
`
