import React from 'react';
import KeyboardControlled from '../../keyboardControlled';
import {connect} from 'react-redux';
import { SubSection, SubSectionHeader, DeleteButton, BottomButtonLine } from './style';
import { Question, Title, Description, Feedback } from '../style';
import { Button } from '../../globalStyle';

import { AppConfirmDialog } from '../../appDialog';

import QuestionDynamic from '../questionDynamic';
import { t } from '..';

function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}


const mapDispatchToProps = dispatch => {
    return {
        onChangeValue( variableId, val , status, reference, noTouch ){
            return dispatch({
                type: "SET_ANSWER",
                payload: {
                    variableId,
                    answer: val,
                    reference,
                    status,
                    noTouch
                }
            });
        },
        dropIndex( variableId, index ){
            return dispatch({
                type: "DROP_COMPLEX_INDEX",
                payload: {
                    variableId,
                    index
                }
            });
        },
        requestVarChange(variableData) {
            return dispatch({
                type: "NAV_GOTO_PUSH_URL",
                payload: {...variableData}
            });
        }
    }
}


class QuestionComplexa extends KeyboardControlled {

    constructor(props){
        super(props);

        let answers = this.props.interview.answers[ this.props.id ];
        let collapsed = [ ];
        let propValue = undefined;

        if (answers && answers[0]) {
          propValue = answers[0].value;
        } else {
          // If allowLooping === false, set value to be the label of this variable
          // this is for maintain the patter of complex variables values has only
          // their labels.
          propValue = this.props.allowLooping ? [] : [this.props.label];
        }

        if(this.props.allowLooping) {
          collapsed = Array(propValue.length).fill(false);
        }

        let isValid = this.isValid(propValue);

        this.state = {
            status: isValid,
            values: propValue || [],
            newLabel: "",
            errorMsg: "",
            // confirm dialog
            showConfirm: false,
            // collapse state array
            collapsed,
        }

        if(props.onUpdateStatus) props.onUpdateStatus(isValid)

        this.containers = [];
        this.updateDelayer = {};
        this.questionsContainer = React.createRef();

        this.registerKey('Enter', (e) => {
          //if event is from complex label input, add new label
          if(this.props.allowLooping && this.refs.input === e.target) {
            this.onSubmitNewLabel();
            e.preventDefault();
            e.stopPropagation();
          } else {

          }
        }, false, false);

        this.registerKey('Tab', (e) => {
          // you can go back
          if (e.shiftKey && e.target !== this.refs.plusBtn && e.target.nodeName !== 'BUTTON') {
            e.preventDefault();
            e.stopPropagation();
          }
          if(!e.shiftKey && e.target === this.refs.plusBtn){
            //e.preventDefault();
            //e.stopPropagation();
          }
        }, false, false);

        this.letUserSeeMe = this.letUserSeeMe.bind(this);
        this.toggleCollpse = this.toggleCollpse.bind(this);
        this.onDeleteLabel = this.onDeleteLabel.bind(this);
        this.onChildChange = this.onChildChange.bind(this);
        this.onChangeNewLabel = this.onChangeNewLabel.bind(this);
        this.onSubmitNewLabel = this.onSubmitNewLabel.bind(this);
    }

    componentDidMount(){
        // this.focus({preventScroll: true});

        if(this.props.childComponentDidMount) this.props.childComponentDidMount(this);

        //if has no values, add one item
        if(this.state.values.length === 0) {
          this.onSubmitNewLabel({detail: {preventNavigation: true}});
        }
    }

    componentWillUnmount(){
        const delayers = Object.keys(this.updateDelayer);
        delayers.forEach((key)=>{ clearTimeout(this.updateDelayer[key]) })
    }

    letUserSeeMe (scrollRequest) {
      if(this.isCurrentVariable()) {
        window.dispatchEvent(new CustomEvent('request-scroll', { detail: {
          ...scrollRequest,
          container: document.getElementById(this.props.navId)
        }}))
      }
    }

    isCurrentVariable () {
      return this.props.nav.currentVariable.startsWith(this.props.navId);
    }

    isValid ( forceValues ) {
        let status = true;
        let values = forceValues || ( this.state ? this.state.values : false );
        let iterations = values.length > 0 ? values.length : 1;

        // check mandatory empty multi
        if( this.props.mandatory && this.props.allowLooping && ( !values || !values.length ) ) return false;

        for (var i = 0; i < iterations && status; i++) {
          if (this.props.navTree[i]) {
            for (const navNode of this.props.navTree[i]) {
              if ( !status ) return false;
              const variable = this.props.interview.variables[ navNode.id ];
              const answer = this.props.interview.answers[ navNode.id ]
              if (answer && answer[i]) {
                // if answered, answer status interfere in complex status
                status = status && ( ( !variable.mandatory && answer[i].status === undefined ) || answer[i].status );
              } else {
                //if not answered, only mandatory flag intefere in complex status
                status = status && !variable.mandatory;
              }
            }
          } else {
            status = status && !this.props.mandatory;
          }
        }

        return status;
    }

    isCollapsed(index, variablesData) {
      let hasActiveChild = this.hasActiveChild(index, variablesData)

      // if has active child, cannot stay collapsed
      if(hasActiveChild && this.state.collapsed[index]) {
        let collapsed = [...this.state.collapsed]
        collapsed[index] = false
        setTimeout(() => {
          this.setState({collapsed})
        }, 1)
      }

      return this.state.collapsed[index] && !hasActiveChild
    }

    getIterationsCount() {
      return this.state.values.length > 0 ? this.state.values.length : 1
    }

    onRequestToSubmitValue() {
        let lastVar = this.props.navTree[this.getIterationsCount() - 1].slice(-1)[0]

        return this.containers[this.props.nav.currentVariable].onRequestToSubmitValue().then(() => {
          return lastVar.navId === this.props.nav.currentVariable;
        })
    }

    onChildComponentDidMount(reference, navId) {
      this.containers[navId] = reference
    }

    onChildChange(id, value, status, reference){
        const key = `${id}-${reference.index}`;
        const change = { id, value, status, reference };

        if(this.updateDelayer[key]) clearTimeout(this.updateDelayer[key]);

        this.updateDelayer[key] = setTimeout(() => {
          //save child state
          this.props.onChangeValue( change.id, change.value, change.status, change.reference );

          // process actual complex variable state
          setTimeout(()=>{
              const valid = this.isValid();
              this.setState({status: valid});
              this.props.onChange( this.props.id, this.state.values, valid, { index: 0 } );
          }, 10 );
        }, 300);
    }

    onChangeNewLabel(evt){
        this.setState({newLabel: evt.target.value, errorMsg: false});
    }

    onSubmitNewLabel(evt){
        let labels = this.state.values;
        labels.push(Math.floor( (Math.random() * 100000) + 1 )); //+1 prevents 0
        const valid = this.isValid(labels);

        let collapsed = [];

        this.setState({
            collapsed,
            status: valid,
            newLabel: "",
            values: labels,
            errorMsg: "",
        });

        this.props.onChangeValue( this.props.id, labels, valid, { index: 0 }, true );
        this.props.onChange( this.props.id, this.state.values, valid, { index: 0 } );

        if(evt.detail && !evt.detail.preventNavigation) {
          setTimeout(() => {
            this.props.requestVarChange(this.props.navTree[this.props.navTree.length - 1][0])
          }, 1)
        }

        this.onSizeChange();
    }

    onSizeChange() {
      if(this.props.onSizeChange) {
        setTimeout(() => {
          this.props.onSizeChange(true);
        }, 100)
      }
    }

    hasNewError(){
        if( !this.state.newLabel ) return t("This field cannot be empty");
        if( this.state.values.indexOf( this.state.newLabel ) > -1 ) return t("The label name must be unique");
        return false;
    }

    currentVariableLoopIndex() {
      if(this.isCurrentVariable()) {
        for (var i = 0; i < this.props.navTree.length; i++) {
          if(this.props.navTree[i].findIndex(item => item.navId === this.props.nav.currentVariable) !== -1) {
            return i
          }
        }
      }

      return -1
    }

    onDeleteLabel(evt){
        if( evt.confirmed ){
            let labels = [...this.state.values];
            let currentVariableLoopIndex = this.currentVariableLoopIndex();
            let index = labels.findIndex(label => label === this.state.showConfirm);

            labels.splice(index, 1);
            const valid = this.isValid(labels);
            
            //Navigate to other loop index if needed
            if(currentVariableLoopIndex >= index) {
              this.props.requestVarChange(this.props.navTree[index === 0 ? 0 : index - 1][0]);
            }
            
            //Remove label from state
            this.props.onChangeValue( this.props.id, labels, valid, index, true );
            
            //Drop complex index (aka propagates the remove label event)
            this.props.dropIndex(this.props.id, index);
            
            this.setState({
              collapsed: [],
              status: valid,
              values: labels,
              showConfirm: false
            });
            
            this.props.onChange( this.props.id, this.state.values, valid, index );
            
            this.onSizeChange();
        }else if( evt.confirmed === false ){
            this.setState({
                showConfirm: false,
                collapsed: []
              })
            }else{
              this.setState({
                showConfirm: parseInt(evt.target.value),
                collapsed: []
            })
        }
        evt.confirmed = undefined;
    }

    hasActiveChild(index, variablesData) {
      return variablesData[index].find(item => item.navId === this.props.nav.currentVariable) !== undefined
    }

    getVariables(){
        let iterations = this.getIterationsCount();
        let result = { };
        for (var i = 0; i < iterations; i++) {
          result[i] = [ ];
          if (this.props.navTree[i]) {
            for (const navNode of this.props.navTree[i]) {
              let variable = {...navNode, ...this.props.interview.variables[ navNode.id ]}
              result[i].push( variable );
            }
          }
        }

        return result;
    }

    toggleCollpse(i) {
      if(this.props.allowLooping) {
        let collapsed = [...this.state.collapsed];
        collapsed[i] = !collapsed[i];
        this.setState({ collapsed });
        this.onSizeChange(true);
      }
    }

    renderAlert(){
        if( this.state.errorMsg ) return <Feedback>{(this.state.errorMsg || "")}</Feedback>
        return false;
    }

    render(){
        let variablesData = this.getVariables();
        const currentVariableId = this.props.nav.currentVariable;
        const labels = this.state.values;
        const collapsed = this.state.collapsed;
        return <Question id={this.props.navId} ref={this.questionsContainer} tabIndex="0" onKeyDown={this._keyDown} color={this.props.color} className={ this.props.id === currentVariableId || this.props.active ? 'active' : '' }>
                    <header>
                        { this.props.question && <Title color={this.props.color}><span dangerouslySetInnerHTML={{__html: this.props.question + (  this.props.mandatory ? "*" : ""  ) }} /></Title> }
                        { this.props.description && <Description color={this.props.color} dangerouslySetInnerHTML={{__html: this.props.description }}></Description> }
                        <AppConfirmDialog title={"Delete this group and all the values?"} showConfirm={this.state.showConfirm} onConfirm={this.onDeleteLabel} onCancel={this.onDeleteLabel} color={this.props.color}/>
                    </header>
                    {labels && labels.map((label, i)=>{
                        return <SubSection key={label||Math.random()}  className={collapsed[i] ? 'collapsed' : ''} color={this.props.color}>
                                    <SubSectionHeader onClick={() => { if(!this.hasActiveChild(i, variablesData)) this.toggleCollpse(i) }}>
                                      { this.props.allowLooping &&
                                        <>
                                          { !this.isCollapsed(i, variablesData) && this.props.allowLooping && this.getIterationsCount() > 1 &&
                                            <DeleteButton onClick={this.onDeleteLabel} value={label} color={this.props.color}></DeleteButton>
                                          }
                                        </>
                                      }

                                    </SubSectionHeader>
                                    { !this.isCollapsed(i, variablesData) && variablesData[i] && variablesData[i].map((variable)=>{
                                        return <QuestionDynamic childComponentDidMount={this.onChildComponentDidMount.bind(this)}
                                                                index={i}
                                                                color={this.props.color}
                                                                onChange={this.onChildChange}
                                                                key={variable.id + '-' + i}
                                                                active={ variable.id === currentVariableId || this.props.active}
                                                                connectionStatus={this.props.connectionStatus}
                                                                focused={this.props.focused}
                                                                {...variable}
                                                                letUserSeeMe={this.letUserSeeMe}
                                                                answers={this.props.interview.answers[ variable.id ]}
                                                                navTree={variable.subVariables}
                                                                isComplexChild={true}/>
                                    })}
                                </SubSection>
                    })}
                    <BottomButtonLine centralize={this.props.allowLooping}>
                      { this.props.allowLooping &&
                        <Button className="invert" ref="plusBtn" color={this.props.color} onClick={this.onSubmitNewLabel} >{t("Add new ?0", [ this.props.label ])}</Button>
                      }
                      {this.props.children}
                    </BottomButtonLine>
                    { this.renderAlert() }
                </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionComplexa);
