import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export default class IconList extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        { !this.props.active && <path transform="translate(3,3)" fill={this.props.color.secondary} d="M5,2H19A1,1 0 0,1 20,3V13A1,1 0 0,1 19,14H5A1,1 0 0,1 4,13V3A1,1 0 0,1 5,2M6,4V12H18V4H6M20,17A1,1 0 0,1 19,18H5A1,1 0 0,1 4,17V16H20V17M20,21A1,1 0 0,1 19,22H5A1,1 0 0,1 4,21V20H20V21Z" />}
                        { this.props.active && <path transform="translate(0,0)" fill={this.props.color.secondary} d="M24 5a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h18zm0 16H6a1 1 0 0 1-1-1v-1h20v1a1 1 0 0 1-1 1zm1 3a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-1h20v1z" />}
                    </svg>
                </Wrapper>;
    }
}