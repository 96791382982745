import React from 'react';
import QuestionBasic, { translateDictionary, Actions, t } from '../../questions';
import {connect} from 'react-redux';
import moment from 'moment';

import { Question, InputLine } from '../style';
import DateInput, { DateService } from '../../uiElements/DateInput';
import LoopButton from '../../uiElements/LoopButton';

function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
        ...Actions(dispatch)
    }
}

class QuestionDate extends QuestionBasic{
    constructor(props){
        super(props);

        // keyboard events
        let keys = ['ArrowLeft', 'ArrowRight'];
        this.registerKey(keys, null, false, true);

        this.registerKey('Enter', (e) => {
          if(this.props.allowLooping) {
            // if valid
            if( this.state.status ) {
              // if editing, submit editing value
              if (this.state.isEditing) {
                e.stopPropagation();
                this.onSubmitNewValue();
              }
              // if not editing and has any value, submit and go next
              else if (this.state.values[this.state.newIndex]) {
                e.stopPropagation();
                this.onSubmitNewValue(true);
              }
            }
            else {
              // if invalid, just stop propagation
              e.stopPropagation();
            }
          } else {
            this.onBlur();
          }
        }, false, false);

        // Cancel editing value
        this.registerKey('Escape', (e) => {
          if(this.props.allowLooping && this.state.isEditing) {
            e.stopPropagation();
            this.onDeselectValueListItem();
          }
        }, false, false);
        
        // Add new value
        this.registerKey(['Shift', '+'], (e) => {
          if (!!e.shiftKey) {
            switch (e.keyCode) {
              case 16: // ignore shift key
              break;
              default:
                
                if (this.refs.input.isFocused() 
                  && this.state.status 
                  && this.state.values.length) {
                    e.preventDefault();
                    this.onSubmitNewValue();
                }
              
                break;
            }
          }
        }, false, false);

        this.registerKey('Tab', (e) => {
          // if input is focused and user is trying to navigate to a previous
          // item...
          if(e.shiftKey && !this.hasParent() && this.refs.input.isFocused()){
            if((this.props.allowLooping && this.state.values.length === 0) ||
                !this.props.allowLooping) {
              e.preventDefault();
              e.stopPropagation();
            }
          } else if(!e.shiftKey) {
            e.stopPropagation();
          } else if(e.shiftKey && this.hasParent()) {
            e.stopPropagation();
          }
          this.renderCurrentError();
        }, false, false);

        this.registerKey('Backspace', (e) => {
          if( this.props.mask && e.target === this.refs.input.getInput() && this.isCarretAtEnd(this.refs.input.getInput())) {
            this.removeLastEditableChar();
            e.preventDefault();
          }
        }, false, false);
    }

    isValid(values, ignoreLimits){
        if( this.props.mandatory && ( !values || !values.length || values[0] === "" )) return false;

        // check last input
        let keyValue = Array.isArray(values) ? values[this.state.newIndex] : values;
        // dont check if null and its allowed
        if( keyValue === "" || keyValue === undefined ) return true;

        return DateService.isValid(keyValue, !ignoreLimits && this.props.limInf, !ignoreLimits && this.props.limSup);
    }

    generateRawValues(values) {
      return values.map(item => moment(item).utc().startOf('day').valueOf());
    }

    hasError(values, callback){
        // check mandatory
        if( this.props.mandatory ){
          let emptyValue = values.findIndex(value => this.isEmpty(value))

          if( !values.length || // no itens
              (values.length === 1 && emptyValue !== -1) || // input is empty and no other itens
              (emptyValue !== (values.length - 1) && emptyValue !== -1) ) { // an item !== of input is empty
            return callback(t("This is a obligatory field"), false);
          }
        }

        // check last input
        let keyValue = values[this.state.newIndex];
        // dont check if null and its allowed
        if( keyValue === "" || keyValue === undefined ) return callback(false, false);

        // is valid Number
        if( !this.isValid( values, true ) ) return callback(t("Invalid value"), false);

        // is in range limit
        keyValue = this.parseInput(keyValue);
        if( this.props.limSup !== undefined && this.props.limSup !== null && this.props.limSup !== "" && DateService.greaterThan( keyValue, this.props.limSup ) ) return callback(t("The value must be below or equal ?0", DateService.toFormatedDate( this.props.limSup )), false);
        if( this.props.limInf !== undefined && this.props.limInf !== null && this.props.limInf !== "" && DateService.lowerThan( keyValue, this.props.limInf ) ) return callback(t("The value must be above or equal ?0", DateService.toFormatedDate( this.props.limInf)), false);

        this.triggerAPIEvent('validation', values ).then((response)=>{
          callback(response, false);
        }).catch((error)=>{
          callback( false, false );
        });

        return callback(true, true);
    }

    renderInput(isMultiple, isEdit){
        const value = this.state.values[this.state.newIndex];
        const isValid = this.state.touched && this.state.status;
        const hasAnswer = this.state.touched && value;

        return this.renderWithTutorial(
            <InputLine className={ isEdit ? "bordered" : "" } onFocus={this.onFocus} onKeyDown={this._keyDown}>
                <DateInput
                    onKeyDown={this._keyDown}
                    isInvalid={!isValid}
                    hasAnswer={hasAnswer}
                    ref="input"
                    lang={translateDictionary.lang}
                    placeholder=""
                    placeholderMask={this.state.masked}
                    color={this.props.color}
                    min={this.props.limInf}
                    max={this.props.limSup}
                    value={value}
                    onChange={this.onChangeInputValue}
                    onBlur={this.onBlur}
                    />
                  { this.renderButtons(isMultiple, isEdit, !this.isEmpty(value)) }
            </InputLine>
          )
    }

    render(){
        const isMultiple = this.props.allowLooping;
        const values = this.state.values.slice(0, this.state.values.length - 1);
        const isEdit = this.state.isEditing;

        return <Question className={ this.props.active ? "active" : "" } color={this.props.color} id={this.props.navId}>
            { this.renderHeader() }
            {isMultiple &&
            <div>
                { values && values.map((value, i)=>{
                        if( !isEdit || this.state.newIndex !== i ) return <LoopButton editable={true} color={this.props.color} selected={ this.state.newIndex === i } onEdit={this.onSelectValueListItem} onRemove={() => {this.onDeleteValue(i)}} key={i} value={value} index={i}>{DateService.toFormatedDate( value )}</LoopButton>
                        if(  isEdit && this.state.newIndex === i ) return <div key={i} >{ this.renderInput(isMultiple, isEdit) }</div>
                        return false
                    })
                }
                { !isEdit && this.renderInput(isMultiple, isEdit) }
                { this.renderAlert() }
            </div>}
            {!isMultiple &&
            <div>
                { this.renderInput(isMultiple, isEdit) }
                { this.renderAlert() }
            </div>}
        </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDate);
