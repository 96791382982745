import React from 'react';
import Tutorial from '../tutorial';
import {
  Title,
  Description,
  Feedback,
  PlusButton,
  EditConfirmButton,
  EditCancelButton
} from './style';
import Tooltip from '../uiElements/tooltip';

import isMobile from '../../services/mobileDetect';
import EventsService from '../../services/eventsService';
import translateService from '../../services/translateService';

export const translateDictionary = new translateService();
export const t = translateDictionary.getTranslateFunction();

export const Actions = dispatch => {
  // event actions
  return {
    showAlert(text, onClose) {
      return dispatch({
        type: 'OPEN_DIALOG',
        payload: { title: 'Alert', text, type: 'alert', onClose }
      });
    },
    updateData(variableId, value) {
      return dispatch({
        type: 'SET_ANSWER',
        payload: {
          variableId,
          answer: value,
          reference: { index: 0 },
          status: true,
          noTouch: true
        }
      });
    },
    updateList(variableId, list) {
      return dispatch({
        type: 'SET_LIST',
        payload: {
          variableId,
          list
        }
      });
    },
    requestVarChange(variableData) {
      return dispatch({
        type: 'NAV_GOTO_PUSH_URL',
        payload: { ...variableData }
      });
    }
  };
};

export const maskDictionary = {
  A: /[A-Z\u00C0-\u00DD\u02b0-\u036F\u0060\u00B4\u0027\u007E]/,
  a: /[a-z\u00E0-\u00FF\u02b0-\u036F\u0060\u00B4\u0027\u007E]/,
  '9': /[0-9]/,
  '?': /.?/,
  '*': /./
};

class QuestionBasic extends Tutorial {
  constructor(props, keys, text) {
    super(props, keys, undefined, undefined, undefined, text);

    if (this.props.mask) {
      let placeholderMask = this.props.mask.replace(/\?/g, '');
      let maskExp = this.props.mask;
      Object.keys(maskDictionary).map(letter => {
        const replacer = new RegExp('\\' + letter, 'g');
        maskExp = maskExp.replace(replacer, maskDictionary[letter].source);
        return (placeholderMask = placeholderMask.replace(replacer, '_'));
      });

      let maskableArray = this.props.mask.split('').map(letter => {
        if (maskDictionary[letter]) {
          return maskDictionary[letter];
        } else {
          return new RegExp((letter !== ' ' ? '\\' + letter : '\\s') + '?');
        }
      });

      const maskable = string => {
        if (string === undefined) console.trace(string, maskableArray);
        for (
          let letter = 0, rule = 0;
          letter < string.length && rule < maskableArray.length;
          letter
        ) {
          const regRule = maskableArray[rule];
          const letterString = string[letter];
          const isMandatory = !regRule.source.endsWith('?');
          const mandatoryRule = new RegExp(regRule.source.replace(/\?$/, ''));

          if (mandatoryRule.test(letterString)) {
            letter += 1;
            rule += 1;
          } else {
            if (isMandatory) {
              return false;
            } else {
              rule += 1;
            }
          }
        }

        return true;
      };

      this.maskable = maskable;
      this.placeholderMask = placeholderMask;
      this.state.masked = placeholderMask;
    }

    let index = this.props.index ? this.props.index : 0;
    let answers = this.props.answers;

    // Set default values
    let propRaw = [];
    let propValue = [];
    let propTouched = false;
    let propNewIndex = 0;

    // If question at actual index has answers, update values
    if (answers && answers[index]) {
      propRaw = answers[index].value.raw;
      propValue = answers[index].value.value;
      propTouched = propValue !== undefined;
      propNewIndex = propTouched ? Math.max(propValue.length - 1, 0) : 0;
    } else if (this.props.data && this.props.data.value.value.length > 0) {
      for (var i = 0; i < this.props.data.value.value.length; i++) {
        if (props.mask) {
          if (this.maskable(this.props.data.value.value[i])) {
            if (typeof this.props.data.value.raw[i] === 'string')
              propRaw.push(this.parseInput(this.props.data.value.raw[i]));
            else propRaw.push(this.props.data.value.raw[i]);
            propValue.push(this.parseInput(this.props.data.value.value[i]));
          }
        } else {
          propRaw.push(this.props.data.value.raw[i]);
          propValue.push(this.parseInput(this.props.data.value.value[i]));
        }
      }

      if (
        (props.allowLooping || propValue.length === 0) &&
        propValue[Math.max(propValue.length - 1, 0)] !== ''
      )
        propValue.push('');

      propTouched = propValue !== undefined;
      propNewIndex = propTouched ? Math.max(propValue.length - 1, 0) : 0;

      setTimeout(() => {
        if (props.onChange)
          props.onChange(
            this.props.id,
            {
              value: [...propValue],
              raw: [...propRaw]
            },
            this.isValid(propValue),
            { index }
          );
        this.renderCurrentError();
        if (!props.allowLooping) {
          this.maskerate(propValue[Math.max(propValue.length - 1, 0)]);
        }
      });
    }

    let propStatus = this.isValid(propValue);

    this.state = {
      // main status
      status: propStatus,
      values: propValue ? propValue : [],
      rawValues: propRaw ? propRaw : [],
      touched: propTouched,
      errorMsg: '',
      maskErrorMsg: '',
      showConfirm: false,
      // multi values input status
      newIndex: propNewIndex,
      isEditing: false,
      oldValue: '',
      // debbuger flag
      // showJSON: false
      reference: { index }
    };

    this.createEventsListeners();

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onDeleteValue = this.onDeleteValue.bind(this);
    this.onSubmitNewValue = this.onSubmitNewValue.bind(this);
    this.renderCurrentError = this.renderCurrentError.bind(this);
    this.onChangeInputValue = this.onChangeInputValue.bind(this);
    this.onSelectValueListItem = this.onSelectValueListItem.bind(this);
    this.onDeselectValueListItem = this.onDeselectValueListItem.bind(this);
  }

  createEventsListeners() {
    try {
      const fullUrl = this.props.app
        ? this.props.app.apiRouters.events.url +
          this.props.app.apiRouters.events.route
        : '';
      this.eventsService = new EventsService(
        {
          url: fullUrl,
          services: this.props.interview.services
        },
        {
          // default callbacks
          alert: msg => {
            if (msg)
              this.props.showAlert(msg, () => {
                //Focus on wrapper
                if (this.props.onRejectFocus) this.props.onRejectFocus();
              });
          },
          data: data => {
            Object.keys(data).forEach(key => {
              if (data[key]) {
                this.props.updateData(key, {
                  raw: [data[key]],
                  value: [data[key]]
                });
                // must use events to update same screen questions faster
                // try the same using Redux is VERY SLOW
                window.dispatchEvent(
                  new CustomEvent('data-loaded-' + key, { detail: data[key] })
                );
              }
            });
          },
          autocomplete: data => {
            data = Array.isArray(data) ? { [this.id]: data } : data;
            Object.keys(data).forEach(key => {
              const list = data[key];
              this.props.updateList(key, list);
            });
          },
          validation: data => {
            console.warn(data);
            // validation dont have a default callback. This must use the Promise response inside `isValid` method
          }
        }
      );
      if (this.props.events && this.props.events.length)
        this.eventsService.addEvents(this.props.events);
    } catch (e) {
      // this interview dont have events
    }
  }

  triggerAPIEvent(evtName, values, status) {
    if (this.eventsService) {
      // return All events of this type Promises
      if (!this.eventsService.hasEvent(evtName)) {
        return Promise.reject().catch(error => {});
      }

      this.setState({ isLoading: true });
      return this.eventsService
        .check(evtName, this.props.id, values, this.props.interview.answers)
        .then(result => {
          this.setState({ isLoading: false });
          return result;
        })
        .catch(error => {
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
    // fast response if dont have events
    return Promise.resolve();
  }

  // Lifecycle methods
  componentDidMount() {
    window.addEventListener(
      'data-loaded-' + this.props.id,
      this.updateValues.bind(this)
    );

    if (this.props.onStart) {
      this.props.onStart({
        value: this.getStorable(this.state.values, this.state.rawValues),
        status: this.isValid(this.state.values),
        reference: this.state.reference
      });
    }

    // Process autofocus
    if (!isMobile && this.props.nav.currentVariable === this.props.navId) {
      this.delayedFocus = setTimeout(() => {
        this.focus({ preventScroll: true });
      }, 1);
    }

    // Process tutorial
    if (
      !isMobile &&
      this.props.focused &&
      this.props.nav.currentVariable === this.props.navId
    ) {
      this.showTutorial();
    }


    this.triggerAPIEvent('autocomplete', this.state.values, this.state.status);

    if (this.props.childComponentDidMount)
      this.props.childComponentDidMount(this, this.props.navId);

    if (this.isCurrentVariable()) {
      setTimeout(() => {
        this.letUserSeeMe();
      }, 200);
    }
    
  }

  componentWillUnmount() {
    window.removeEventListener(
      'data-loaded-' + this.props.id,
      this.updateValues.bind(this)
    );
    //
    if (this.eventsService) {
      this.eventsService.cancel();
    }
    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps) {
    if (!isMobile) {
      if (
        this.props.focused &&
        this.props.nav.currentVariable === this.props.navId
      ) {
        this.showTutorial();
      } else {
        this.hideTutorial();
      }
    }
    
    if (
      prevProps.nav.currentVariable !== this.props.nav.currentVariable &&
      this.props.nav.currentVariable === this.props.navId
      ) {
        this.showTutorial();
      this.focus({ preventScroll: true });
      this.letUserSeeMe();
    }
  }

  letUserSeeMe(justUpdate) {
    let scrollRequest = {
      justUpdate,
      target: document.getElementById(this.props.navId)
    };

    // if has parent, question focus is equals to complex container size and not
    // local container.
    if (this.hasParent() && this.props.letUserSeeMe) {
      this.props.letUserSeeMe(scrollRequest);
    } else {
      window.dispatchEvent(
        new CustomEvent('request-scroll', { detail: scrollRequest })
      );
    }
  }

  focus() {
    if (!isMobile && this.isCurrentVariable()) {
      if (this.refs.input && this.refs.input.focus) {
        this.refs.input.focus({ preventScroll: true });
      } else if (this.props.onRejectFocus) {
        this.props.onRejectFocus();
      }
    }
  }

  updateValues(event) {
    let values = this.state.values;
    let rawValues = this.state.values;
    rawValues[this.props.index || 0] = event.detail;
    values[this.props.index || 0] = this.formatedValue(event.detail);

    this.setState({
      values,
      rawValues
    });
  }

  maskString(value, mask, maskPatterns) {
    // comment this line if you want clean input string by mask
    // if( !this.maskable(value) ) return value

    //cut extra input values and cache
    let suffix = '';
    value = value || '';

    // mask error to be returned
    let error = '';

    if (value.length > mask.length) {
      suffix = value.slice(mask.length);
      value = this.props.prohibitiveMask
        ? value || ''
        : value.slice(0, mask.length);
    }

    mask = mask || '';
    maskPatterns = maskPatterns || {};

    var maskedValue = '';
    var valueParts = value.split('');
    var maskParts = mask.split('');

    while (valueParts.length > 0) {
      var unmaskedChar = valueParts.shift();
      let firstMaskChar = true;

      while (unmaskedChar !== null) {
        var maskChar = maskParts.shift();

        if (maskChar !== undefined) {
          var maskPattern = maskPatterns[maskChar];
          if (maskChar === 'A') unmaskedChar = unmaskedChar.toUpperCase();
          else if (maskChar === 'a') unmaskedChar = unmaskedChar.toLowerCase();

          if (maskPattern !== undefined) {
            var check = false;

            if (typeof maskPattern === 'function') {
              check = maskPattern(unmaskedChar);
            } else if (maskPattern instanceof RegExp) {
              check = maskPattern.test(unmaskedChar);
            }

            if (check) {
              maskedValue += unmaskedChar;
            } else {
              maskParts.unshift(maskChar);

              if (firstMaskChar) {
                if (maskChar === 'A' || maskChar === 'a') {
                  error = t('This character must be a letter');
                } else if (maskChar === '9') {
                  error = t('This character must be a number');
                }
              }
            }

            unmaskedChar = null;
          } else {
            maskedValue += maskChar;
          }
        } else {
          unmaskedChar = null;
        }
        firstMaskChar = false;
      }
    }

    let restString = maskParts.join('');
    const maskKeys = Object.keys(maskPatterns);
    if (
      restString.length &&
      maskKeys.find(key => maskPatterns[key].exec(restString) !== null) ===
        undefined
    ) {
      maskedValue += restString;
    }

    // complete maskedValue with mandatory characters
    while (
      maskedValue.length < mask.length &&
      maskPatterns[mask[maskedValue.length]] === undefined
    ) {
      maskedValue += mask[maskedValue.length];
    }

    return {
      value: maskedValue + (this.props.prohibitiveMask ? '' : suffix),
      error
    };
  }

  removeLastEditableChar() {
    let values = [...this.state.values];
    let value = values[this.state.newIndex];
    if (!value) value = '';

    const mask = this.props.mask;

    let valueArray = value.split('');
    for (let i = valueArray.length - 1; i >= 0; i--) {
      const maskLetter = mask[i];
      if (!maskDictionary[maskLetter]) {
        valueArray.pop();
      } else {
        valueArray.pop();
        break;
      }
    }
    value = valueArray.join('');
    this.onChangeInputValue({ target: { value } });
  }

  // Computed paramaters and methods
  hasParent() {
    return (
      (this.props.complexParent !== undefined &&
        this.props.complexParent !== null) ||
      this.props.hasParent
    );
  }

  isCarretAtEnd(input) {
    return (
      input.selectionStart === input.value.length &&
      input.value.length <= this.placeholderMask.length
    );
  }

  isEmpty(value) {
    return value === '' || value === null || value === undefined;
  }

  isValid(values) {
    if (
      this.props.mandatory &&
      (!values || !values.length || this.isEmpty(values[0]))
    )
      return false;
    //values = Array.isArray(values) ? values[0] : values;
    return true;
  }

  isCurrentVariable() {
    return this.props.navId === this.props.nav.currentVariable;
  }

  hasError(value, callback) {
    if (this.props.mandatory && (!value || value.length === 0))
      return callback(t('This is a obligatory field'), false);
    if (this.inputType() !== 'checkbox' && !this.isValid(value))
      return callback(t('Invalid value'), false);

    // temp block question
    this.triggerAPIEvent('validation', value)
      .then(response => {
        callback(response, false);
      })
      .catch(error => {
        callback(false, false);
      });

    return callback(true, true);
  }

  inputType() {
    switch (this.props.type) {
      case 'MUL':
        return 'text';
      case 'YN':
        return 'checkbox';
      case 'NUM':
      case 'CASH':
      case 'PERCENT':
      case 'DAYS':
        return 'number';
      case 'DATE':
        return 'date';
      case 'FILE':
        return 'file';
      default:
        return 'text';
    }
  }

  parseInput(value) {
    if (value !== '') {
      switch (this.inputType()) {
        case 'number':
          return parseFloat(value);
        default:
          if (
            value === undefined &&
            value === null &&
            typeof value === 'string'
          ) {
            return value;
          } else if (
            typeof value === 'string' &&
            value !== '' &&
            this.props.mask
          ) {
            const { value: maskedValue, error } = this.maskString(
              value,
              this.props.mask,
              maskDictionary
            );
            this.setState({
              maskErrorMsg: error
            });
            return maskedValue;
          } else {
            return value;
          }
      }
    } else {
      return value;
    }
  }

  formatedValue(value) {
    return value;
  }

  generateRawValues(values) {
    return values.map(item => this.parseInput(item, true));
  }

  getStorable(values, raw) {
    return {
      value: [...values],
      raw:
        raw !== undefined && raw.length > 0
          ? [...raw]
          : this.generateRawValues(values)
    };
  }

  formatOutput(value) {
    return value;
  }

  maskerate(value) {
    if (this.props.mask) {
      const masked =
        this.maskable(value) && value.length < this.placeholderMask.length
          ? value + this.placeholderMask.slice(value.length)
          : '';

      this.setState({
        masked
      });
    }
  }

  onFocus(evt) {
    this.props.requestVarChange({
      var_id: this.props.navId,
      block_id: this.props.nav.currentBlock
    });
  }

  onBlur() {
    this.setState({ touched: true });
    this.triggerAPIEvent('alert', this.state.values, this.state.status);

    if (this.props.onChange && this.hasParent()) {
      this.props.onChange(
        this.props.id,
        this.getStorable(this.state.values, this.state.rawValues),
        this.isValid(this.state.values),
        this.state.reference
      );
    }
  }

  // Event listenrs
  onDeselectValueListItem(evt) {
    let values = [...this.state.values];
    let rawValues = [...this.state.rawValues];

    // Restore old values
    values[this.state.newIndex] = this.state.oldValue.value;
    rawValues[this.state.newIndex] = this.state.oldValue.raw;

    let toSubmitValue = this.getStorable(values, rawValues);

    if (this.state.isEditing) {
      this.hasError(values, error => {
        this.setState({
          values,
          rawValues,
          newIndex: Math.max(values.length - 1, 0),
          isEditing: false,
          errorMsg: error,
          status: !error,
          oldValue: ''
        });

        if (this.props.onChangeValue)
          this.props.onChangeValue(
            this.props.id,
            toSubmitValue,
            !error,
            this.state.reference
          );
        if (this.props.onChange)
          this.props.onChange(
            this.props.id,
            toSubmitValue,
            !error,
            this.state.reference
          );
      });
    }
  }

  onSelectValueListItem(evt) {
    if (
      evt.detail.index === undefined ||
      this.state.values[evt.detail.index] === undefined
    )
      return false;

    const index =
      this.state.values[evt.detail.index] === evt.detail.value
        ? evt.detail.index
        : -1;
    const newIndex = index > -1 ? index : this.state.values.length - 1;

    const oldValue = {
      value: this.state.values[newIndex],
      raw: this.state.rawValues[newIndex]
    };

    this.setState({
      newIndex,
      oldValue,
      isEditing: true,
      //if item was added, status is supposed to be true untill user
      //change the actual value
      status: true
    });

    if (this.props.onUpdateStatus) this.props.onUpdateStatus(this.state.status);

    this.delayedFocus = setTimeout(() => {
      this.focus({ preventScroll: true });
    }, 1);

    return newIndex;
  }

  onRequestToSubmitValue() {
    if (this.props.allowLooping) {
      return this.onSubmitNewValue();
    } else {
      return Promise.resolve();
    }
  }

  onSubmitNewValue(autoConfirm) {
    return new Promise((resolve, reject) => {
      let values = [...this.state.values];
      let value = values[this.state.newIndex];
      if (!value || this.isEmpty(value)) {
        resolve(false);
        return false;
      }
      value = value.toString().trim();
      const oldIndex = this.state.isEditing
        ? this.state.newIndex
        : Math.max(values.length - 1, 0);
      const newIndex = this.state.isEditing
        ? this.state.values.length - 1
        : Math.max(values.length, 0);

      // values[newIndex] can have previous user input
      if (values[newIndex] === undefined) {
        values[newIndex] = '';
      }

      values[oldIndex] = value;

      let toSubmitValue = this.getStorable(values);

      if (toSubmitValue.value[toSubmitValue.value.length - 1] === '') {
        toSubmitValue.raw.pop();
      }

      // sync interface update
      this.setState({
        status: true,
        newIndex,
        values,
        rawValues: toSubmitValue.raw,
        errorMsg: false,
        touched: false,
        isEditing: false
      });

      // delayed validation
      this.hasError(values, (error, processing) => {
        // apply validation from API
        this.setState({ errorMsg: error, status: !error });
        if (this.props.onChangeValue)
          this.props.onChangeValue(
            this.props.id,
            toSubmitValue,
            !error,
            this.state.reference
          );
        if (this.props.onChange)
          this.props.onChange(
            this.props.id,
            toSubmitValue,
            !error,
            this.state.reference
          );

        if (!error && autoConfirm === true && this.props.onGoNext) {
          setTimeout(() => {
            this.props.onGoNext();
          }, 200);
        } else {
          this.focus();
        }

        if (!processing) {
          if (!error) resolve();
          else reject();
        }
      });
    });
  }

  onDeleteValue(index) {
    let raw = this.state.rawValues;
    let values = this.state.values;
    let newIndex = this.state.newIndex;

    if (newIndex > index) {
      newIndex--;
    } else if (newIndex === index) {
      newIndex = values.length - 1;
    }

    raw.splice(index, 1);
    values.splice(index, 1);

    let toSubmitValue = this.getStorable(values, raw);

    this.hasError(values, error => {
      this.setState({
        newIndex,
        status: !error,
        errorMsg: error,
        values: values,
        rawValues: toSubmitValue.raw
      });

      if (this.props.onChangeValue)
        this.props.onChangeValue(
          this.props.id,
          toSubmitValue,
          !error,
          this.state.reference
        );
      if (this.props.onChange)
        this.props.onChange(
          this.props.id,
          toSubmitValue,
          !error,
          this.state.reference
        );
    });

    this.delayedFocus = setTimeout(() => {
      this.focus({ preventScroll: true });
    }, 100);
  }

  onChangeInputValue(evt) {
    const isCheckbox = evt.target.type === 'checkbox';
    const input = evt.target ? evt.target.value : evt.value;
    const value = isCheckbox
      ? evt.target.checked
        ? 1
        : 0
      : this.parseInput(input);
    const values = [...this.state.values];
    values[this.state.newIndex] = value;
    const toSubmitValue = this.getStorable(values);

    if (this.state.values[this.state.newIndex] === value) return;

    if (toSubmitValue.value[toSubmitValue.value.length - 1] === '') {
      toSubmitValue.raw.pop();
    }

    this.maskerate(value);

    this.hasError(values, errorMsg => {
      const status = isCheckbox || !errorMsg;

      this.setState({
        values,
        rawValues: toSubmitValue.raw,
        errorMsg: errorMsg,
        status: !errorMsg
      });
      if (this.props.onChange)
        this.props.onChange(
          this.props.id,
          toSubmitValue,
          status,
          this.state.reference
        );

      if (!errorMsg) this.triggerAPIEvent('autocomplete', values, !errorMsg);
      if (!errorMsg) this.triggerAPIEvent('data', values, !errorMsg);
    });
  }

  renderCurrentError() {
    this.hasError(this.state.values, error => {
      if (this.props.onUpdateStatus) this.props.onUpdateStatus(!error);
      this.setState({ errorMsg: error, status: !error, touched: true });
    });
  }

  // render methods
  renderHeader() {
    return (
      <header>
        {this.props.question && (
          <Title color={this.props.color}>
            <span
              dangerouslySetInnerHTML={{
                __html: this.props.question + (this.props.mandatory ? '*' : '')
              }}
            />
            {this.renderInfo()}
          </Title>
        )}
        {this.props.description && (
          <Description
            color={this.props.color}
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          />
        )}
      </header>
    );
  }

  renderAlert() {
    if (this.state.touched && this.state.errorMsg) {
      return <Feedback>{this.state.errorMsg || ''}</Feedback>;
    } else if (this.state.maskErrorMsg) {
      return <Feedback>{this.state.maskErrorMsg}</Feedback>;
    }

    return false;
  }

  renderButtons(isMultiple, isEdit, canAdd = true) {
    return (
      <div style={{ display: 'inherit' }}>
        {isMultiple &&
          !isEdit && (
            <Tooltip
              title={`${t('Add another field to')} ${this.props.label}`}
              arrow={true}
            >
              <PlusButton
                ref="plusBtn"
                color={this.props.color}
                disabled={!this.state.status || !canAdd}
                onClick={this.onSubmitNewValue}
              >
                +
              </PlusButton>
            </Tooltip>
          )}
        {isMultiple &&
          isEdit && (
            <Tooltip title={t('Confirm edit')} arrow={true}>
              <EditConfirmButton
                color={this.props.color}
                disabled={!this.state.status || !canAdd}
                onClick={this.onSubmitNewValue}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h30v30H0z" />
                    <mask
                      id="confirmEditMask"
                      fill="#fff"
                      className="colored-fill"
                    >
                      <path d="M11.897 23.43L26.243 9.086a1.221 1.221 0 0 0-1.728-1.727L11.897 19.976l-6.412-6.412a1.221 1.221 0 1 0-1.727 1.727l8.14 8.14z" />
                    </mask>
                    <g
                      fill="#000"
                      className="colored-fill"
                      mask="url(#confirmEditMask)"
                    >
                      <path d="M0 0h30v30H0z" />
                    </g>
                  </g>
                </svg>
              </EditConfirmButton>
            </Tooltip>
          )}
        {isMultiple &&
          isEdit && (
            <Tooltip title={t('Cancel edit')} arrow={true}>
              <EditCancelButton
                className="invert"
                color={this.props.color}
                onClick={this.onDeselectValueListItem}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h30v30H0z" />
                    <mask
                      id="cancelEditMask"
                      fill="#fff"
                      className="colored-fill"
                    >
                      <path d="M13.3 15L7.352 9.052a1.202 1.202 0 0 1 1.7-1.7L15 13.3l5.948-5.948a1.202 1.202 0 0 1 1.7 1.7L16.7 15l5.948 5.948a1.202 1.202 0 0 1-1.7 1.7L15 16.7l-5.948 5.948a1.202 1.202 0 0 1-1.7-1.7L13.3 15z" />
                    </mask>
                    <g
                      fill="#000"
                      className="colored-fill"
                      mask="url(#cancelEditMask)"
                    >
                      <path d="M0 0h30v30H0z" />
                    </g>
                  </g>
                </svg>
              </EditCancelButton>
            </Tooltip>
          )}
      </div>
    );
  }

  // main render
  render(children) {
    return super.render(children);
  }
}

export default QuestionBasic;
