import styled from 'styled-components';
import globalStyle from '../globalStyle';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100vw;
    background: ${ globalStyle.red };
    align-items: center;
    justify-content: center;
`

export const Text = styled.span`
    color: white;
    font-weigth: bold;
    text-align: center;
`