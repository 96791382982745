
import ConditionsExpressionCompiler from './Compilers/ConditionsExpressionCompiler.js'

/* Singleton class responsible to facade ConditionsExpressionCompiler, Evaluable and
 * Comparator complexity. Also cache compiled expressions to reduce
 * ConditionsExpressionCompiler overhead.
 */
export default class ConditionService {

  static instance = undefined;

  constructor () {
    if (!ConditionService.instance) {
      this.compiler = new ConditionsExpressionCompiler()
      this.cachedConditions = { 'singleton_test': Math.random(100) }
      ConditionService.instance = this
    }

    return ConditionService.instance
  }

  /* Responsible to invoke ConditionsExpressionCompiler (only when needed) to translate
   * expression to an Evaluable object, cache the result and return it.
   */
  process (expression, conditions) {
    if (!this.cachedConditions[expression]) {
      if (conditions) {
        let evaluableExpression = this.compiler.compile(conditions, expression)
        this.cachedConditions[expression] = evaluableExpression
        return evaluableExpression
      } else {
        throw new Error ('Cannot process empty conditions')
      }
    }

    return this.cachedConditions[expression]
  }

  /* Evaluates the result of the expression given with actual variables values.
   */
  evaluate (expression, variables, conditions, key) {
    if (expression) {
      return this.process(expression, conditions).evaluate(variables, key)
    } else {
      return true
    }
  }

  preCompile (variables, blocks, conditions) {
    let expressions = [ ]
    let leanConditions = { }

    Object.keys(variables).forEach((key) => {
      if (variables[key].conditions !== undefined && variables[key].conditions !== "" ) {
        expressions.push(variables[key].conditions)
      }
    })

    Object.keys(blocks).forEach((key) => {
      if (blocks[key].conditions !== undefined && blocks[key].conditions !== "" ) {
        expressions.push(blocks[key].conditions)
      }
    })

    Object.keys(conditions).forEach((key) => {
      leanConditions[key] = conditions[key]
    })

    expressions.forEach(expr => {
      this.process(expr, leanConditions)
    })
  }

  /* Returns object instance.
   */
  static getInstance() {
    return new ConditionService()
  }
}
