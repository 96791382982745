import React, { Component } from 'react';

import { TextInput } from '../../globalStyle';


export const PercentService = {
    percentToValue( input ){
        if( input === "" || input === undefined || input === null ) return "";
        if( !isNaN( input ) ) return parseFloat(input);

        const isNeg = input.indexOf("-") > -1;

        const posComma = input.indexOf(",");

        let value = input.replace(/[%/s]*/gi,'');
        if( posComma > -1 ){
            // remove thousands
            value = value.replace(/\.+/gi,"");
            // convert cents
            value = value.replace(/,+/gi,".");
        }

        value = !isNaN(value)? parseFloat( value ) * ( isNeg ? -1 : 1 ) : input;

        return value;
    },

    valueToPercent( value ){
        if(value === '') return ''
        let number = value;
        //If aready have % at the end, just return number. Else, add % symbol.
        if (number.length > 0 && number[number.length - 1] === "%") {
          return number;
        } else {
          return number + "%";
        }
    },

    isValid( input ){
        return !isNaN( this.percentToValue( input ) );
    }
}

export default class PercentInput extends Component{
    constructor(props){
        super(props);

        const propValue = this.props.value ? this.getFormatedValue(this.props.value).value : "" ;
        this.state = {
            value: propValue
        }

        this.parentOnChange = this.props.onChange;
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onByPassChange = this.onByPassChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value === '' && nextProps.value !== this.state.value ) {
          this.setState({value: ''});
        }
        else if (nextProps.value !== this.props.value && nextProps.value !== this.state.value ) {
            if( !this.isFocused() ){
                this.setState({
                    value: this.getFormatedValue( nextProps.value ).value
                });
            }
        }
    }

    focus(attr) {
      this.refs.input.focus(attr);
    }

    isFocused() {
      return this.refs.input === document.activeElement;
    }

    onByPassChange(evt){
        const scoped = {
            ...evt
        };
        this.setState({value: evt.target.value.replace(/[^%,.0-9]/g,'')});
        setTimeout(()=>{
            this.parentOnChange( scoped );
        }, 1);
    }

    getFormatedValue( input ){
        let values, value;

        values = PercentService.percentToValue( input );
        if( !isNaN(values) ){
            value = PercentService.valueToPercent( values );
        }else{
            value = input;
        }

        return {
            value
        }
    }

    onBlur(evt){
        this.onChange(evt);
        this.props.onBlur();
    }

    onChange(evt){
        let values;
        const input = evt.target.value;

        values = this.getFormatedValue(input);
        evt.target.value = values.value;

        this.parentOnChange( evt, values );
        if( this.props.onBlur ) this.props.onBlur(evt);
        this.setState({value: values.value});
    }

    render(){
        const props = {...this.props};
        delete props.value;
        delete props.onBlur;
        delete props.onChange;
        return <TextInput ref="input" type="text" {...props} value={this.state.value} onChange={this.onByPassChange} onBlur={this.onBlur}/>
    }
}
