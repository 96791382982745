import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Viewport, QuestionsWrapper } from './style';
import QuestionWrapper from '../questions/questionWrapper';
import Navigator from '../uiElements/navigator';
import { AppConfirmDialog } from '../appDialog';

import ScrollerBox from '../scrollerBox';

function mapStateToProps(state){
    const { app, nav, interview } = state;
    return { app, nav, interview };
}

const mapDispatchToProps = dispatch => {
    return {
        prev(skipGroup){
            return dispatch({
                type: "NAV_PREV_VARIABLE",
                payload: {skipGroup}
            });
        },
        next(skipGroup){
            return dispatch({
                type: "NAV_NEXT_VARIABLE",
                payload: {skipGroup}
            });
        },
        nextBlock(){
            return dispatch({
                type: "NAV_NEXT_BLOCK",
                payload: { }
            });
        },
        prevBlock(){
            return dispatch({
                type: "NAV_PREV_BLOCK",
                payload: { }
            });
        },
        navUnanswered(){
            return dispatch({
              type: "NAV_UNANSWERED",
              payload: { }
            });
        },
        submitInterview( ){
            return dispatch({ type: "SUBMIT_INTERVIEW", payload: { } });
        },
        openLoading(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        closeLoading(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        showSuccess(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Interview saved!", text: "The interview was saved on your profile. You'll be redirected to iDox Explorer now.", type: "success" }
            })
        },
        showError(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Fail to save Interview!", text: "The interview can't be saved at this moment. Please, try again later.", type: "error" }
            })
        },
        hideSuccess(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Interview saved!", text: "The interview was saved on your profile. You'll be redirected to iDox Explorer now.", type: "success" }
            })
        }
    }
}

class InterviewContent extends Component {

    constructor(props){
        super(props);
        this.state = { scrollCall: false, showConfirm: false };
        this.wrappers = { }

        this.onNavNext = this.onNavNext.bind(this);
        this.onNavPrev = this.onNavPrev.bind(this);
        this.onSubmitInterview = this.onSubmitInterview.bind(this);
        this.onNavigateToUnanswered = this.onNavigateToUnanswered.bind(this);
    }

    componentDidMount() {
      window.addEventListener('request-nav-next', this.onNavNext)
      window.addEventListener('request-nav-prev', this.onNavPrev)
    }

    componentDidUpdate(prevProps) {
      if (prevProps.app.savingAnswers && !this.props.app.savingAnswers){
        this.updateSaveDialogContent();
      }
    }

    componentWillUnmount(){
        if(this.scrollCallDelay) clearTimeout(this.scrollCallDelay);
        window.removeEventListener('request-nav-next', this.onNavNext)
        window.removeEventListener('request-nav-prev', this.onNavPrev)
    }

    getVariables(navTree){
        const result = {};

        navTree.forEach((navNode)=>{
            result[ navNode.id ] = this.props.interview.variables[ navNode.id ];
        });

        return result;
    }

    updateScrollPos(pos, min, max) {

      pos = pos !== undefined ? pos : this.state.scrollCallPos.scrollTarget;

      this.setState({
        scrollCallPos: {
          scrollTarget: pos,
          scrollMin: min,
          scrollMax: max
        }
      });
    }

    updateScroll(pos, min, max){
        //if( this.state.scrollCallPos && this.state.scrollCallPos.scrollTarget === pos ) return false;
        // set new pos
        this.updateScrollPos(pos, min, max);
        // reset forced pos
        if( this.scrollCallDelay ) clearTimeout(this.scrollCallDelay);
        this.scrollCallDelay = setTimeout(()=>{
            this.setState({scrollCallPos: false});
        }, 50);
    }

    onNavigateToUnanswered(evt) {
      if( evt.confirmed ){
        this.setState({ showConfirm: false });
        this.props.navUnanswered();
      } else if( evt.confirmed === false ){
        this.setState({ showConfirm: false });
      }

      evt.confirmed = undefined;
    }

    updateSaveDialogContent() {
        if(this.props.app.saveError) {
          this.props.showError();
        } else {
          this.props.showSuccess();
          setTimeout(() => {
            window.location.replace(this.props.app.redirectUrl);
          }, 2300);
        }
        setTimeout(()=>{
          this.props.closeLoading();
        }, 1000 );
    }

    finishInterview(e) {
      setTimeout(()=>{
          this.props.openLoading();
          this.props.submitInterview();
      }, 1 );
    }

    onSubmitInterview(){
      let finished = this.props.nav.interviewFinished;
      let hasUnanswered = this.props.nav.unansweredVariable;

      if (finished) {
        if(hasUnanswered.var_id) {
          this.setState({showConfirm: true})
        } else {
          this.finishInterview();
        }
      }
    }

    getWrapperInstance(varNavId) {
        if (varNavId && this.wrappers[varNavId]) {
          return this.wrappers[varNavId].getWrappedInstance()
        } else {
          //verify by prefix
          let wrapperId = Object.keys(this.wrappers).find(item => varNavId.startsWith(item))
          return this.wrappers[wrapperId].getWrappedInstance()
        }
    }

    onSubmit() {
        let ref = this.getWrapperInstance(this.props.nav.currentVariable);
        if (ref) ref.onSubmit(false).catch(() => { });
    }

    onNavNext(evt) {
      this.props.next(evt.detail ? evt.detail.skipGroup : false)
    }

    onNavPrev(evt) {
      this.props.prev(evt.detail ? evt.detail.skipGroup : false)
    }

    onNavNextBlock() {
        let ref = this.getWrapperInstance(this.props.nav.currentVariable);
        if (ref && this.props.nav.nextBlock.allowed) {
          ref.onSubmit(true, 'nav').finally(() => {
            this.props.nextBlock();
          });
        }
    }

    onNavPrevBlock() {
        let ref = this.getWrapperInstance(this.props.nav.currentVariable);
        if (ref && this.props.nav.previousBlock.allowed) {
          ref.onSubmit(true, 'nav').finally(() => {
           this.props.prevBlock();
         });
        }
    }

    render(){
        const blockId = this.props.block;
        let block = this.props.nav.currentNavTree.find((blk)=>(blk.id === parseInt(blockId)));
        let navTree = block ? block.subVariables : block;
        let variablesData = this.getVariables( navTree || [] );
        let currentVariableId = this.props.nav.currentVariable ? this.props.nav.currentVariable : null;

        return <Viewport id="interview-content-viewport" ref="questionsContent">
                    <AppConfirmDialog title={"You have mandatory unanswered questions and will be redirected to it."} showConfirm={this.state.showConfirm} onConfirm={this.onNavigateToUnanswered} hideCancel={true} color={this.props.color}/>
                    <ScrollerBox dom="interview-content-viewport" contentDom="interview-content-wrapper" onSwipeUp={ this.props.onSwipeUp } color={this.props.color}>
                        <QuestionsWrapper id="interview-content-wrapper" ref="questionsContentWrapper">
                            {
                                navTree && navTree.map(( variable, i )=>{
                                    const varData = variablesData[ variable.id ];
                                    return <QuestionWrapper ref={(input) => {this.wrappers[variable.navId] = input }}
                                                            onSubmitInterview={this.onSubmitInterview}
                                                            color={this.props.color}
                                                            key={ variable.id }
                                                            active={ variable.id === currentVariableId }
                                                            {...varData}
                                                            navId={ variable.navId }
                                                            navTree={ variable.subVariables }/>
                                })
                            }
                        </QuestionsWrapper>
                    </ScrollerBox>
                    <Navigator onNavNext={this.onNavNext} onNavPrev={this.onNavPrev} color={this.props.color}/>
                </Viewport>
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(InterviewContent);
