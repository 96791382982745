import RemoteApi from './RemoteApi';
import LocalApi from './LocalApi';

import DataInterface from './DataInterface';

let dataInterface = new DataInterface();

export const local = LocalApi.getInstance();
export const remote = RemoteApi.getInstance();

export default {
  local: LocalApi.getInstance(),
  remote: RemoteApi.getInstance(),
  storeAnswers(hash, variables, answers, user, isComplete) {
    let apiAnswers = dataInterface.answersToApi(variables, answers);

    local.storeAnswers(hash, answers);

    return remote.storeAnswers(hash, apiAnswers, user, isComplete);
  }
};
