
import React, { Fragment } from 'react';
import {connect} from 'react-redux';

import Tutorial from '../../tutorial';

import { NavigatorWrapper, NavigatorButton, Arrows } from './style';
//import { Button } from '../../globalStyle';
import translateService from '../../../services/translateService';

//import { AppConfirmDialog } from '../../appDialog';

import IconUpArrow from './IconUpArrow';
import IconDownArrow from './IconDownArrow';

import { push } from 'connected-react-router'

export const translateDictionary = new translateService();
export const t = translateDictionary.getTranslateFunction();

function mapStateToProps(state){
    const { nav, app } = state;
    return { nav, app };
}

const mapDispatchToProps = dispatch => {
    return {
        submitInterview( ){
            return dispatch({
                type: "SUBMIT_INTERVIEW",
                payload: { }
            });
        },
        prev(){
            return dispatch({
                type: "NAV_PREV_VARIABLE",
                payload: { }
            });
        },
        next(){
            return dispatch({
                type: "NAV_NEXT_VARIABLE",
                payload: { }
            });
        },
        finish(){
            return dispatch(push({
                pathname: "/finished/",
                search: `?h=${ this.props.app.hash }`
            }));
        },
        navUnanswered(){
            return dispatch({
              type: "NAV_UNANSWERED",
              payload: { }
            });
        },
        openLoading(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        showSuccess(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Interview saved!", text: "The interview was saved on your profile. You'll be redirected to iDox Explorer now.", type: "success" }
            })
        },
        showError(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Fail to save Interview!", text: "The interview can't be saved at this moment. Please, try again later.", type: "error" }
            })
        },
        hideSuccess(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Interview saved!", text: "The interview was saved on your profile. You'll be redirected to iDox Explorer now.", type: "success" }
            })
        },
        closeLoading(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        }
    }
}

class Navigator extends Tutorial {

    constructor(props) {
      super(props, ['↑', '↓'], true, "NAVIGATOR", "top-end", "use-arrows-to-navigate");

      this.saveInterview = this.saveInterview.bind(this);
      this.onNavigateToUnanswered = this.onNavigateToUnanswered.bind(this);
    }

    componentDidMount() {
        this.showTutorial();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.app.savingAnswers && !this.props.app.savingAnswers){
        this.updateSaveDialogContent();
      }
    }

    previousBlockClass(){
        return !this.canPrev() ? "" : ( !this.isPreviousSameBlock() ? "previousBlock" : "" );
    }

    nextBlockClass(){
        return !this.canNext() ? "" : ( !this.isNextSameBlock() ? "nextBlock" : "" );
    }

    isPreviousSameBlock(){
        return this.hasPrev() && this.props.nav.previousVariable.block_id !== this.props.nav.previousBlock.block_id
    }

    isNextSameBlock(){
        return this.hasNext() && this.props.nav.nextVariable.block_id !== this.props.nav.nextBlock.block_id
    }

    hasNext() {
        // if interview finished, nev_next will redirect to finish page
        return this.props.nav.nextVariable.var_id !== undefined
    }

    hasPrev() {
        return this.props.nav.previousVariable.var_id !== undefined
    }

    canNext(){
       return this.hasNext() && ( this.isNextSameBlock() || this.props.nav.nextBlock.allowed );
    }

    canPrev(){
       return this.hasPrev() && ( this.isPreviousSameBlock() || this.props.nav.previousBlock.allowed );
    }

    next(){
        if( this.props.nav.nextVariable.var_id !== undefined ){
            window.dispatchEvent(new CustomEvent('request-submit', {detail: {nextHook: 'request-nav-next', reason: 'nav'}}))
        }
    }

    prev(){
        if( this.props.nav.previousVariable.var_id !== undefined ){
            window.dispatchEvent(new CustomEvent('request-submit', {detail: {nextHook: 'request-nav-prev', reason: 'nav'}}))
        }
    }

    updateSaveDialogContent() {
        if(this.props.app.saveError) {
          this.props.showError();
        } else {
          this.props.showSuccess();
          setTimeout(() => {
            window.location.replace(this.props.app.redirectUrl);
          }, 2300);
        }
        setTimeout(()=>{
          this.props.closeLoading();
        }, 1000 );
    }

    finishInterview(e) {
      setTimeout(()=>{
          this.props.openLoading();
          this.props.submitInterview();
      }, 1 );
    }

    saveInterview(){
      let finished = this.props.nav.interviewFinished
      let hasUnanswered = this.props.nav.unansweredVariable;

      if (finished) {
        if(hasUnanswered.var_id) {
          this.setState({showConfirm: true})
        } else {
          this.finishInterview();
        }
      }
    }

    onNavigateToUnanswered(evt) {
      if( evt.confirmed ){
        this.setState({ showConfirm: false });
        this.props.navUnanswered();
      } else if( evt.confirmed === false ){
        this.setState({ showConfirm: false });
      }

      evt.confirmed = undefined;
    }

    render(){
        const nextClass = (["square", "invert", this.nextBlockClass()]).filter(value=>value).join(" ");
        const previousClass = (["square", "invert", this.previousBlockClass()]).filter(value=>value).join(" ");
        //const isFinished = this.props.nav.interviewFinished;

        return  <Fragment>
                    {/*<AppConfirmDialog title={"You have mandatory unanswered questions.\nDo you want to see it?"} showConfirm={this.state.showConfirm} onConfirm={this.onNavigateToUnanswered} onCancel={this.onNavigateToUnanswered} color={this.props.color}/>*/}
                    <NavigatorWrapper>
                        {/* isFinished && <Button color={this.props.color} onClick={this.saveInterview.bind(this)}>{t('Send Interview')}</Button> */}
                        <Arrows>
                        { this.renderWithTutorial(
                            <div>
                                <NavigatorButton color={this.props.color} disabled={!this.hasPrev()} className={previousClass} onClick={this.prev.bind(this)}>
                                    <IconUpArrow color={this.props.color}/>
                                </NavigatorButton>
                                <NavigatorButton color={this.props.color} disabled={!this.canNext()} className={nextClass} onClick={this.next.bind(this)}>
                                    <IconDownArrow color={this.props.color}/>
                                </NavigatorButton>
                            </div>
                        ) }
                        </Arrows>
                    </NavigatorWrapper>
                </Fragment>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
