import React from 'react';
import QuestionBasic, { t, Actions, maskDictionary } from '../../questions';
import { connect } from 'react-redux';

import { Question, InputLine } from '../style';
import LoopButton from '../../uiElements/LoopButton';
import { TextInput, Mask } from '../../globalStyle';

import Select from '../../uiElements/SelectInput';
import LongTextInput from '../../uiElements/LongTextInput';
import AutocompleteInput from '../../uiElements/AutocompleteInput';

function mapStateToProps(state) {
  const { interview, nav } = state;
  return { interview, nav };
}

const mapDispatchToProps = dispatch => {
  return {
    ...Actions(dispatch)
  };
};

class QuestionText extends QuestionBasic {
  constructor(props) {
    super(props);

    this.state.isLoading = false;

    const hasAutocomplete = this.eventsService.findEvent(
      'autocomplete',
      this.props.events
    );
    if (hasAutocomplete) {
      this.state.hasAutocomplete = true;
      // set default value to empty string to allow the autocomplete get data from API
      if (!this.state.values.length) this.state.values[0] = '';
    }

    if (props.mask) {
      this.state.masked = this.placeholderMask;
    }

    // keyboard events
    this.registerKey(
      'Enter',
      e => {
        if (this.props.allowLooping) {
          // if valid
          if (this.state.status) {
            // if editing, submit editing value
            if (this.state.isEditing) {
              e.stopPropagation();
              this.onSubmitNewValue();
            }
            // if not editing and has any value, submit and go next
            else if (this.state.values[this.state.newIndex]) {
              e.stopPropagation();
              this.onSubmitNewValue(true);
            }
          } else {
            // if invalid, just stop propagation
            e.stopPropagation();
          }
        } else {
          this.onBlur();
        }
      },
      false,
      false
    );

    // Cancel editing value
    this.registerKey(
      'Escape',
      e => {
        if (
          this.props.allowLooping &&
          this.state.isEditing &&
          e.target === this.refs.input
        ) {
          e.stopPropagation();
          this.onDeselectValueListItem();
        }
      },
      false,
      false
    );

    this.registerKey(['ArrowLeft', 'ArrowRight'], undefined, false, true);

    // Arrows up and down is disabled just in Long Text and Select
    if (this.inputType() === LongTextInput || this.inputType() === Select) {
      this.registerKey(
        ['ArrowUp', 'ArrowDown'],
        e => {
          // Arrows changing actual question is only disabled into long text
          // editor
          if (e.target === this.refs.input.$editor) {
            e.stopPropagation();
          }
        },
        false,
        false
      );
    }
    
    // Add new value
    this.registerKey(['Shift', '+'], (e) => {
      if (!!e.shiftKey) {
        switch (e.keyCode) {
          case 16: // ignore shift key
          break;
          default:
            
            if (this.isFocused() 
              && this.state.status 
              && this.state.values.length) {
                e.preventDefault();
                this.onSubmitNewValue();
            }
          
            break;
        }
      }
    }, false, false);

    this.registerKey(
      'Tab',
      e => {
        if (e.shiftKey) {
          if (!this.hasParent() && e.target === this.refs.input) {
            e.preventDefault();
          }
        } else {
          if (!this.state.status) {
            e.preventDefault();
            this.renderCurrentError();
          }
        }
      },
      false,
      true
    );

    this.registerKey(
      'Backspace',
      e => {
        if (
          this.props.mask &&
          e.target === this.refs.input &&
          this.isCarretAtEnd(this.refs.input)
        ) {
          this.removeLastEditableChar();
          e.preventDefault();
        }
      },
      false,
      false
    );
  }

  componentDidMount() {
    this.setTutorialKeys(this.inputType() === Select ? ['↓', '↑'] : []);
    super.componentDidMount();
  }

  hasError(values, callback) {
    // check mandatory
    if (this.props.mandatory) {
      let emptyValue = values.findIndex(value => this.isEmpty(value));

      if (
        !values.length || // no itens
        (values.length === 1 && emptyValue !== -1) || // input is empty and no other itens
        (emptyValue !== values.length - 1 && emptyValue !== -1)
      ) {
        // an item !== of input is empty
        return callback(t('This is a obligatory field'), false);
      }
    }

    this.triggerAPIEvent('validation', values).then(response => {
      const error =
        response && response.join(' ').trim()
          ? response.join(' ').trim()
          : false;
      callback(error, false);
    });
    return callback(true, true);
  }

  isEmpty(value) {
    if (this.props.type !== 'LT') {
      return value.trim() === '';
    } else {
      let val = document.createElement('div');
      val.innerHTML = value;
      return val.textContent.trim() === '';
    }
  }

  isValid(values) {
    if (
      this.props.mandatory &&
      (!values || !values.length || this.isEmpty(values[0]))
    )
      return false;
    return true;
  }

  getSelectOptions(value) {
    const options = {
      id: 'auto' + this.props.id,
      value: value,
      suggestions: this.props.list || [],
      onSizeChange: this.props.onSizeChange
    };

    return options;
  }

  getLongTextOptions(value) {
    return {
      config: {
        placeholderText: '',
        charCounterCount: false,
        toolbarVisibleWithoutSelection: false,
        events: {
          'froalaEditor.focus': function(e, editor) {
            //console.log(editor.selection.get());
          }
        },
        toolbarButtons: [
          'undo',
          'redo',
          '|',
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'outdent',
          'indent',
          'clearFormatting',
          'insertTable',
          'html'
        ]
      },
      tag: 'textarea'
    };
  }

  inputType() {
    if (
      !this.props.interview.variables ||
      !this.props.interview.variables[this.props.id]
    )
      return TextInput;

    switch (this.props.interview.variables[this.props.id].type) {
      case 'LT':
        return LongTextInput;
      case 'ST':
        if (this.props.list || this.state.hasAutocomplete) {
          return AutocompleteInput;
        } else {
          return TextInput;
        }
      default:
        return TextInput;
    }
  }

  parseInput(value, ignoreError) {
    if (value === undefined || value === null) {
      return '';
    } else if (value !== '' && this.props.mask) {
      const { value: maskedValue, error } = this.maskString(
        value,
        this.props.mask,
        maskDictionary
      );
      if (!ignoreError) {
        this.setState({
          maskErrorMsg: error
        });
      }
      return maskedValue;
    } else {
      return value;
    }
  }

  onChangeInputValue(evt) {
    // the event can come from input or select
    const input = evt.target ? evt.target.value : evt.value;
    const value = this.parseInput(input);
    let values = [...this.state.values];
    values[this.state.newIndex] = value;
    const toSubmitValue = this.getStorable(values);

    if (this.state.values[this.state.newIndex] === input) return;

    if (toSubmitValue.value[toSubmitValue.value.length - 1] === '') {
      toSubmitValue.raw.pop();
    }

    if (this.props.mask) {
      const masked =
        this.maskable(value) && value.length < this.placeholderMask.length
          ? value + this.placeholderMask.slice(value.length)
          : '';

      this.setState({
        masked
      });
    }

    // delayed validation
    this.hasError(values, error => {
      // apply validation from API
      this.setState({
        values,
        rawValues: toSubmitValue.raw,
        errorMsg: error,
        status: !error
      });
      this.props.onChange(
        this.props.id,
        toSubmitValue,
        !error,
        this.state.reference
      );
      if (!error) this.triggerAPIEvent('autocomplete', values, !error);
      if (!error) this.triggerAPIEvent('data', values, !error);
    });
  }

  renderInput(isMultiple, isEdit) {
    const values = this.state.values;
    const inputValue = values[this.state.newIndex]
      ? values[this.state.newIndex]
      : '';
    const ExtraOptions =
      this.props.type === 'LT'
        ? this.getLongTextOptions(inputValue)
        : this.state.hasAutocomplete
          ? this.getSelectOptions(inputValue)
          : {};

    const isValid = this.state.touched && this.state.status;
    const hasAnswer = this.state.touched && inputValue;
    const Input = this.inputType();
    const isLoading = this.props.isLoading || this.state.isLoading;

    return this.renderWithTutorial(
      <InputLine className={isEdit ? 'bordered' : ''} onFocus={this.onFocus}>
        <Input
          ref="input"
          isInvalid={!isValid}
          hasAnswer={hasAnswer}
          isLoading={isLoading}
          color={this.props.color}
          value={inputValue}
          onChange={this.onChangeInputValue}
          onBlur={this.onBlur}
          id={this.props.id}
          {...ExtraOptions}
        />
        {this.placeholderMask && <Mask>{this.state.masked}</Mask>}
        {this.renderButtons(isMultiple, isEdit, !this.isEmpty(inputValue))}
      </InputLine>
    );
  }

  render() {
    const isMultiple = this.props.allowLooping;
    const values = this.state.values.slice(0, this.state.values.length - 1);
    const isEdit = this.state.isEditing;

    return (
      <Question
        className={this.props.active ? 'active' : ''}
        color={this.props.color}
        id={this.props.navId}
        onKeyDown={this._keyDown}
      >
        {this.renderHeader()}
        {isMultiple && (
          <div>
            {values &&
              values.map((value, i) => {
                if (!isEdit || this.state.newIndex !== i)
                  return (
                    <LoopButton
                      editable={true}
                      color={this.props.color}
                      selected={this.state.newIndex === i}
                      onEdit={this.onSelectValueListItem}
                      onRemove={() => {
                        this.onDeleteValue(i);
                      }}
                      key={i}
                      value={value}
                      index={i}
                    >
                      {value}
                    </LoopButton>
                  );
                if (isEdit && this.state.newIndex === i)
                  return (
                    <div key={i}>{this.renderInput(isMultiple, isEdit)}</div>
                  );
                return false;
              })}
            {!isEdit && this.renderInput(isMultiple, isEdit)}
            {this.renderAlert()}
          </div>
        )}
        {!isMultiple && (
          <div>
            {this.renderInput(isMultiple, isEdit)}
            {this.renderAlert()}
          </div>
        )}
      </Question>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionText);
