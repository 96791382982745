import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 99px;
        height: 99px;
        border-radius: 50%;
    }
`;

export default class IconDone extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="107" viewBox="0 0 107 107">
                        <defs>
                            <linearGradient id="a135" x1="88.747%" x2="12.494%" y1="93.439%" y2="7.314%">
                                <stop offset="0%" stopColor={this.props.color.main} />
                                <stop offset="100%" stopColor={this.props.color.main} />
                            </linearGradient>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle cx="49.5" cy="49.5" r="49.5" stroke="url(#a135)" strokeWidth="8" transform="translate(4 4)"/>
                            <g transform="translate(3 3)">
                                <path d="M0 0h100v100H0z"/>
                                <mask id="c" fill="#fff">
                                    <path d="M50 16.667C31.6 16.667 16.667 31.6 16.667 50S31.6 83.333 50 83.333 83.333 68.4 83.333 50 68.4 16.667 50 16.667zm-6.667 50L26.667 50l4.7-4.7 11.966 11.933 25.3-25.3 4.7 4.734-30 30z"/>
                                </mask>
                                <path fill="#000" fillRule="nonzero" d="M50 16.667C31.6 16.667 16.667 31.6 16.667 50S31.6 83.333 50 83.333 83.333 68.4 83.333 50 68.4 16.667 50 16.667zm-6.667 50L26.667 50l4.7-4.7 11.966 11.933 25.3-25.3 4.7 4.734-30 30z"/>
                                <g fill={this.props.color.main} mask="url(#c)">
                                    <path d="M0 0h100v100H0z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </Wrapper>;
    }
}