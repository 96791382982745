import update from 'immutability-helper';

export const initialState = {
    dialogCalls: []
};

const rootReducer = (state = initialState, action) => {
    let dialogCall;
    switch (action.type) {
        case "OPEN_DIALOG":
            const randId = "dialog-" + Math.floor( Math.random() * 10000 );
            dialogCall = action.payload.type ? action.payload : { text: action.payload, type: "alert" };
            dialogCall.id = randId;
            return update(state, {
                dialogCalls: { $push: [ dialogCall ] }
            });
        case "CLOSE_DIALOG":
            const dialogCallId = action.payload.text || action.payload.title || action.payload ;
            const currentDialog = state.dialogCalls.find(item => item.title !== dialogCallId || item.text !== dialogCallId);
            const callback = currentDialog && currentDialog.onClose;
            if( callback ) callback();

            return update(state, {
                dialogCalls: { $set: ( state.dialogCalls.filter(item => item.id !== currentDialog.id ) ) }
            });
        default:
            return state;
    }
}

export default rootReducer;
