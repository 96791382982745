import React from 'react';

import KeyboardControlled from '../../keyboardControlled';

import { SimpleLoopButton, Wrapper } from './style'

export default class LoopButton extends KeyboardControlled {
    constructor(props) {
      super(props);

      this.registerKey('Tab', (e) => {
        //console.log(e.target);
        if(e.target === this.refs.label) {
          //console.log('label');
        } else if (e.target === this.refs.deleteBtn){
          //console.log('btn');
        }
      });

      // if pressed space on label, emmit edit.
      // if pressed space on delete button, emmit delete.
      this.registerKey(' ', (e) => {
        if(e.target === this.refs.label) {
          this.onEdit();
        } else if (e.target === this.refs.deleteBtn){
          this.onDelete();
        }
      }, true, true);

      this.onEdit = this.onEdit.bind(this);
      this.onDelete = this.onDelete.bind(this);
    }
    onEdit(){
        var event = new CustomEvent('edit', {
          detail: {
            ...this.props,
            key: this._reactInternalFiber.key
        }});
        if( this.props.onEdit ) this.props.onEdit( event );
    }
    onDelete(){
        var event = new CustomEvent('delete', {
          detail: {
            ...this.props,
            key: this._reactInternalFiber.key
        }});
        if( this.props.onRemove ) this.props.onRemove( event );
    }

    renderEditable() {
      return <Wrapper onKeyDown={this._keyDown} className={ this.props.selected ? "selected" : "" } color={this.props.color}>
                  <div onClick={()=>{ this.onEdit() }} tabIndex="0" ref="label" dangerouslySetInnerHTML={{__html: this.props.children }} />
                  { !this.props.selected && <button tabIndex="0" ref="deleteBtn" onClick={()=>{ this.onDelete() }} /> }
              </Wrapper>
    }

    render(){
        if (this.props.editable) {
          return this.renderEditable();
        } else {
          return <SimpleLoopButton className={this.props.className} color={this.props.color} onClick={this.onDelete} value={this.props.value}>{this.props.value}</SimpleLoopButton>
        }
    }
}
