import styled from 'styled-components';
import globalStyle from '../../globalStyle';

export const SidebarMenu = styled.nav`
    display: flex;
    padding: 20px 40px;
    justify-content: space-between;
    box-shadow: ${ props => props.color && props.color.material ? "0 0 2px 2px rgba(0, 0, 0, 0.1)": "0 0 5px 2px rgba(0, 0, 0, 0.3)" };
    background-attachment: fixed;
    background-size: cover;

    top: 0;
    z-index: 2;

    transition: all .3s;

    position: fixed;
    top: 0px;
    
    width: 100vw;
    box-sizing: border-box;

    &:after{
        content: "";
        top: 0px;
        left: 0px;
        width: auto;
        height: auto;
        right: 0px;
        bottom: 0px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
        z-index: -1;

        background: ${ props => props.color && props.color.gradient ? props.color.gradient : globalStyle.main };
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    a {
        padding: 0;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        position: relative;
        top: auto;
        padding: 30px;
        width: auto;
        max-width: ${ props => props.color && props.color.material ? "280px": "230px" };
        background: transparent;
        box-shadow: none;

        &:after{
            content: none;
        }

        &.close{
            flex-direction: column;

            a {
                margin: 0 0 20px 0;
            }
        }
    }
`

export const Button = styled.a`
    display: block;
    width: 30px;
    height: 30px;

    overflow: visible;

    transition: all .3s;
    transform: scale( 1, 1 );

    svg {
        content: "";
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 100px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover::before {
        background-color: ${ props => props.color && props.color.material ? "#e0e0e0" : "rgba(255, 255, 255, 0.3)" };
    }

    &::after{
        content: "";
        position: absolute;
        background: url(${ props=> props.color && props.color.material ? "/img/loading.svg" : "/img/loading-neg.svg" });
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        top: -10px;
        left: -10px;
        width: 50px;
        height: 50px;
        z-index: 10;
        opacity: 0;
        transition: all .3s;
    }
    &.loading{
        &::after{
            opacity: 1;
            top: -5px;
            left: -5px;
            width: 40px;
            height: 40px;
        }
    }

    &.btn-menu{ display: none; }
    @media screen and ( ${ globalStyle.desktop } ){
        &.btn-menu{ display: block; }
    }
`

export const SavingAlert = styled.div`
    position: absolute;
    left: 0;
    text-align: center;
    top: 5px;
    font-size: 14px;
    width: 100vw;
    opacity: .75;
    color: ${ props => props.color && props.color.material ? props.color.secondry : "white" };
    white-space: nowrap;

    @media screen and ( ${ globalStyle.desktop } ){
        left: 45px;
        top: 10px;
        font-size: 10px;
        width: auto;
        text-align: left;

        &.menu-close{
            white-space: normal;
            top: 40px;
            text-align: center;
            left: 50%;
            transform: translate( -50%, 0);
        }
    }
`
