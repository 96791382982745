import React, { Component } from 'react';

import { TextInput, Mask } from '../../globalStyle';
import { DateInputWrapper } from './style';

import moment from 'moment';
import 'moment/locale/pt-br';

import DatePicker from './DatePicker';

import translateService from '../../../services/translateService';
let translateDictionary = new translateService();
let t = translateDictionary.getTranslateFunction();


export const DateService = {
    placeholder: "__/__/____",
    detectFormat( input ){
        if( (/^\d{4}-\d{1,2}-\d{1,2}$/).test( input ) ) return "YYYY-MM-DD";
        if( (/^\d{1,2}\/\d{1,2}\/\d{4}$/).test( input ) ) return "DD/MM/YYYY";
        return false;
    },
    toDate( input ){
        if( typeof input !== "string" && typeof input !== "number" ) return input;

        let date = ''
        let format = this.detectFormat( input );

        if(format !== false) {
          date = moment(input, format);
          return date.isValid() ? date : undefined;
        }
    },
    toFormatedDate( value ){
        let date = this.toDate( value );
        return ( date ? date.format("DD/MM/YYYY") : value );
    },
    isValid( value, limInf, limSup ){
        if( !value ) return false;
        value = typeof value === "string" || typeof value === "number" ? value : value.format ? value.format("DD/MM/YYYY") : undefined;
        const date = this.toDate( value );
        return date && date.isValid() && ( !limInf || !limSup || ( this.greaterThan(date, limInf) && this.lowerThan(date, limSup) ) || this.isEqual(date, limSup) || this.isEqual(date, limInf) );
    },
    isEqual( varA, varB ) {
      return this.toDate(varA).valueOf() === this.toDate(varB).valueOf();
    },
    greaterThan( varA, varB ){
        if(!varB) return true
        return this.toDate(varA).valueOf() > this.toDate(varB).valueOf();
    },
    lowerThan( varA, varB ){
        if(!varB) return true
        return this.toDate(varA).valueOf() < this.toDate(varB).valueOf();
    }
}

export default class DateInput extends Component {
    constructor(props){
        super(props);

        const propStartDate = DateService.toDate( this.props.value );

        this.state = {
            masked: propStartDate? "" : DateService.placeholder,
            startDate: propStartDate,
            forcedValue: null
        }

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value && nextProps.value !== this.state.value ) {
          if( !this.isFocused() || nextProps.value === '' || true){
              let forcedValue = nextProps.value ? DateService.toFormatedDate( nextProps.value ) : ''
              let placeholdered = forcedValue ? ( forcedValue + ( nextProps.value.length < DateService.placeholder.length ? (DateService.placeholder).slice(forcedValue.length) : "" ) ) : DateService.placeholder;
              this.setState({
                  forcedValue: forcedValue,
                  masked: placeholdered
              });
          }

          if(nextProps.value !== '' && this.state.forcedValue === '') {
            this.setState({
                forcedValue: false,
                masked: DateService.placeholder
            });
          }
        }
    }

    isOpen() {
      return this.refs.picker.isOpen;
    }

    isFocused() {
      return this.refs.picker.input === document.activeElement
    }

    focus (attr) {
      this.refs.picker.input.focus(attr);
      this.refs.picker.setOpen(false);
    }

    getInput() {
      return this.refs.picker.input
    }

    handleBlur(evt){
      if(this.props.onBlur) {
        this.props.onBlur(evt);
      }
    }

    handleChange(input) {
        if( input && input.target ) input = input.target.value;
        const date = DateService.isValid( input ) ? DateService.toDate( input ) : null ;

        if( date ){
            this.setState({
                startDate: date,
                forcedValue: false
            });
        }

        if( date && input ){
          this.setState({
            masked: ''
          })
        }else{
          let placeholdered = input ? ( input + ( input.length < DateService.placeholder.length ? (DateService.placeholder).slice(input.length) : "" ) ) : DateService.placeholder;

          this.setState({
            masked: placeholdered
          })
        }

        setTimeout(()=>{
            const evt = { target: { value: date && date.isValid() ? date.format('DD/MM/YYYY') : input ? input : "" } };

            if( this.props.onChange ) this.props.onChange( evt );
            //if( this.props.onBlur ) this.props.onBlur( evt );
        },1);
    }

    render(){
        const value = this.state.forcedValue !== false ? this.state.forcedValue : undefined;
        const selected = moment( this.state.startDate, "DD/MM/YYYY" ).isValid() ? moment( this.state.startDate, "DD/MM/YYYY" ) : null ;
        const min = this.props.min ? DateService.toDate( this.props.min ) : undefined ;
        const max = this.props.max ? DateService.toDate( this.props.max ) : undefined ;

        const showSignal = this.props.hasAnswer ? true : false;
        const showErrorSignal = this.props.isInvalid ? true : false;

        moment.updateLocale(translateDictionary.getMomentLang(), {
            week: {
              dow: 1
            },
            weekdaysMin: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
        });

        return <DateInputWrapper color={ this.props.color } tabIndex={this.props.tabIndex} onKeyDown={this.props.onKeyDown}>
                    <DatePicker
                        value={value}

                        ref="picker"
                        placeholderText={this.props.placeholder}
                        dateFormat="DD/MM/YYYY"
                        minDate={min}
                        maxDate={max}
                        customInput={<TextInput color={this.props.color} hasAnswer={showSignal} isInvalid={showErrorSignal} />}
                        selected={selected}
                        onChange={this.handleChange}
                        onChangeRaw={this.handleChange}
                        onBlur={this.handleBlur}
                        onKeyDown={this._keyDown}
                        disabledKeyboardNavigation={true}
                    />
                    { this.props.placeholderMask &&  <Mask>{this.props.placeholderMask}</Mask> }
                </DateInputWrapper>;
    }
}
