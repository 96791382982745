
import { compareTwoStrings } from './StringSimilarity.js'

/* Responsible to verify the result of a condition
 */
class Comparator {
  constructor (condition) {
    this.id = condition.id
    this.value = condition.value
    this.operator = condition.operator
    this.variable_id_ax = condition.variable_id_ax
    this.variable_id_bx = condition.variable_id_bx
  }

  getAx (variables, key) {
    let ax = variables[this.variable_id_ax] ? variables[this.variable_id_ax][key] : undefined

    // if ax is answered, insert it in an array if isn't one
    if (ax !== undefined && ax !== null && ax.value !== undefined) {
      ax = ax.value.raw && ax.value.raw.constructor === Array ? ax.value.raw : [ ax.value.raw ]
    }

    return ax
  }

  getBx (variables, key) {
    let bx = null

    // bx can be provided by value within condition or a variable
    if (this.value !== undefined && this.value !== null) {
      bx = this.value.constructor === Array ? this.value : [ this.value ]
    } else {
      bx = variables[this.variable_id_bx] ? variables[this.variable_id_bx][key] : undefined
      if (bx !== undefined && bx !== null) {
        bx = bx.value.raw && bx.value.raw.constructor === Array ? bx.value.raw : [ bx.value.raw ]
      }
    }

    return bx
  }

  /* Get variables values and test the condition
   */
  test (variables, key) {
    let ax = this.getAx(variables, key)
    let bx = this.getBx(variables, key)

    /* If ax or bx not exists, condition cannot be tested and the result is
     * aways false.
     */
    if(this.operator !== 'is_empty' && this.operator !== 'is_answered') {
      if (!ax || !bx) {
        return [ false ]
      }
    }

    try {
      return this[this.operator](ax, bx)
    } catch (e) {
      if (!this[this.operator]) {
        console.log(Error(`Unknow operator ${this.operator}`));
        throw new Error(`Unknow operator ${this.operator}`)
      } else {
        console.log(Error(`Fail to process condition ${this.id} with operator ${this.operator}.`), e);
        throw new Error(`Fail to process condition ${this.id} with operator ${this.operator}.`)
      }
    }
  }

  // Compare elements one by one using compare function
  _compare_one_by_one (a, b, compare) {
    let result = [ ]

    if((a.length > 1 && b.length > 1) || (a.length === b.length)) {
      for (let i = 0, length = Math.min(a.length, b.length); i < length; i++) {
        result[i] = compare(a[i], b[i])
      }
    } else if(a.length > 1 && b.length === 1){
      a.forEach((item, i) => {
        result[i] = compare(item, b[0])
      })
    } else if(a.length === 1 && b.length > 1) {
      b.forEach((item, i) => {
        result[i] = compare(item, a[0])
      })
    }

    return result
  }

  is_equal_to (a, b) {
    return [this._compare_one_by_one(a, b, (ax, bx) => {
      return ax === bx
    }).find(item => item) === true]
  }

  not_equal_to (a, b) {
    return [!(this._compare_one_by_one(a, b, (ax, bx) => {
      return ax !== bx
    }).find(item => !item) === false)]
  }

  greater_than (a, b) {
    return this._compare_one_by_one(a, b, (ax, bx) => {
      return ax > bx
    })
  }

  less_than (a, b) {
    return this._compare_one_by_one(a, b, (ax, bx) => {
      return ax < bx
    })
  }

  greater_or_equal_to (a, b) {
    return this._compare_one_by_one(a, b, (ax, bx) => {
      return ax >= bx
    })
  }

  smaller_or_equal_to (a, b) {
    return this._compare_one_by_one(a, b, (ax, bx) => {
      return ax <= bx
    })
  }

  is_similar_to (a, b) {
    return this._compare_one_by_one(a, b, (ax, bx) => {
      return compareTwoStrings(ax + "", bx + "") > 0.85
    })
  }

  not_similar_to (a, b) {
    return this._compare_one_by_one(a, b, (ax, bx) => {
      return compareTwoStrings(ax + "", bx + "") <= 0.85
    })
  }

  is_empty (a) {
    if (a !== undefined && a.constructor === Array) {
      return a.length === 0 ? [ true ] : a.map(i => !i || (i && i.length === 0))
    } else {
      return [ true ]
    }
  }

  is_answered (a) {
    if (a !== undefined && a.constructor === Array) {
      return a.length === 0 ? [ false ] : a.map(i => i !== undefined || i !== '')
    } else {
      return [ false ]
    }
  }
}

export default Comparator
