
import MobileDetect from 'mobile-detect'

class MobileDetector {

  static instance = undefined;

  constructor () {
    if (!MobileDetector.instance) {
      this.mobileDetect = new MobileDetect(window.navigator.userAgent);

      // See https://www.npmjs.com/package/mobile-detect for more methods
      let mobile = this.mobileDetect.mobile();
      this.isMobileCache = mobile !== null && mobile !== undefined;
    }

    return MobileDetector.instance
  }

  isMobile() {
    return this.isMobileCache;
  }

  static getInstance() {
    return new MobileDetector()
  }
}

export default MobileDetector.getInstance().isMobile();

export const mobileDetector = MobileDetector.getInstance();
