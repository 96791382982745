import RcSlider from 'rc-slider';
import keyCode from 'rc-util/lib/KeyCode';

const utils = {
  getKeyboardValueMutator(e) {
    switch (e.keyCode) {
      case keyCode.RIGHT:
        return (value, props) => value + props.step;

      case keyCode.LEFT:
        return (value, props) => value - props.step;

      default: return undefined;
    }
  },
  isValueOutOfRange(value, { min, max }) {
    return value < min || value > max;
  }
}

class Slider extends RcSlider {
  componentWillReceiveProps(nextProps) {
    if (!('value' in nextProps || 'min' in nextProps || 'max' in nextProps)) return;

    const prevValue = this.state.value;
    const value = nextProps.value !== undefined ? nextProps.value : prevValue;
    const nextValue = this.trimAlignValue(value, nextProps);
    if (nextValue === prevValue) return;

    this.setState({ value: nextValue });
  }

  onKeyboard(e) {
    const valueMutator = utils.getKeyboardValueMutator(e);

    if (valueMutator) {
      const state = this.state;
      const oldValue = state.value;
      const mutatedValue = valueMutator(oldValue, this.props);
      const value = this.trimAlignValue(mutatedValue);

      e.preventDefault();
      e.stopPropagation();

      if (value === oldValue) return;

      this.onChange({ value });
    }
  }
}

export default Slider;
