import React, { Component } from 'react';

import { KeyboardControlledWrapper } from './style';

class KeyboardControlled extends Component {

  constructor(props) {
    super(props);
    this.keyHandlers = { };

    this._keyDown = this._keyDown.bind(this);
    this.registerKey = this.registerKey.bind(this);
  }

  registerKey(key, handler, preventDefault, stopPropagation) {
    let keys = key.constructor === Array ? key : [key];

    keys.forEach(item => {
      this.keyHandlers = {
        ...this.keyHandlers,
        [item.toLowerCase()]: {
          handler,
          preventDefault,
          stopPropagation
        }
      };
    })
  }

  unregisterKey(key) {
    let keys = key.constructor === Array ? key : [key];

    keys.forEach(item => {
      this.keyHandlers[item.toLowerCase()] = undefined;
    });
  }

  _handle(key, evt) {
    if (this.keyHandlers[key] !== undefined) {
      if (this.keyHandlers[key].preventDefault) {
        evt.preventDefault();
      }

      if (this.keyHandlers[key].stopPropagation) {
        evt.stopPropagation();
      }

      if (this.keyHandlers[key].handler) {
        this.keyHandlers[key].handler(evt);
      }
    }
  }

  _keyDown(evt) {
    let evtKey = evt.key.toLowerCase();

    this._handle(evtKey, evt);
    this._handle('anykey', evt);
  }

  render(children) {
    return <KeyboardControlledWrapper tabIndex="-1" onKeyDown={this._keyDown}>
      { children }
    </KeyboardControlledWrapper>;
  }

}

export default KeyboardControlled;
