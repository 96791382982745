import styled from 'styled-components';

let globalStyle = {
    // media sizes
    desktop: ' min-width: 480px ',
    // material colors
    material: "#7bb342",
    materialSecondary: "#0091ea",
    materialGradient: 'linear-gradient( 0deg, #f5f5f5, #f5f5f5 )',
    materialFavicon: 'favicon-material_9.png',
    materialTitle: '#37474f',
    materialText: '#37474f',
    materialSubText: "#797979",
    // iDox colors
    default: '#9013fe', // purple
    defaultGradient: 'linear-gradient(334deg, #a808e7, #9013fe)',
    defaultFavicon: 'favicon-blue_9.png',
    defaultTitle: 'black',
    defaultText: 'black',
    defaultSubText: "#797979",
    purple: '#9013fe',
    purpleGradient: 'linear-gradient(318deg, #a808e7, #9013fe)',
    purpleFavicon: 'favicon-violet_9.png',
    purpleTitle: 'black',
    purpleText: 'black',
    purpleSubText: "#797979",
    blue: '#1196f3',
    blueGradient: 'linear-gradient(318deg, #11daf3, #097fd2)',
    blueFavicon: 'favicon-blue_9.png',
    blueTitle: 'black',
    blueText: 'black',
    blueSubText: "#797979",
    red: '#d8064d',
    redGradient: 'linear-gradient(318deg, #f85e5e, #d8064d)',
    redFavicon: 'favicon-red_9.png',
    redTitle: 'black',
    redText: 'black',
    redSubText: "#797979",
    green: '#07af5b',
    greenGradient: 'linear-gradient(318deg, #87c229, #07af5b)',
    greenFavicon: 'favicon-green_9.png',
    greenTitle: 'black',
    greenText: 'black',
    greenSubText: "#797979",
    orange: '#ff5c00',
    orangeGradient: 'linear-gradient(318deg, #f79c45, #ff5c00)',
    orangeFavicon: 'favicon-orange_9.png',
    orangeTitle: 'black',
    orangeText: 'black',
    orangeSubText: "#797979",
    // negative
    white: 'white',
    whiteGradient: 'linear-gradient(318deg, #f79c45, #ff5c00)',
    whiteFavicon: 'favicon-blue_9.png',
    whiteTitle: 'black',
    whiteText: 'black',
    whiteSubText: "#797979"
}

export default globalStyle;

export const AppStyle = styled.div`

    h1,
    h2,
    h3,
    ul,
    p {
        margin-bottom: 10px;
    }

    p{
        line-height: 1.5em;
    }

    li {
        position: relative;
        padding-left: 2em;

        &::before {
            content: '\\2022';
            position: absolute;
            left: 0;
            font-size: 2.5em;
            color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        }
    }
`

export const Button = styled.button`
    border: ${ props => props.color && props.color.material ? "1px solid transparent" : "3px solid transparent" };
    outline: none;
    cursor: pointer;

    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;

    min-width: 125px;
    border-radius: ${ props => props.color && props.color.material ? "4px" : "25px" };
    box-shadow: ${ props => props.color && props.color.material ? "box-shadow: inset 0 0px 0 0 #005d95;" :  "0 4px 11px 0 rgba(0, 0, 0, 0.1);"  };
    background-color: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };
    color: white;

    transition: all .3s;

    margin-bottom: 25px;

    &[disabled],
    &[tabindex="-1"]
    &.disabled{
        background-color: #dcdcdc;
        pointer-events: none;
        cursor: default;
    }

    &[square],
    &.square {
      border-radius: ${ props => props.color && props.color.material ? "4px" : "10px" };
      margin-bottom: 0;

      width: 40px;
      min-width: 40px;
      height: 40px;
      padding: 5px;
    }

    &[invert],
    &.invert {
        border: 1px solid #dcdcdc;
        background-color: white;
        box-shadow: ${ props => props.color && props.color.material ? "inset 0 0px 0 0 #005d95;" : "0 4px 11px 0 rgba(0, 0, 0, 0.1);" }
        color: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };
        border-color: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };

        & svg path {
            fill: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };
        }

        &:hover {
            color: white;
            background-color: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };
            border-color: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };

            & svg path {
                fill: white;
            }
        }

        &[disabled],
        &[tabindex="-1"]
        &.disabled{
            border-color: #dcdcdc;
            & svg path {
                fill: #dcdcdc;
            }
        }
    }

    &.ghost {
      border: 1px solid;
      box-shadow: none;
      border-color: white;
      background-color: transparent;
    }

    &:hover{
        color: ${ props => props.color && props.color.buttonHover ? "white" : globalStyle.default };
        background-color: ${ props => props.color && props.color.buttonHover ? props.color.buttonHover : "white" };
        border: ${ props => props.color && props.color.material ? "1px solid transparent" : "3px solid #dcdcdc" };

        ${ props => props.color && props.color.material ? "box-shadow: inset 0 -2px 0 0 #005d95;" : "" }

        & svg path {
          fill: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };
        }
    }

    &:focus{
        border: 3px solid #dcdcdc;
    }

    padding: 5px 15px;
    min-height: 40px;
    font-size: 12px;
    min-width: 100px;

    @media screen and ( ${ globalStyle.desktop } ){
        margin-bottom: 25px;
        padding: 10px 25px;
        min-height: 50px;
        height: 100%;
        font-size: 15px;
        min-width: 125px;

        &.square{
            width: 50px;
            min-width: 50px;
            height: 50px;
            padding: 10px;
        }
    }

`

export const Mask = styled.div`
  position: absolute;
  padding: 19px 21px;
  opacity: 0.25;
  pointer-events: none;

  color: ${ props => props.color && props.color.text ? props.color.text : "inherit" };
`

export const TextInput = styled.input`
    border: solid 1px ${ props => props.hasAnswer && props.isInvalid ? "#d8064d" : "#dcdcdc" };
    outline: none;
    margin: 2px 0;
    max-height: 50px;

    padding: 15px 20px;
    border-radius: ${ props => props.color.material ?
                        props.hasPrefix ? "0 4px 4px 0" : "4px" :
                        props.hasPrefix ? "0 10px 10px 0" : "10px"
                    };

    width: 100%;
    box-sizing: border-box;

    color: ${ props => props.color && props.color.text ? props.color.text : "inherit" };
    background: ${ props => props.color && props.color.text === "white" ? "none" : "white" };

    background-repeat: no-repeat;
    background-position: right 10px center;
    background-image: url(/img/${ props => props.isLoading ? "loading.svg" : props.hasAnswer ? props.isInvalid ? "attention_colored.svg" : "done_colored.svg" : "" });

    transition: background .25s;

    &:focus{
        border: 3px solid ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        margin: 2px -2px 0 -2px;
        background-position: ${ props => !props.isLoading ? 'right -30px center' : 'right 10px center' };
    }

    /* Placeholder */
    &:-ms-input-placeholder{
        color: ${ props => props.color && props.color.subText ? props.color.subText : "inherit" };
    }
    &::-ms-input-placeholder{
        color: ${ props => props.color && props.color.subText ? props.color.subText : "inherit" };
    }
    &::placeholder {
        color: ${ props => props.color && props.color.subText ? props.color.subText : "inherit" };
        opacity: 1; /* Firefox */
    }
`

export const TextArea = styled.textarea`
    border: solid 1px #dcdcdc;
    outline: none;
    margin: 2px 0;

    padding: 10px 20px;
    border-radius: ${ props => props.color.material ? "4px" : "10px" };
    width: 100%;
    box-sizing: border-box;

    color: ${ props => props.color && props.color.text ? props.color.text : "inherit" };
    background: ${ props => props.color && props.color.text === "white" ? "none" : "white" };

    &:focus{
        border: 3px solid ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        margin: 0 -2px;
    }
`

export const ButtonLine = styled.div`
    margin-top: 25px;

    button{
        margin-left: 10px;
        margin-bottom: 0;

        &:first-child{
            margin-left: 0;
        }
    }
`
