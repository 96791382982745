import styled from 'styled-components';
import globalStyle from '../globalStyle';

export const Question = styled.article`
    width: 100%;
    padding-bottom: 10px;

    transition: all .25s;

    opacity: .1;
    pointer-events: none;
    color: ${ props => props.color && props.color.text ? props.color.text : globalStyle.default };

    &:last-child{
        margin-bottom: 0px;
    }

    &.active{
        opacity: 1;
        pointer-events: all;
    }

    &:focus {
        outline: 0;
    }

    li {
        &::before {
            color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        }
    }

    a {
        color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    }

    @media screen and ( ${ globalStyle.desktop } ){
        padding-bottom: 20px;
    }
`

export const Title = styled.h2`
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36em;
    color: ${ props => props.color && props.color.title ? props.color.title : globalStyle.default };

    span{
        margin-left: 5px;
    }
`

export const Description = styled.div`
    font-size: 15px;
    line-height: 1.6em;
    margin-bottom: 10px;
    color: ${ props => props.color && props.color.subText ? props.color.subText : globalStyle.default };

    u {
      text-decoration: underline;
    }

    strong {
      font-weight: bolder;
    }

    em {
      font-style: italic;
    }
`

export const Feedback = styled.div`
    font-size: 12px;
    line-height: 2;
    color: #d8064d;
`

export const Options = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 0;

    counter-reset: option;

    &.itens-1 > label,
    &.itens-2 > label:not(.yes-no),
    &.itens-3 > label { width: calc( 100% - 10px ); }
`

export const Option = styled.label`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: calc( 100% - 10px );
    box-sizing: border-box;
    border: solid 1px #dcdcdc;
    border-color: ${ props => props.color && props.color.material ? "#f5f5f5" : "#dcdcdc" };
    border-radius: ${ props => props.color && props.color.material ? "4px" : "10px" };
    background: ${ props => props.color && props.color.material ? "#f5f5f5": "white" };
    padding: 10px;
    margin: 5px;
    color: #4a4a4a;
    transition: all .3s;

    input{ opacity: 0; position: absolute; }

    &:before{
        counter-increment: option;
        content: counter(option, upper-alpha);
        background-color:  ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        background-image: none;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        color: white;
        border-radius: ${ props => props.color && props.color.material ? "4px" : "10px" };
        height: 36px;
        min-width: 36px;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 18px;
        font-weight: bold;

        transition: all .3s;
    }

    &:after{
        content " ";
        background-color: white;
        background-image: url(/img/loading.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: ${ props => props.isLoading ? "70%" : "cover" };

        position: absolute;
        top: 9px;
        left: 9px;
        border-radius: ${ props => props.isLoading ? "10px" : "50%" };;
        height: 38px;
        width: 38px;
        z-index: 1;

        opacity: ${ props => props.isLoading ? "1" : "0" };

        transition: all .3s;
    }

    &:hover,
    &.focus{
        box-shadow: 0px 0px 0px 2px ${ props => props.color && props.color.button ? props.color.button : globalStyle.default } inset;
        border-color: ${ props => props.color && props.color.button ? props.color.button : globalStyle.default };
    }

    &.single{

        &:before,
        &:after{
            border-radius: 50%;
        }

        &.yes-no {
          &:before { content: attr(data-letter) }
        }
    }

    &.selected{

        &.focus{
            box-shadow: 0px 0px 0px 2px white inset;
        }

        background:  ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        color: white;

        &:before{
            content: " " !important;
            background:  ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
            background-image: url(/img/check-filled.svg);
            color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        }
    }

    @media screen and ( ${ globalStyle.desktop } ){
        width: calc( 50% - 10px );
    }
`

export const InputLine = styled.div`
    position: relative;
    display:flex;
    margin-bottom: 10px;
    min-height: 50px;
    max-height: auto;
    align-items:flex-start;
    align-content:flex-start;
    transition: min-height 0.5s, max-height 0.5s;

    &.bordered{
        border-radius: 10px;
        padding: 5px;
        margin-left: -5px;
        margin-right: -5px;
        background: #f1f1f1;
    }

    * > button{
        margin-top: 2px;
        margin-bottom: 2px;
    }

    &.hide{
        min-height: 0;
        max-height: 0;
    }

    .slider{
        padding: 30px;
        box-shadow: 0 4px 11px 5px rgba(0,0,0,0.1);
        opacity: 1;
        z-index: 3;
        /*position: absolute;*/
        right: 0;
        left: 0;
        background: white;
        border-radius: 10px;
        /*bottom: -85px;*/
        width: 100%;
        overflow: hidden;

        transition: all .5s;

        &.hide{
            opacity: 0;
            pointer-events: none;
            bottom: -65px;
        }
    }
    input {
        flex: 4;
    }
    & > button{
        flex: 1;
        margin-left: 5px;
        margin-right: 5px;

        &:first-child{ margin-left: 0; }
        &:last-child{ margin-right: 0; }
    }

`

export const PlusButton = styled.button`

    border: 3px solid transparent;
    outline: none;
    cursor: pointer;

    font-size: 35px;
    line-height: 1.2em;
    text-align: center;
    text-transform: uppercase;

    height: 50px;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    border-radius: ${ props => props.color && props.color.material ? "4px" : "10px" };
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.1);
    background-color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    color: white;
    box-sizing: border-content;

    transition: all .3s;

    margin-left: 10px;

    &[disabled],
    &[tabindex="-1"]
    &.disabled{
        background-color: #dcdcdc;
        pointer-events: none;
        cursor: default;
    }

    &[square],
    &.square {
      width: 50px;
      padding: 10px;
      border-radius: 10px;
      margin-left: 10px;
    }

    &:hover{
        color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        background-color: white;
        border: 1px solid #dcdcdc;
        box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
    }

    &:focus{
        border: 3px solid #dcdcdc;
    }
`

export const EditConfirmButton = styled(PlusButton)`
    padding-top: 7px;

    font-size: 15px;
        border-radius: ${ props => props.color.material ? "4px 0 0 4px" : "10px 0 0 10px" };

    .colored-fill{
        fill: white !important;
    }

    &:hover{
        .colored-fill{
            fill: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default } !important;
        }
    }
`
export const EditCancelButton = styled(PlusButton)`
    padding-top: 7px;

    font-size: 15px;
    border-radius: ${ props => props.color.material ? "0 4px 4px 0" : "0 10px 10px 0" };
    margin-left: 0;
    border: 1px solid #dcdcdc;

    background-color: white;
    color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };

    .colored-fill{
        fill: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default } !important;
    }

    &:hover{
        color: white;
        background-color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };

        .colored-fill{
            fill: white !important;
        }
    }
`

export const SelectOptions = ( color, complete )=>{
    const themeColor = color.main;
    return {
        container:  styles=>({ ...styles, flex: '1' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                ? null
                : isSelected ? themeColor : isFocused ? themeColor : "white",
                color: isSelected ?  "white" : isFocused ?  "white" : null
            }
        },
        dropdownIndicator:  styles =>({ ...styles, color: themeColor }),
        indicatorSeparator: styles =>({ ...styles, display: "none" }),
        control: ( styles, { data, isDisabled, isFocused, isSelected } ) => ({ ...styles,
                            backgroundColor: "white",
                            borderColor: (isFocused ? themeColor : "#ccc") + " !important",
                            minHeight: "50px",
                            margin: (isFocused ? "0 -2px" : "2px 0px") + " !important",
                            boxShadow: "none",
                            borderWidth: isFocused ? "3px" : "1px",
                            minWidth: '100%',
                            borderRadius: ( complete ? "10px" : "10px 0 0 10px" )
                        }),
        valueContainer: styles => ({ ...styles, padding: "12px 20px"}),
        input: styles => ({ ...styles }),
        placeholder: styles => ({ ...styles }),
        singleValue: (styles, { data }) => ({ ...styles })
    }
}

export const SelectPrefixOptions = ( color, complete )=>{
    const themeColor = color.material ? color.secondary : color.main;
    return {
        container:  styles=>({ ...styles, margin:"2px 0" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                ? null
                : isSelected ? themeColor : isFocused ? themeColor : "white",
                color: isSelected ?  "white" : isFocused ?  "white" : null
            }
        },
        dropdownIndicator:  styles =>({ ...styles, color: "white" }),
        indicatorSeparator: styles =>({ ...styles, display: "none" }),
        control: styles => ({ ...styles,
                            backgroundColor: themeColor,
                            borderColor: themeColor,
                            color: "white",
                            minWidth: '100px',
                            height: "100%",
                            minHeight: "50px",
                            borderRadius: ( complete ? color.material ? "4px" : "10px" :  color.material ? "4px 0 0 4px" : "10px 0 0 10px" )
                        }),
        input: styles => ({ ...styles, color: "white" }),
        placeholder: styles => ({ ...styles, color: "white" }),
        singleValue: (styles, { data }) => ({ ...styles, color: "white" })
    }
}
