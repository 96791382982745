class EventBasic {
    constructor( opts ){
        this.url = opts.url;
        this.task = opts.task;
        this.token = opts.token;
        this.type = opts.type;
        this.callback = opts.callback;
        this.inputs = opts.inputs;
        this.inputTypes = this.getInputTypes( opts.inputTypes );
        this.outputs = opts.outputs;
        this.forcedDelay = 0;
        this.serviceModel = opts.serviceModel;

        this.variableIds = opts.inputs ? Object.values( opts.inputs ) : [];

        // cache input to avoid same values on output
        this._cache = {};

        this.xhr = new XMLHttpRequest();
    }
    getInputTypes( objs ){
        let ret = {};
        for(var key in objs){
            ret[ key.toLocaleLowerCase() ] = objs[key];
        }
        return ret;
    }
    getDataByLabels( objs ){
        let ret = {};
        for(var key in objs){
            ret[ objs[key] ] = { id: objs[key], label: key.toLocaleLowerCase() };
        }
        return ret;
    }
    getInputsValues( answers ){
        let ret = {};
        let data = this.getDataByLabels( this.inputs );
        for(var key in data ){
            const answer = answers[ data[key].id ].raw !== undefined ? answers[ data[key].id ].raw : answers[ data[key].id ] ;
            const inputFormatAllowArray = this.inputTypes[ data[key].label ] !== "ST";
            ret[ data[key].label ] = inputFormatAllowArray ? answer : answer[ answer.length - 1 ];
        }
        this._cache = ret;
        return ret;
    }
    getOutputValues( result ){
        let ret = {};
        let payload = result[ this.task ];
        if( !payload.outputs || JSON.stringify( payload.outputs ) === "{}" ) return ret;
        let outputs = payload.outputs;

        let data = this.getDataByLabels( this.outputs );
        for(var key in data ){
            let value = outputs[ data[key].label ];
            if( value ) ret[ data[key].id ] = value;
        }
        return ret;
    }
    cancel(){
        try{
            this.xhr.abort();
            if( this._onDelay ) clearTimeout( this._onDelay );
        }catch(e){

        }
    }
    check( triggerVarId, varValue, answers ){

        if( this.variableIds.indexOf( triggerVarId ) === -1 ) Promise.resolve("");

        let values = { [triggerVarId]: varValue };
        this.variableIds.forEach((varId)=>{
            if( varId === triggerVarId ) return false;
            if( values !== undefined &&
                answers[ varId ] !== undefined ){
                    values[ varId ] = answers[ varId ];
            }else{
                // break loop
                values = undefined;
            }
        })

        this.cancel();
        return this.send( values );
    }
    send(values){
        if( values !== undefined ){
            this.xhr.abort();
            return new Promise( ( resolve, reject )=>{
                //let fd = new FormData();
                let self = this;
                const data = this.getInputsValues(values);
                //const value = data ? JSON.stringify( data ) : "";

                let fd = JSON.stringify({
                    [ this.task ]: {
                        ...this.serviceModel,
                        "workspace_access_token": this.token,
                        "inputs": data
                    }
                })

                this.xhr.open('post', this.url ) // path da rota no servidor
                this.xhr.setRequestHeader("Content-Type", "application/json");
                this.xhr.setRequestHeader("Accept", "application/json");

                this.xhr.onerror = event => {
                    reject(this) // em caso de erro, rejeitamos a promise
                }
                this.xhr.onreadystatechange =  function(){
                    if (self.xhr.readyState === 4 && self.xhr.status === 200) {

                        try{
                            let data = JSON.parse( self.xhr.responseText );
                            // o envio ocorreu com sucesso
                            if( data && !data.error ){
                                let outputs = self.getOutputValues( data );
                                self.callback( outputs || "" );
                                resolve( data ? self.getOutputValues( data ) : "" ) // resolvemos nossa promise
                            }else if(data.error){
                                throw new Error( data.error || "Undefined error" );
                            }else{
                                throw new Error("Error on data recover");
                            }

                        }catch(e){
                            console.error(e);
                            reject(e);
                        }
                    }

                }

                this._onDelay = setTimeout(()=>{
                    this.xhr.send(fd);
                }, this.forcedDelay);
            });
        }else{
            return Promise.reject("Blank variable yet");
        }
    }
}

class EventAlert extends EventBasic{
    getOutputValues( result ){
        let payload = result[ this.task ];
        return payload && payload.outputs && payload.outputs.message ? payload.outputs.message : "";
    }
}
class EventData extends EventBasic{
    /*getOutputValues( result ){
        return result.result;
    }*/
}
class EventAutocomplete extends EventBasic{
    constructor( opts ){
        super( opts );
        this.forcedDelay = 300;
    }
    getOutputValues( result ){
        let ret = {};
        let payload = result[ this.task ];
        if( !payload || !payload.outputs || JSON.stringify( payload.outputs ) === "{}" ) return ret;
        let outputs = payload.outputs;

        let data = this.getDataByLabels( this.inputs );
        for(var key in data ){
            let value = outputs[ data[key].label ];
            if( value ) ret[ data[key].id ] = value;
        }
        return ret;
    }
}

class EventValidation extends EventBasic{
    constructor( opts ){
        super( opts );
        this.forcedDelay = 525;
    }
    getOutputValues( result ){
        let ret = true;
        let payload = result[ this.task ];
        if( !payload.outputs || JSON.stringify( payload.outputs ) === "{}" ) return ret;
        let outputs = payload.outputs;

        for(var key in outputs ){
            if( key !== "message" ){
                let value = outputs[ key ];
                if( value === false ) ret = false;
            }
        }
        return ret ? "" : outputs.message ;
    }
}

export default class EventsService {
    constructor( opts, defaultCallback ){
        this.url = opts.url;
        this.services = opts.services;
        this.events = [];
        this.eventClasses = {
            "alert": EventAlert,
            "data": EventData,
            "autocomplete": EventAutocomplete,
            "validation": EventValidation
        }
        this.defaultCallback = defaultCallback;
    }
    findEvent( eventName, eventList ){
        let result;
        Object.keys(eventList).forEach((name)=>{
            const event = eventList[ name ];
            if( event.typeEvent === eventName ){
                result = event
            }
        })
        return result
    }
    addEvents( events ){
        if( !events || !Array.isArray( events ) ) return false;

        events.forEach((evt)=>{
            if( !evt.typeEvent ){ console.error("Question event must have typeEvent"); return false; }
            if( !this.services[ evt.taskName ] ){ console.log("Current service is not at servicesLib", evt.taskName, this.services ); return false; }
            let newEvent;

            const service = this.services[ evt.taskName ];

            const opts = {
                url: service.endpoint,
                token: service.workspace_access_token,
                task: evt.taskName,
                type: evt.typeEvent,
                inputs: evt.inputs,
                inputTypes: service.inputs,
                outputs: evt.outputs,
                callback: this.defaultCallback[ evt.typeEvent ] || console.warn,
                serviceModel: service
            }
            if(this.eventClasses[ evt.typeEvent ]){
                newEvent = new ( this.eventClasses[ evt.typeEvent ] )( opts );
            }else{
                newEvent = new EventBasic( opts );
                console.error("Error trying create Event object", evt);
            }
            this.events.push( newEvent );
        });
        return events;
    }
    hasEvent( evtName ){
        return !( !this.events || this.events.length === 0 || !this.events.find( (evt)=>evt.type === evtName ) );
    }
    check( evtName, varId, varValues, answers ){
        if( !navigator.onLine ) return Promise.reject("No connection");
        if( !this.events || this.events.length === 0 ) return Promise.reject('No events');

        const events = this.events.filter( (evt) => evt.type === evtName );
        if( !events || events.length === 0 ){ return Promise.reject('No events of type ' + evtName) }

        const callbackPromises = events.map( evt => evt.check( varId, varValues, answers ) );
        return Promise.all( callbackPromises );
    }
    cancel(){
        const events = this.events;
        if( !events || events.length === 0 ){ return "no events"; }

        events.map(evt=>evt.cancel());
    }
}
