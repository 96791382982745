import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SidebarMenu, Button, SavingAlert } from './style';

import Tooltip from '../../uiElements/tooltip';

import IconList from './IconList';
import IconDashboard from './IconDashboard';
import IconEye from './IconEye';
import IconLater from './IconLater';
import IconBack from './IconBack';
import IconCloudSaved from './IconCloudSaved';

import translateService from '../../../services/translateService';

let translateDictionary = new translateService();
let t = translateDictionary.getTranslateFunction();

function mapStateToProps(state) {
  const { nav, app } = state;
  return { nav, app };
}

const mapDispatchToProps = dispatch => {
  return {
    saveAnwersLocally() {
      return dispatch({
        type: 'SAVE_ANSWERS_LOCALLY',
        payload: {}
      });
    }
  };
};

class InterviewNavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.saveAnwers = this.saveAnwers.bind(this);
  }

  componentDidUpdate() {
    if (this.props.app.autoSaving || this.state.saving) {
      this.updateAutoSaveInfo();
    }
  }

  saveAnwers(e) {
    e.preventDefault();
    this.props.saveAnwersLocally();
  }

  updateAutoSaveInfo() {
    if (this.props.app.autoSaving && !this.state.saving) {
      this.setState({
        savingText: 'saving...',
        saving: true,
        saveTime: new Date().getTime()
      });
    } else if (!this.props.app.autoSaving && this.state.saving) {
      const timeDiff = new Date().getTime() - this.state.saveTime;
      const animRemainTime = Math.max(timeDiff, 2000);

      setTimeout(() => {
        let message = this.props.app.saveError
          ? 'fail to save interview, try again'
          : 'answer saved!';
        this.setState({ savingText: message, saving: false, saveTime: null });

        this.autosaveCloseDelay = setTimeout(() => {
          this.setState({ saving: false, savingText: false, saveTime: null });
        }, 2500);
      }, animRemainTime);
    }
  }

  getTippyPopperOptions() {
    let options = {
      modifiers: {
        hide: { enabled: false },
        flip: { enabled: false },
        preventOverflow: { enabled: false }
      }
    };

    if (this.props.close) {
      options.modifiers = {
        ...options.modifiers,
        arrow: { enabled: true, order: 10 },
        keepTogether: { enabled: true, order: 5 }
      };
    }

    return options;
  }

  render() {
    const dashboardActive = this.props.activeTab === 'dashboard';

    const closed = this.props.close;
    const tooltipOffset = closed ? -10 : 0;
    const tooltipPosition = closed ? 'right' : 'bottom';
    const tooltipPooperOption = this.getTippyPopperOptions();

    const showPreview = false;

    return (
      <SidebarMenu className={closed ? 'close' : ''} color={this.props.color}>
        {/* this.props.color.material &&
                      <Button className="btn-site" href="https://www.jusbrasil.com.br/home">
                        <IconLogo></IconLogo>
                      </Button>
                    */}
        <Button
          className="btn-menu"
          href="#menu"
          onClick={e => {
            e.preventDefault();
            if (this.props.onMenuToggle) this.props.onMenuToggle();
          }}
          color={this.props.color}
        >
          <IconList color={this.props.color} />
        </Button>
        {showPreview && (
          <Tooltip
            key={`btn-preview-${tooltipPosition}`}
            offset={tooltipOffset}
            distance={15}
            position={tooltipPosition}
            title={t('preview')}
            arrow={true}
            popperOptions={tooltipPooperOption}
          >
            <Button
              className="btn-preview"
              href="#preview"
              onClick={e => {
                e.preventDefault();
                if (this.props.onShowPreview) this.props.onShowPreview();
              }}
              color={this.props.color}
            >
              <IconEye color={this.props.color} />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          key={`btn-dashboard-${tooltipPosition}`}
          offset={tooltipOffset}
          distance={15}
          position={tooltipPosition}
          title={dashboardActive ? t('current section') : t('sections')}
          arrow={true}
          popperOptions={tooltipPooperOption}
        >
          <Button
            className={'btn-dashboard ' + (dashboardActive ? 'active' : '')}
            href="#dashboard"
            onClick={e => {
              e.preventDefault();
              if (this.props.onShowDashboard) this.props.onShowDashboard();
            }}
            color={this.props.color}
          >
            <IconDashboard active={dashboardActive} color={this.props.color} />
          </Button>
        </Tooltip>
        {this.props.app.finishLater ? (
          <Tooltip
            key={`btn-later-${tooltipPosition}`}
            offset={tooltipOffset}
            distance={15}
            position={tooltipPosition}
            title={t('finish later')}
            arrow={true}
            popperOptions={tooltipPooperOption}
          >
            <Button
              className="btn-later"
              href="#later"
              onClick={e => {
                e.preventDefault();
                if (this.props.onContinueLater) this.props.onContinueLater();
              }}
              color={this.props.color}
            >
              <IconLater color={this.props.color} />
            </Button>
          </Tooltip>
        ) : null}

        {this.props.app.apiRouters.abort_url && (
          <Tooltip
            key={`btn-back-${tooltipPosition}`}
            offset={tooltipOffset}
            distance={15}
            position={tooltipPosition}
            title={t('back')}
            arrow={true}
            style={{ position: 'relative' }}
            popperOptions={tooltipPooperOption}
          >
            <Button
              target="_self"
              className="btn-back"
              href={`${this.props.app.apiRouters.abort_url.url}/${
                this.props.app.apiRouters.abort_url.route
              }`}
              color={this.props.color}
            >
              <IconBack color={this.props.color} />
            </Button>
          </Tooltip>
        )}

        <Tooltip
          key={`btn-send-${tooltipPosition}`}
          offset={tooltipOffset}
          distance={15}
          position={tooltipPosition}
          title={t('save')}
          arrow={true}
          style={{ position: 'relative' }}
          popperOptions={tooltipPooperOption}
        >
          <Button
            className={'btn-send ' + (this.state.saving ? 'loading' : '')}
            href="#send"
            onClick={this.saveAnwers}
            color={this.props.color}
          >
            <IconCloudSaved
              saving={this.state.saving}
              color={this.props.color}
            />
            <SavingAlert
              className={this.props.close ? 'menu-close' : ''}
              color={this.props.color}
            >
              {this.state.savingText ? t(this.state.savingText) : ''}
            </SavingAlert>
          </Button>
        </Tooltip>
      </SidebarMenu>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewNavMenu);
