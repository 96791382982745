
import CompilerInterface from './CompilerInterface.js'

import Comparator from '../Comparator.js'

export default class RuleCompiler extends CompilerInterface {

  /* Get given Rule object and transform to an format recognizable to _parse
   * method.
   */
  _tokenize ({ condition }) {
    let tokens = [ ]

    // Token identifiers
    let isOperator = (condition) => /(and|or)/.test(condition.operator)
    let isCondition = (condition) => condition.variable_id_ax !== undefined
    let isUnaryOperator = (condition) => !(/(and|or)/.test(condition.operator))

    // Conversion table to translate spell boolean operators to logical oprators
    let spellToLogicalOperator = (operator) => {
      let conversionTable = {
        'or': '|',
        'and': '&',
        'not': '!'
      }

      return conversionTable[operator]
    }

    // For each char of expression...
    condition.forEach((item) => {
      // If is operator, add as operator
      if (isOperator(item)) {
        tokens.push({
          type: 'op',
          value: spellToLogicalOperator(item.operator),
          unary: isUnaryOperator(item) ? true : false
        })
      }
      // If is condition object, add it as an id (aka operand)
      else if (isCondition(item)) {
		    tokens.push({
          type: 'id',
          value: item
        })
      }
      // Anything else isn't recognized by this specific language grammar
      else {
        throw new Error('Unknow token')
      }
    })

    return tokens
  }

  /* Populate abstract syntax tree (AST) using references to comparators id.
   */
  _populateAst(ast) {
    // Populate left childs
    if (ast[0]) {
      this._populateAst(ast[0])
    }
    // Populate right childs
    if (ast[2]) {
      this._populateAst(ast[2])
    }

    // If actual node is op, set it's value, else insert comparator object.
    if (ast[1].type === 'op') {
      ast[1] = ast[1].value
    } else if(ast[1].type === 'id') {
      ast[1] = new Comparator(ast[1].value)
    } else {
      throw new Error('Invalid node in abstract syntax tree')
    }
  }

  /* Generates evaluable object based on abstract syntax tree (AST) generated by
   * parser.
   */
  _generateEvaluable (ast) {
    this._populateAst(ast)
    return ast
  }
}
