import styled from 'styled-components';
import globalStyle from '../../globalStyle';

export const DateInputWrapper = styled.div`

    width: 100%;
    display: flex;

    & > div,
    .react-datepicker-wrapper, 
    .react-datepicker-wrapper div {
        display: flex;
        width: 100%;
    }

    .react-datepicker-popper{
        z-index: 2;
    }

    .react-datepicker{
        border: none;
        border-radius: ${ props => props.color.material ? "4px" : "10px" };
        overflow: hidden;
        background: white;
        box-shadow: 0 4px 11px 5px rgba(0,0,0,0.1);
    }

    .react-datepicker__triangle{
        display: none;
    }

    .react-datepicker__navigation{
        top: 12px;
        height: 18px;
        width: 18px;
    }

    .react-datepicker__navigation--next{
        border: none;
        margin: 0;
        background: url(/img/arrow-right.svg) no-repeat center;
        background-size: contain;
    }

    .react-datepicker__navigation--previous{
        border: none;
        margin: 0;
        background: url(/img/arrow-left.svg) no-repeat center;
        background-size: contain;
    }

    .react-datepicker__header{
        color: white;
        background: ${ props => props.color && props.color.material ? props.color.secondary : props.color.main ? props.color.main : globalStyle.default };
        border: none;
        padding-top: 11px;
    }

    .react-datepicker__current-month, .react-datepicker-time__header{
        color: white;
        padding-bottom: 12px;
        font-size: 16px;
        font-weight: normal;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }

    .react-datepicker__day-names{
        background: white;
        border-bottom: none;
        font-weight: bolder;
        font-size: 12px;
    }

    .react-datepicker__day--outside-month{
        color: #656565;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__day--selected:hover{
        border-radius: 50%;
        color: white;
        background: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    }


`
