import styled from 'styled-components';
import globalStyle from '../../globalStyle';

export const OkWrapper = styled.div`
    opacity: ${ props => props.open ? "1" : "0" };
    pointer-events: ${ props => props.open ? "auto" : "none" };
    transition: all 1s;
    overflow: hidden;
    text-align: center;
    padding-bottom: 15px;

    button {
      margin: 0px;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        text-align: left;
        padding-bottom: 20px;
    }
`

export const Label = styled.span`
    margin-left: 12px;

    strong{
        color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    }

    &.disabled{
        color: #dcdcdc;

        strong{
            color: #dcdcdc;
        }
    }
`

export const QuestionWrapperDOM = styled.div`
    padding-bottom: 0px;
    &:focus {
      outline: 0;
    }
`
