import React from 'react';
import QuestionBasic, {t, Actions} from '../../questions';
import {connect} from 'react-redux';

import { Question, Title, Description } from '../style';
import { Button, TextInput } from '../../globalStyle';


function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

function mapDispatchToProps(dispatch){
    return {
        ...Actions(dispatch)
    }
}


class QuestionDefault extends QuestionBasic {

    constructor(props){
        super(props);

        let index = this.props.index ? this.props.index : 0;
        let answers = this.props.interview.answers[ this.props.id ];

        // Set default values
        let propValue = undefined;
        let propTouched = false;

        // If question at actual index has answers, update values
        if (answers && answers[index]) {
          propValue = answers[ index ].value.value;
          propTouched = propValue !== undefined;
        }

        this.state.showJSON = false;
        this.state.value = propValue && this.inputType() !== 'file' ? propValue : '';
        this.state.touched = propTouched;
    }

    toggleJSON(){ this.setState({ showJSON: !this.state.showJSON }); }

    onBlur(){
        this.setState({ touched: true });
    }

    toJSON(){
        var cleanedProps = JSON.parse( JSON.stringify( this.props ) );
        delete cleanedProps.interview;
        return JSON.stringify( cleanedProps, null, 4 );
    }

    render(){
        const value = this.state.values ? this.state.values[0] : '';
        return <Question className={ this.props.active ? "active" : "" } color={this.props.color} id={this.props.navId}>
                    <Title>{this.props.label}{ this.props.mandatory ? "*" : "" }</Title>
                    <Description color={this.props.color}>{this.props.question}</Description>
                    <div><TextInput ref="input" color={this.props.color} placeholder={t("Question value")} type={this.inputType()} value={value} onBlur={(evt)=>{ this.onBlur() }} onChange={(evt)=>{this.onChangeInputValue(evt); return false; }} /></div>
                    { this.renderAlert() }
                    <pre>{this.props.type}</pre>
                    { this.state.showJSON && <pre>{this.toJSON()}</pre> }
                    <div><Button color={this.props.color} onClick={ ()=>{ this.toggleJSON(); } }>Toggle JSON</Button></div>
                </Question>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDefault);
