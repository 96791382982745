import React from 'react';
import QuestionBasic, { Actions } from '../../questions';
import {connect} from 'react-redux';

import { Question } from '../style';


function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
        ...Actions(dispatch)
    }
}

class QuestionMessage extends QuestionBasic{
    constructor(props){
        super(props, []);
        this.state.status = true;

        this.registerKey('Tab', (evt) => {
          if(evt.shiftKey) {
            evt.stopPropagation()

            if(!this.hasParent()) {
              evt.preventDefault()
            }
          }
        }, false, false );
    }
    render(){
        return <Question ref="input" tabIndex="0" onKeyDown={this._keyDown} onFocus={this.onFocus} className={ this.props.active ? "active" : "" } color={this.props.color} id={this.props.navId}>
            { this.renderHeader() }
        </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionMessage);
