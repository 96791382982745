class DictionaryService {
    static instance = undefined;

    constructor () {
      if (!DictionaryService.instance) {
        this.notFoundTerms = {};
        this.langs = {};
        this.currentLang = '';
        DictionaryService.instance = this;

        let showNotFoundTerms = function(){
            let result = {};
            let keys = Object.keys(this.notFoundTerms);
            keys.forEach((key)=>{
                const term = this.notFoundTerms[ key ];
                result[ term.id ] = term.term;
            });
            return JSON.stringify(result ,null ,'\t');
        }
        window.showNotFoundTerms = showNotFoundTerms.bind(this);

      }

      return DictionaryService.instance
    }

    standardizeLang(lang) {
      return lang.toLowerCase().replace(/[^a-z]/,'');
    }

    slugfy( term ){
        return term.toLowerCase().replace(/[^a-z0-9]/gi,"-").replace(/-+/gi,"-").replace(/ˆ-*/gi,"").replace(/-*?/gi,"");
    }

    addLanguageTerms( terms, lang = 'ptbr' ){
        lang = this.standardizeLang(lang);
        if( this.langs[ lang ] === undefined ) this.langs[lang] = {};
        let termKeys = Object.keys(terms);
        termKeys.forEach( termKey => {
            const value = terms[ termKey ];
            this.langs[ lang ][ termKey ] = value;
        });
    }

    setLanguage( lang ){
        if( !lang ) return false;
        lang = this.standardizeLang(lang);
        let subLang;
        // check if lang exists
        if( this.langs[ lang ] !== undefined ){ this.currentLang = lang; }
        // check if general lang exists: enus => en
        subLang = lang.length > 2 ? lang.slice(0,2) : lang;
        if( this.langs[ subLang ] !== undefined ){ this.currentLang = subLang; }
        // check if a located lang exists: en === enus or enau or engk
        let langCodes = Object.keys(this.langs);
        let generalLang = langCodes.find((langCode)=>langCode.startsWith( lang ));
        if( this.langs[ generalLang ] !== undefined ){ this.currentLang = generalLang; }
    }

    init(langs, dfLang) {
      Object.keys(langs).forEach(lang => {
        this.addLanguageTerms(langs[lang], lang);
      });
      this.setLanguage(dfLang);
    }

    getTranslate( term, ...vars ){
        if( !term || typeof term !== 'string' ) return ( term || '' ).toString();
        const key = this.slugfy( term );
        let result = term;
        if( this.langs[ this.currentLang ] && this.langs[ this.currentLang ][ key ] ){
            result = this.langs[ this.currentLang ][ key ];
        }else{
            if( !this.notFoundTerms[ key ] ) console.warn("Term not found, please translate",  {
                id: key,
                term: term,
                vars: vars,
                lang: this.currentLang
            });
            this.notFoundTerms[ key ] = {
                id: key,
                term: term,
                vars: vars
            };
        }
        if( vars.length ) vars.forEach((varSel, i)=>{ result = result.replace( new RegExp( '\\?' + i, 'gi'), this.getTranslate( varSel ) );  });
        return result;
    }
    getMomentLang(){
      let lang = this.currentLang.slice(0,2);

      if(lang === "pt") return "pt-br";

      return lang;
    }

    getTranslateFunction(){
        let tFunction = this.getTranslate.bind( this );
        return tFunction;
    }

    static getInstance() {
      return new DictionaryService();
    }
}

export default DictionaryService;
