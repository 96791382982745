export class UploaderPromise {
    constructor(opts){
        this.defaultAWSOpts = {
            acl: 'public-read',
            targetFilename: null,
            bucket: 'idox-files-br',
            folder: 'upload/temp/'
        }
        this.xhr = new XMLHttpRequest();
        this.name = undefined;
        this.size = undefined;
        this.type = undefined;
        this.fileUrl = undefined;
        this.serverName = undefined;
        this.promise = new Promise((resolve, reject) => {});
        if( opts.file ) this.upload( opts );
    }
    randomString(length) {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

        return result;
    }
    slugfy (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
        // eslint-disable-next-line
        str = str.replace(/[^a-z0-9 -\.]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
    getAWSoptions(url){
        return fetch(url).then(function(response) {
                    return response.json();
                })
                .then(function(AWSOptions) {
                    return AWSOptions
                });
    }
    sendFileToASW(opts){
        return new Promise(( resolve, reject )=>{
            let file = opts.file;
            let path = 'https://' + opts.bucket + '.s3.amazonaws.com/'; // path da rota no servidor

            // key is the filename at server
            this.serverName =  opts.targetFilename ? opts.targetFilename : ( opts.keyStart || opts.folder ) + opts.file.name.split('.')[0] + '-' + (new Date()).getTime() + '-' + this.randomString(16) + "." + opts.file.name.split('.').pop();
            this.fileUrl = path + this.slugfy(this.serverName);

            let fd = new FormData();
            fd.append('key', this.slugfy(this.serverName));
            fd.append('acl', opts.acl);
            fd.append('Content-Type', file.type);
            fd.append('AWSAccessKeyId', opts.AWSAccessKeyId);
            fd.append('policy', opts.policy);
            fd.append('signature', opts.signature);
            fd.append("file", file);

            this.xhr.open('post', path);

            this.xhr.onerror = event => {
                console.error( this.xhr.status, this.xhr.statusText );
                reject( this.xhr.statusText ) // em caso de erro, rejeitamos a promise
            }
            this.xhr.onload = event => {
                // o envio ocorreu com sucesso
                if( this.xhr.status < 400 ){
                    resolve( this ) // resolvemos nossa promise
                }else{
                    reject( this.xhr.statusText ) // em caso de erro, rejeitamos a promise
                }
            }

            if( this.xhr.upload ) {
                // caso tenhamos acesso a esta informação
                this.xhr.upload.onprogress = progress => {
                    this.onProgress( Math.round((progress.loaded * 100) / progress.total) );
                }
            } else {
                this.onProgress( undefined );
            }

            this.xhr.send(fd) // iniciando a requisição, enviando o FormData
        })
    }
    upload( opts ){
        this.name = opts.file.name;
        this.size = opts.file.size;
        this.type = opts.file.type;
        this.promise = new Promise(( resolve, reject )=>{
            this.getAWSoptions( opts.url ).then(( AWSOptions )=>{
                let sendOpts = { ...this.defaultAWSOpts, ...opts, ...AWSOptions, ...AWSOptions.params };
                this.sendFileToASW( sendOpts ).then(()=>{
                    resolve();
                }).catch((e)=>{
                    reject(e);
                });
            }).catch((e)=>{
                reject(e);
            });
        });

        return this.promise;
    }
    cancel(){
        if( this.xhr && this.xhr.readyState < 2 ){
            try{
                this.xhr.abort();
                this.onFail("Upload canceled");
                this.promise.reject("Upload canceled");
            }catch(e){

            }
        }
    }
    onProgress(){}
    onFail(){}
    onSuccess(){}
    remove(){
        this.cancel();
        this.onRemove()
    }
    onRemove(){}
}

class fileUploadService {

    constructor( opts ){
        this.optionsURL = opts ? opts.url : { url: 'http://www.themartians.dk/idox/'};
        this.promises = [];
    }
    config(opts){
        this.optionsURL = opts && opts.url ? opts.url : this.optionsURL ;
    }
    remove( uploaderPromise ){
        this.promises = this.promises.filter((prom)=>{ return prom.name !== uploaderPromise.name });
    }
    upload( files ){
        files = Array.isArray( files ) ? files : [ files ] ;
        const promises = files.map((file)=>{
            const opts = {
                url: this.optionsURL,
                file: file
            }
            const uploaderPromise = new UploaderPromise(opts);
            uploaderPromise.promise.then(()=>{
                this.remove( uploaderPromise );
                uploaderPromise.onSuccess();
            }).catch(( error )=>{
                const errorMsg = ( error.message || error.toString() || "Connection error" );
                console.error( errorMsg );
                this.remove( uploaderPromise );
                uploaderPromise.onFail( errorMsg );
            });
            uploaderPromise.onRemove = ()=>{
                this.remove( uploaderPromise );
            }
            return uploaderPromise;
        });
        this.promises = this.promises.concat( promises );
        return promises;
    }
    getAll(){
        return this.promises
    }
}
export default new fileUploadService();
