
//https://react-select.com/
import Select from 'react-select/lib/Creatable';

class SelectInput extends Select {
  focus(attr) {
    this.select.select.focus(attr);
  }

  isOpen() {
    return this.state.menuIsOpen;
  }
}

export default SelectInput;
