import { Component } from 'react';

export default class Favicon extends Component {
    componentDidMount(){
        this.updateFavicon();
    }
    componentDidUpdate(){
        this.updateFavicon();
        this.updateThemeColor();
    }
    updateThemeColor(){
        this.themeColor = document.querySelectorAll("meta[name='theme-color']");
        // change all icons;
        this.themeColor.forEach((elem)=>{
            elem.setAttribute( 'content', this.props.color.main );
        });
    }
    updateFavicon(){
        this.favicon = document.querySelectorAll("link[rel*='icon']");
        // change all icons;
        this.favicon.forEach((elem)=>{
            let baseUrl = elem.href.split("/").slice(0,-1).join("/") + "/";
            elem.href = baseUrl + this.props.color.icon;
        });
    }
    render(){
        this.updateFavicon();
        return ""//`<!-- favicon ${ this.props.color.icon } -->`;
    }
}
