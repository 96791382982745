import React from 'react';

import KeyboardControlled from '../keyboardControlled';

import TutorialService from '../../services/tutorialService';

import TutorialTooltip from './tooltip';

class Tutorial extends KeyboardControlled {

    constructor(props, keys, active, type, placement, text){
        super(props);

        this._type = type;
        this._active = active;
        this._placement = placement;
        this._tutorialKeys = keys ? keys : [ ];
        this._id = props.id ? props.id : Math.floor(Math.random() * 10000);
        this.service = TutorialService.getInstance();
        this._tutorialText = text ? text : 'use-keyboard-to-select-item';
        this.random = props.id ? false : true

        this.state = {closed: false};

        this._canShow = this._canShow.bind(this);
        this.hideTutorial = this.hideTutorial.bind(this);
        this.showTutorial = this.showTutorial.bind(this);
        this._onShowTutorial = this._onShowTutorial.bind(this);
        this.renderWithTutorial = this.renderWithTutorial.bind(this);
    }

    componentDidMount() {
      this.showTutorial();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      this.showTutorial();
    }

    componentWillUnmount(){
      //this.hideTutorial();
      if( this.autocloseDelay ){
        clearTimeout( this.autocloseDelay );
      }
    }

    isActive() {
      return this._active !== undefined ? this._active : this.props.active;
    }

    isFocused() {
      return this._focused !== undefined || this.random ? this._focused : this.props.focused;
    }

    _getType() {
      return this._type !== undefined ? this._type : this.props.type;
    }

    _getId() {
      return this._id;
    }

    _registerKeyboardEvent(){
      if(this._canShow()){
        this.registerKey('AnyKey', () => {
          this.hideTutorial();
        }, false, false);
      }
    }

    setTutorialKeys(keys) {
      this._tutorialKeys = keys ? keys : [ ];
      this._registerKeyboardEvent();
    }

    _canShow() {
      return  this._tutorialKeys.length > 0 &&
              ((this.isFocused() && this.isActive()) || this.random) &&
              this._getType() !== 'COMPLEX' &&
              this.service.canShow(this._getType());
    }

    _onShowTutorial() {
      if(this._canShow()) {
        this.setState({closed: false})
        this._registerKeyboardEvent();
      } else {
        this.hideTutorial();
        this.unregisterKey('AnyKey');
      }
    }

    showTutorial() {

      if(this.service.getView(this._type) === true) return;

      if(((this.isFocused() && this.isActive()) || this.random) && this._tutorialKeys.length > 0) {
        this.service.registerTip(this._getId(), this._getType(), this._onShowTutorial, this.random ? 2000 : 500);

        // autoclose Tutorial
        if( this.autocloseDelay ) clearTimeout( this.autocloseDelay );
        this.autocloseDelay = setTimeout(()=>{
          this.hideTutorial();
        }, 10000 );
      } else {
        //this.hideTutorial();
      }
    }

    hideTutorial(doFocus) {
      if(!this.state.closed){
        this.setState({closed: true});
      }
      this.service.onClose(this._getId());

      if(this.focus && doFocus === true) {
        setTimeout(() => {
          this.focus()
        }, 100)
      }
    }

    renderInfo() {
      if(!this.service.disabled) {
        return <TutorialTooltip
                        info={true}
                        color={this.props.color}
                        position={ this._placement ? this._placement : 'top-start' }
                        keys={this._tutorialKeys}
                        onHide={() => {this.hideTutorial(true)}}
                        text={this._tutorialText}>
                </TutorialTooltip>
      } else {
        return ""
      }
    }

    renderWithTutorial(children){
        const isOpen = this._canShow() && !this.state.closed
        return  <TutorialTooltip
                        color={this.props.color}
                        position={ this._placement ? this._placement : 'top-start' }
                        open={isOpen}
                        keys={this._tutorialKeys}
                        onHide={this.hideTutorial}
                        text={this._tutorialText}>
                  <div onClick={this.hideTutorial} ref="childContent">
                    { children }
                  </div>
                </TutorialTooltip>
    }
}

export default Tutorial;
