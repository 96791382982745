
import API from '../../services/ApiService'
import navService from '../../services/navService'
import navFlowService from '../../services/navFlowService';

export const interviewMiddleware = store => next => action => {
    let currentLibState;

    switch (action.type) {
        case 'CHECK_HASHVALIDATION':
            API.remote.validateHash( action.payload )
                .then((data)=>{
                    store.dispatch({
                        type: "UPDATE_INTERVIEW",
                        payload: data
                    });
                    store.dispatch({
                        type: "UPDATE_HASHVALIDATION",
                        payload: true
                    });
                    store.dispatch({
                        type: "UPDATE_HASH",
                        payload: action.payload
                    });

                    store.dispatch({ type: "UPDATE_LOCAL_ANSWERS_FLAGS", payload: {hash: action.payload} });
                    store.dispatch({ type: "UPDATE_INIT_FLAG" });
                })
                .catch((error) =>{
                    store.dispatch({
                        type: "UPDATE_HASHVALIDATION",
                        payload: false
                    });
                });
            break;

        case 'UPDATE_INIT_FLAG': {
          currentLibState = store.getState();
          let isInit = currentLibState.app.localAnswersLoaded && currentLibState.app.hashValidated;

          if(isInit) {
            store.dispatch({
                type: "UPDATE_NAV",
                payload: {
                    interview: currentLibState.interview.interview,
                    blocks: currentLibState.interview.blocks,
                    variables: currentLibState.interview.variables,
                    conditions: currentLibState.interview.conditions,
                    answers: currentLibState.interview.answers,
                    goToFirstQuestion: true
                }
            });
          }

          store.dispatch({
            type: "UPDATE_INIT",
            payload: isInit
          });
          break;
        }

        case 'UPDATE_LOCAL_ANSWERS_FLAGS': {
          currentLibState = store.getState();
          let hasLocalAnswers = API.local.hasLocalAnswers(action.payload.hash);
          let localAnswersLoaded = action.payload.loaded || !hasLocalAnswers;

          store.dispatch({ type: 'UPDATE_HAS_LOCAL_ANSWERS', payload: hasLocalAnswers });
          store.dispatch({ type: 'UPDATE_LOCAL_ANSWERS_LOADED', payload: localAnswersLoaded });
          break;
        }

        case "UPDATE_LOCAL_ANSWERS_LOADED":
          next(action);
          store.dispatch({ type: "UPDATE_INIT_FLAG" });
          break;

        case "CLEAR_LOCAL_ANSWERS":
          API.local.clearAnswers(action.payload.hash);
          break;

        case 'UPDATE_NAV':
            next(action);

            if(action.payload.goToFirstQuestion) {
              store.dispatch({ type: "NAV_FIRST_VARIABLE", payload: {} });
            } else {
              let url = store.getState().router.location.pathname;
              store.dispatch({ type: "NAV_GOTO_BY_PATHNAME", payload: { url } });
            }

            break;

        case 'SET_USER_INFO':
          next(action);
          store.dispatch({ type: "NAV_FIRST_VARIABLE", payload: { }});
          break;

        case 'SET_ANSWER':
            next(action);
            currentLibState = store.getState();
            // create update object
            let {
                blocks,
                variables,
                conditions,
                answers
            } = currentLibState.interview;
            const flow = navFlowService( variables, blocks, conditions, answers);
            const currentNavTree = flow.getNavTree();

            let navPayload = {
                blocks,
                variables,
                conditions,
                answers,
                currentNavTree
            }
            // update current stateObj locally
            currentLibState.interview = Object.assign( currentLibState.interview, navPayload );
            currentLibState.nav = Object.assign( currentLibState.nav, { currentNavTree } );

            navPayload = Object.assign( navPayload, navService.processNextPrevious( currentLibState.nav, currentLibState.interview ) );
            store.dispatch({ type: "UPDATE_NAV_VARS", payload: navPayload });

            // let the system save
            setTimeout(()=>{
                store.dispatch({ type: "SAVE_ANSWERS_LOCALLY", payload: { }});
            }, 100);

            break;

        case "LOAD_LOCAL_ANSWERS":
            store.dispatch({
              type: "SET_ANSWERS",
              payload: API.local.loadAnswers(store.getState().app.hash).content
            });
            currentLibState = store.getState();
            store.dispatch({
              type: "UPDATE_LOCAL_ANSWERS_FLAGS",
              payload: {
                hash: currentLibState.app.hash,
                loaded: true
              }
            });
            store.dispatch({ type: "UPDATE_INIT_FLAG", payload: { } });
            API.local.clearAnswers(store.getState().app.hash);
            break;

        case "DONE_LATER":
        case "SUBMIT_INTERVIEW":
          store.dispatch({ type: "UPDATE_SAVING_ANSWERS_STATE", payload: { saving: true, error: false }});
          currentLibState = store.getState();
          API.storeAnswers(currentLibState.app.hash,
                           currentLibState.interview.variables,
                           currentLibState.interview.answers,
                           undefined, //user data
                           currentLibState.nav.interviewFinished && action.type === 'SUBMIT_INTERVIEW'
                          )
            .then((data) => {
              if (data && data.url) {
                store.dispatch({ type: "SET_REDIRECT_URL", payload: data.url });
              }

              if(currentLibState.nav.interviewFinished && action.type === 'SUBMIT_INTERVIEW') {
                store.dispatch({ type: 'CLEAR_LOCAL_ANSWERS', payload: { hash: currentLibState.app.hash } });
              }

              setTimeout(() => {
                store.dispatch({ type: "UPDATE_SAVING_ANSWERS_STATE", payload: { saving: false }});
              }, 20);
            })
            .catch((e) => {
              setTimeout(() => {
                store.dispatch({ type: "UPDATE_SAVING_ANSWERS_STATE", payload: { saving: false, error: true }});
              }, 20);
            })
          break;

        case "SAVE_ANSWERS_LOCALLY":
          store.dispatch({ type: "UPDATE_SAVING_ANSWERS_STATE", payload: { auto: true, error: false }});
          try {
            API.local.storeAnswers(store.getState().app.hash,
                                   store.getState().interview.answers);
            setTimeout(() => {
              store.dispatch({
                type: "UPDATE_SAVING_ANSWERS_STATE",
                payload: { auto: false }
              });
            }, 10);
          } catch(e) {
            store.dispatch({
              type: "UPDATE_SAVING_ANSWERS_STATE",
              payload: { auto: false, error: true }
            });
          }/**/
          break;

        case "DROP_COMPLEX_INDEX":
          next(action);
          setTimeout(() => {
            currentLibState = store.getState().interview;
            store.dispatch({
                type: "UPDATE_NAV",
                payload: {
                    blocks: currentLibState.blocks,
                    variables: currentLibState.variables,
                    answers: currentLibState.answers
                }
            });
            store.dispatch({ type: "PROCESS_NEXT_PREVIOUS_FLAGS", payload: { }});
          }, 20);
          break;

        default:
            next(action);
    }
}

export default interviewMiddleware;
