import React, { Component } from 'react';
import { Container } from './style';

export default class AppLoader extends Component {
    render(){
        return <Container className="PageSpinner">
      		<div className="RawSpinner">
        		<svg className="RawSpinner-inner" viewBox="0 0 150 150">
        			<circle className="RawSpinner-inner-circle" cx="75" cy="75" r="60" />
        		</svg>
      		</div>
      	</Container>
    }
}
