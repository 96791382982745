import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export default class IconList extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <path fill={this.props.color.secondary} d="M15 5C9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10S20.5 5 15 5zm4.2 14.2L14 16v-6h1.5v5.2l4.5 2.7-.8 1.3z"/>
                        </g>
                    </svg>
                </Wrapper>;
    }
}