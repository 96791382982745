import styled from 'styled-components';
import globalStyle from '../../globalStyle';
import { InputLine } from '../style';

export const SubTitle = styled.h3`
    font-size: 23px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    margin: 0;
    width: calc(100% - 25px);

    &.clickable {
      cursor: pointer;
    }
`

export const SubSection = styled.section`
    padding: 20px;
    border: 1px solid ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
    border-radius: ${ props => props.color && props.color.material ? "4px" : "10px" };
    color: ${ props => props.color && props.color.text ? props.color.text : globalStyle.default };

    width: 100%;
    margin: 0 auto 20px;
    box-sizing: border-box;

    &.collapsed {
      padding-top: 11px;
      padding-bottom: 4px;
      padding-left: 30px;
      margin-bottom: 20px;

      span {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 0px;
        svg {
          width: 20px;
          height: 20px;
          path {
              fill: ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
          }
        }
      }
    }

    &:last-child{
        margin-bottom: 0;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        padding: 30px;
    }
`

export const SubSectionHeader = styled.div`
  position: relative;
`

export const DeleteButton = styled.button`
    &:before{
        content: url(/img/delete.svg);
        opacity: .35;
        display: block;
    }

    position: absolute;
    right: -20px;
    top: -20px;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    background: #fff;

    border-radius: ${ props => props.color && props.color.material ? "0 4px 0 4px" : "0 10px 0 10px" };

    transition: all .3s;

    padding: 0;

    &:hover,
    &:focus{
        background: ${ props => props.color && props.color.material ? props.color.secondary : "#eeeeee" };

        &:before{
            content: ${ props => props.color && props.color.material ? "url(/img/delete-neg.svg)" : "url(/img/delete.svg)" };
            opacity: ${ props => props.color && props.color.material ? ".6" : ".35" };
        }
    }

    @media screen and ( ${ globalStyle.desktop } ){
      right: -30px;
      top: -30px;
    }
`

export const BottomButtonLine = styled(InputLine)`

    justify-content: center;

    button:last-child:not(.invert){
        max-width: 250px;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        justify-content: flex-start;
    }
`
