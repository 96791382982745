import React, { Component } from 'react';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import styled from 'styled-components';

import FroalaEditor from 'react-froala-wysiwyg';
import $ from 'jquery';
window.$ = window.jQuery = $;

const Wrapper = styled.div`
    width: 100%;

    .fr-box {
        border: ${ props => props.isFocused ? "3px" : "2px" } solid;
        border-radius: ${ props => props.color.material ? "4px" : "10px" };
        border-color: ${ props => props.isFocused ? props.color.main : "#ccc" };
        margin ${ props => props.isFocused ? "0 -2px" : "2px 0" };
    }

    .fr-box.fr-basic.fr-top .fr-wrapper{
        box-shadow: none;
        border-radius: inherit;
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-image: url(/img/${ props => props.hasAnswer ? props.isInvalid ? "attention_colored.svg" : "done_colored.svg" : "" });
    }

    .fr-toolbar.fr-top {
        border: none;
        box-shadow: none;
        border-radius: inherit;

        button {
          width: 30px;
          height: 30px;

          i {
            margin: 8px;
          }
        }
    }

    .fr-code {
      border-radius: inherit;
    }

    .fr-popup {
      border-top: 5px solid ${ props => props.color.main }

      & .fr-arrow {
        border-bottom: 5px solid ${ props => props.color.main }
      }
    }

    .fr-view em{
      font-style: italic;
    }

    .fr-view p:first-child{
        margin-top: 0;
    }
    .fr-view p:last-child{
        margin-bottom: 0;
    }
`


export default class LognTextEditor extends Component{
    constructor(props){
        super(props);

        const config = {
            ...this.props,
            config:{
                ...this.props.config,
                key: process.env.REACT_APP_FROALA_KEY,
                events: {
                    'froalaEditor.initialized': (e, editor) => {
                      editor.toolbar.hide();
                    },
                    'froalaEditor.focus': (e, editor) => {
                      editor.toolbar.show();
                      this.setState({ focus: true });
                    },
                    'froalaEditor.blur': (e, editor) => {
                      editor.toolbar.hide();
                      this.setState({ focus: false });
                    },
                }
            }
        };

        this.state = {
            value: this.props.value,
            focus: false,
            config
        }

        this.clear = this.clear.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount(){
      this.$editor = this.refs.input.$editor[0];
    }
    componentWillReceiveProps(nextProps){
      if(nextProps.value === '') {
        this.setState({value: ''});
      }
    }
    focus(attr) {
      setTimeout(() => {
        if(this.$editor) {
          this.$editor.focus(attr);
        }
      },400);
    }
    clear() {
      this.setState({value: ''});
    }
    onChange(value){
        this.setState({ value: value })
        this.props.onChange({value: value });
    }
    render(){
        return  <Wrapper isFocused={this.state.focus} {...this.props}>
                    <FroalaEditor id={this.props.id} ref="input" {...this.state.config} model={this.state.value} onModelChange={this.onChange}/>
                </Wrapper>
    }
}
