import update from 'immutability-helper';
import translateService from '../../services/translateService';

export const initialState = {
    hash: '',
    isInit: false,
    hashValidated: null,
    isFirst: true,
    styleMode: 'idox',
    colorMode: process.env.REACT_APP_DEFAULT_THEME_COLOR,
    lang: '',
    finishLater: true,
    apiRouters:{},
    redirectUrl: '',
    savingAnswers: false,
    saveError: false,
    autoSaving: false,
    hasLocalAnswers: false,
    localAnswersLoaded: false,
};

const rootReducer = (state = initialState, action) => {
    let langDictionary;
    switch (action.type) {
        case "UPDATE_HASH":
            return update(state, {
                hash: { $set: action.payload }
            });
        case "UPDATE_HASHVALIDATION":
            return update(state, {
                hashValidated: { $set: action.payload }
            });
        case "UPDATE_INIT":
            return update(state, {
                isInit: { $set: action.payload }
            });
        case "UPDATE_HAS_LOCAL_ANSWERS":
            return update(state, {
                hasLocalAnswers: { $set: action.payload }
            });
        case "UPDATE_LOCAL_ANSWERS_LOADED":
            return update(state, {
                localAnswersLoaded: { $set: action.payload }
            });
        case "UPDATE_SAVING_ANSWERS_STATE":
            return update(state, {
                savingAnswers: { $set: action.payload.saving },
                saveError: { $set: action.payload.error },
                autoSaving: { $set: action.payload.auto }
            });
        case "UPDATE_INTERVIEW":
            langDictionary = new translateService();
            langDictionary.setLanguage( action.payload.setup.lang );
            return update(state, {
                colorMode: { $set: action.payload.setup.colorMode },
                styleMode: { $set: action.payload.setup.styleMode },
                lang: { $set: action.payload.setup.lang },
                finishLater: { $set: action.payload.setup.finishLater },
                apiRouters: { $set: action.payload.setup.routes },
                defaultSideBarOpen: { $set: action.payload.setup.sideBarOpen !== undefined ? action.payload.setup.sideBarOpen : false }
            });
        case "SET_REDIRECT_URL":
            return update(state, {
              redirectUrl: { $set: action.payload }
            });
        default:
            return state;
    }
};

export default rootReducer;
