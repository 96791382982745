import React, { Component } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
    svg{
        transform-origin: center;

        display: inline-block;

        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export default class IconCloudSaved extends Component {
    render(){
        return <Wrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <defs>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            {  this.props.saving && <path fill={this.props.color.secondary} d="M22.35 13.04A7.49 7.49 0 0 0 15 7c-2.89 0-5.4 1.64-6.65 4.04A5.994 5.994 0 0 0 3 17c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 16v4h-4v-4h-3l5-5 5 5h-3z"/>}
                            { !this.props.saving && <path fill={this.props.color.secondary} d="M22.35 13.04A7.49 7.49 0 0 0 15 7c-2.89 0-5.4 1.64-6.65 4.04A5.994 5.994 0 0 0 3 17c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM13 20l-3.5-3.5 1.41-1.41L13 17.17 18.18 12l1.41 1.41L13 20z"/>}
                        </g>
                    </svg>
                </Wrapper>;
    }
}