import React from 'react';
import QuestionBasic, {t, Actions} from '../../questions';
import {connect} from 'react-redux';

import { Question, InputLine, SelectPrefixOptions } from '../style';
import { TextInput } from '../../globalStyle';
import CurrencyInput, { CurrencyService } from '../../uiElements/CurrencyInput'
import PercentInput, { PercentService } from '../../uiElements/PercentInput'
import LoopButton from '../../uiElements/LoopButton';

import Slider from '../../uiElements/SliderInput'
import Select from 'react-select'

function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}

const mapDispatchToProps = dispatch => {
    return {
        ...Actions(dispatch)
    }
}

class QuestionNumber extends QuestionBasic{
    constructor(props){
        super(props);

        let propPrefixList = this.props.prefix || ( this.props.type === "CASH" ? [{ label: "R$", value: "R$" }] : undefined );
        let propCurrentPrefix = propPrefixList && propPrefixList[0];

        this.state.focused = false;
        this.state.prefixList = propPrefixList;
        this.state.currentPrefix = propCurrentPrefix;

        this.onSelectValue = this.onSelectValue.bind(this);
        this.formatedValue = this.formatedValue.bind(this);

        if( this.state.values && this.props.type === "CASH" ){
          this.state.values = this.state.values.map((value)=>{
            return CurrencyService.formatToCurrencyValue(value, propCurrentPrefix.value)
          });
        }

        // keyboard events
        this.registerKey(['ArrowLeft', 'ArrowRight'], () => {}, false, true);

        this.registerKey('Enter', (e) => {
          if(this.props.allowLooping) {
            // if valid
            if( this.state.status ) {
              // if editing, submit editing value
              if (this.state.isEditing) {
                e.stopPropagation();
                this.onSubmitNewValue();
              }
              // if not editing and has any value, submit and go next
              else if (this.state.values[this.state.newIndex]) {
                e.stopPropagation();
                this.onSubmitNewValue(true);
              }
            }
            else {
              // if invalid, just stop propagation
              e.stopPropagation();
            }
          } else {
            this.onBlur();
          }
        }, false, false);
        
        this.registerKey(['Shift', '+'], (e) => {
          let focused = this.isFocused();
          
          if (!!e.shiftKey) {
            switch (e.keyCode) {
              case 16: // ignore shift key
              break;
              default:
                
                if (focused.input 
                  && this.state.status 
                  && this.isValid(this.state.values) 
                  && this.state.values.length ) {
                    this.onSubmitNewValue()
                }
              
                break;
            }
          }
        }, false, false)

        // Cancel editing value
        this.registerKey('Escape', (e) => {
          if(this.props.allowLooping && this.state.isEditing && e.target === this.refs.input) {
            e.stopPropagation();
            this.onDeselectValueListItem();
          }
        }, false, false);

        this.registerKey('Tab', (e) => {
          let focused = this.isFocused();

          let hasParent = this.hasParent();
          let allowLooping = this.props.allowLooping;
          let hasLoopWithItens = allowLooping && this.state.values.length > 0;

          if(e.shiftKey) {
            // if is focused on the first input element (input or slider) and
            // pressed shift+tab
            if (focused.input) {
              // if has no parent, focus on previous element just can be
              // allowed when question has loop and at least one item
              if (!hasParent && !hasLoopWithItens) {
                e.preventDefault();
              }
            }
          }
          else {
            // if pressed tab on input and plusBtn is disabled or pressed tabIndex
            // on plusBtn with invalid values, focus need to stay into question
            // inputs and plusBtn
            if ((focused.input && !this.state.status) ||
                (focused.plusBtn && !this.isValid(this.state.values))) {
              e.preventDefault();
              this.renderCurrentError();
            }
          }
        }, false, true);

        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    componentDidMount(){
        //this.setTutorialKeys(this.hasSlider() ? ['←', '→'] : [ ]);
        super.componentDidMount();
    }

    componentWillUnmount(){
        if( this.delayedFocus ) clearTimeout( this.delayedFocus );
        super.componentWillUnmount();
    }

    isFocused() {
        let focus = {
            slider: this.refs.slider && this.refs.slider.isFocused() ? true : false,
            plusBtn: this.refs.plusBtn && this.refs.plusBtn === document.activeElement
        }

        if ( ( this.refs.input && this.refs.input.isFocused && this.refs.input.isFocused()) ||
               this.refs.input === document.activeElement) {
            focus.input = true;
        }

        return focus;
    }

    isValid(values){
        if( this.props.mandatory && ( !values || !values.length || values[0] === "" )) return false;

        // check last input
        let keyValue = Array.isArray(values) ? values[this.state.newIndex] : values;
        // dont check if null and its allowed
        if( keyValue === "" || keyValue === undefined ) return true;

        // is valid Number
        switch( this.props.type ){
            case "PERCENT":
                if( !PercentService.isValid(keyValue) ) return false;
            break;
            case "NUM":
                if( isNaN(keyValue) ) return false;
                break;
            case "CASH":
                if( !CurrencyService.isValid(keyValue) ) return false;
                break;
            default:
        };

        return true;
    }

    hasError(values, callback){
        // check mandatory
        if( this.props.mandatory ){
          let emptyValue = values.findIndex(value => this.isEmpty(value))

          if( !values.length || // no itens
              (values.length === 1 && emptyValue !== -1) || // input is empty and no other itens
              (emptyValue !== (values.length - 1) && emptyValue !== -1) ) { // an item !== of input is empty
            return callback(t("This is a obligatory field"), false);
          }
        }

        // check last input
        let keyValue = values[this.state.newIndex];
        // dont check if null and its allowed
        if( keyValue === "" || keyValue === undefined ) return callback( false, false );

        // is valid Number
        if( !this.isValid( values ) ) return callback( t("Invalid value"), false );

        // is in range limit
        keyValue = this.parseInput(keyValue);
        if( this.props.limSup !== undefined && this.props.limSup !== null && this.props.limSup !== "" && keyValue > this.props.limSup ) return callback( t("The value must be below or equal ?0", this.props.limSup), false );
        if( this.props.limInf !== undefined && this.props.limSup !== null && this.props.limInf !== "" && keyValue < this.props.limInf ) return callback( t("The value must be above or equal ?0", this.props.limInf), false );

        this.triggerAPIEvent('validation', values ).then((response)=>{
            const error = response && response.length ? response[0].result === false ? response[0].message : false : false ;
            callback( error, false );
        }).catch((error)=>{
            callback( false, false );
        });

        return callback( true, true );
    }

    inputType () {
        switch (this.props.type) {
          case "NUM":
          case "DAYS":
            return TextInput;
          case "CASH":
            return CurrencyInput;
          case "PERCENT":
            return PercentInput;
          default:
            return TextInput;
        }
    }

    parseInput (value) {
      if (value !== '') {
        switch (this.props.type) {
            case 'NUM':
                return parseFloat(value);
            case 'CASH':
                return CurrencyService.currencyToValue(value).value;
            case 'PERCENT':
                return PercentService.percentToValue(value);
            default:
                return value
            }
      } else {
        return value
      }
    }

    formatedValue(value) {
        if (value !== '') {
          switch (this.props.type) {
              case 'NUM':
                return (value).toString().replace(/[^-.0-9]/,"").replace(/\.(?!.*\.)/,"#").replace(/\./g,"").replace(/#/,".");
              case 'CASH':
                return CurrencyService.valueToCurrency(value);
              case 'PERCENT':
                return PercentService.valueToPercent(value);
              default:
                return value
              }
        } else {
          return value
        }
    }

    updateSelectPrefix( prefix ){
        if( !this.state.prefixList || !this.state.prefixList.length ) return "";
        if( !prefix ) return this.state.currentPrefix.value || "";

        let prefixOption = this.state.prefixList.find(prefixOption=>prefixOption.value === prefix);
        if( prefixOption ){
            this.setState({ currentPrefix: prefixOption });

            // update actual value prefix if needed
            setTimeout(() => { this.onChangeInputValue() }, 1)

            return prefix;
        }
        return "";
    }

    onSelectValueListItem(evt) {
      let newIndex = super.onSelectValueListItem(evt);
      this.updateSelectPrefix(CurrencyService.currencyToValue(this.state.values[newIndex]).currencySignal)
    }

    onChangeInputValue(evt = {}) {
        let values = [...this.state.values];
        const value = evt.target ? evt.target.value : values[this.state.newIndex];
        const prefix = this.state.currentPrefix ? this.state.currentPrefix.value : '';
        const formatedValue = prefix && value ? CurrencyService.formatToCurrencyValue(value, prefix, true) : this.formatedValue(value);
        values[this.state.newIndex] = formatedValue;
        const toSubmitValue = this.getStorable(values);

        if (this.state.values[this.state.newIndex] === formatedValue) return;

        if (toSubmitValue.value[toSubmitValue.value.length - 1] === '') {
          toSubmitValue.raw.pop();
        }

        this.hasError( values, (error)=>{
            const status = !error;

            this.setState({
              rawValues: toSubmitValue.raw,
              values: values,
              errorMsg: error,
              status
            });

            this.props.onChange( this.props.id, toSubmitValue, status, this.state.reference );

            if( !error ) this.triggerAPIEvent('data', values, !error );
        });

    }

    onSelectValue(evt){
       // if( !this.props.allowLooping ) this.onChangeInputValue({prefix: evt.value});
       // if( this.props.allowLooping ) this.updateSelectPrefix(evt.value);
       this.updateSelectPrefix(evt.value);
    }

    onFocus(){
        if( super.onFocus ) super.onFocus();
        if( this.delayedFocus ) clearTimeout( this.delayedFocus );

        this.setState({ focused: true });
    }

    onBlur(){
        if( super.onBlur ) super.onBlur();

        if( this.delayedFocus ) clearTimeout( this.delayedFocus );
        this.delayedFocus = setTimeout(()=>{
            const focusState = this.isFocused();
            this.setState({ focused: ( focusState.slider || focusState.input ) });
        }, 10);
    }

    hasSlider() {
        return (this.props.limInf !== undefined && this.props.limInf !== null && this.props.limInf !== "" &&
                this.props.limSup !== undefined && this.props.limSup !== null && this.props.limSup !== "");
    }

    renderSlider( input, onChange ){
        if( this.hasSlider() ){

            const min = this.parseInput(this.props.limInf);
            const max = this.parseInput(this.props.limSup);
            const value = input !== "" && input !== undefined && this.isValid([input]) ? this.parseInput( input ) : (min + max) / 2;
            const step = this.props.step ? parseFloat( this.props.step ) : null;

            return  <InputLine className={ ( this.state.focused ? "show" : "hide" ) } onKeyDown={this._keyDown}>
                      <div className={ "slider " + ( this.state.focused ? "show" : "hide" ) }>
                        <Slider ref="slider" min={min} max={max} step={step} format={this.formatedValue} color={this.props.color} value={value} onChange={onChange} onBlur={this.onBlur} onFocus={this.onFocus} />
                      </div>
                    </InputLine>
        }else{
            return false
        }
    }

    renderInput(isMultiple, isEdit){
        const value = this.state.values[this.state.newIndex];
        const isValid = this.state.touched && this.state.status;
        const hasAnswer = this.state.touched && value;
        const Input = this.inputType();
        const SelectStyle = SelectPrefixOptions( this.props.color );
        const selectValue = this.state.currentPrefix;
        const isLoading = this.props.isLoading || this.state.isLoading;

        return this.renderWithTutorial(
          <>
            <InputLine onFocus={this.onFocus} onKeyDown={this._keyDown} className={ isEdit ? "bordered" : "" } >
                { this.state.prefixList && <Select options={this.state.prefixList} styles={SelectStyle} value={selectValue} isSearchable={false} onChange={this.onSelectValue}/>}
                <Input
                    ref="input"
                    tabIndex="0"
                    isInvalid={!isValid}
                    hasAnswer={hasAnswer}
                    isLoading={isLoading}

                    hasPrefix={this.state.prefixList}
                    placeholder={""}
                    color={this.props.color}
                    value={value}
                    onChange={this.onChangeInputValue}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    />
                  { this.renderButtons(isMultiple, isEdit, !this.isEmpty(value)) }
            </InputLine>
            { this.renderSlider( value, this.onChangeInputValue ) }
          </>)

    }

    render(){
        const isMultiple = this.props.allowLooping;
        const values = this.state.values.slice(0, this.state.values.length - 1);
        const isEdit = this.state.isEditing;

        return <Question className={ this.props.active ? "active" : "" } color={this.props.color} id={this.props.navId}>
            { this.renderHeader() }
            {isMultiple &&
            <div>
                { values && values.map((value, i)=>{
                        if( !isEdit || this.state.newIndex !== i ) return <LoopButton editable={true} color={this.props.color} selected={ this.state.newIndex === i } onEdit={this.onSelectValueListItem} onRemove={() => {this.onDeleteValue(i)}} key={i} value={value} index={i}>{ this.formatedValue(value) }</LoopButton>
                        if(  isEdit && this.state.newIndex === i ) return <div key={i} >{ this.renderInput(isMultiple, isEdit) }</div>
                        return false
                    })
                }
                { !isEdit && this.renderInput(isMultiple, isEdit) }
                { this.renderAlert() }
            </div>}
            {!isMultiple &&
            <div>
                { this.renderInput(isMultiple, isEdit) }
                { this.renderAlert() }
            </div>}
        </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionNumber);
