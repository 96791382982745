import React from 'react';

export const IconConversation = (props)=>{
    const unique = Math.floor( Math.random() * 1000000 );
    const color = props.color.material ? props.active ? "white" : "#607d8b" : props.active ? props.color.main : "white" ;
    return  <span style={{display: props.show ? "block" : "none" }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={props.style}>
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h30v30H0z"/>
                    <mask id={`a${unique}`} fill={color} >
                        <path id="a11" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/>
                    </mask>
                    <path id="a12" fill={color} fillRule="nonzero" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/>
                    <g fill={color} mask={`url(#a${unique})`}>
                        <path d="M0 0h30v30H0z"/>
                    </g>
                </g>
            </svg></span>
}

export const IconLock = (props)=>{
    const unique = Math.floor( Math.random() * 100000 );
    const color = props.color.material ?  "black" : "white" ;
    return  <span style={{display: props.show ? "block" : "none" }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={props.style}>
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h30v30H0z"/>
                    <mask id={`a${unique}`} fill={color}>
                        <path id="a21" d="M21 12h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2H9c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V14c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                    </mask>
                    <path id="a22" fill={color} fillRule="nonzero" d="M21 12h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2H9c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V14c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                    <g fill={color} mask={`url(#a${unique})`}>
                        <path d="M0 0h30v30H0z"/>
                    </g>
                </g>
            </svg></span>
}

export const IconDone = (props)=>{
    const unique = Math.floor( Math.random() * 10000 );
    const color = props.color.material ? "#7bb342" : "white" ;
    return  <span style={{display: props.show ? "block" : "none" }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={props.style}>
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h30v30H0z"/>
                    <mask id={`a${unique}`} fill={color}>
                        <path id="a31" d="M15 5C9.48 5 5 9.48 5 15s4.48 10 10 10 10-4.48 10-10S20.52 5 15 5zm-2 15l-5-5 1.41-1.41L13 17.17l7.59-7.59L22 11l-9 9z"/>
                    </mask>
                    <path id="a32" fill={color} fillRule="nonzero" d="M15 5C9.48 5 5 9.48 5 15s4.48 10 10 10 10-4.48 10-10S20.52 5 15 5zm-2 15l-5-5 1.41-1.41L13 17.17l7.59-7.59L22 11l-9 9z"/>
                    <g fill={color} mask={`url(#a${unique})`}>
                        <path d="M0 0h30v30H0z"/>
                    </g>
                </g>
            </svg></span>
}

