import styled from 'styled-components';
import globalStyle from '../../globalStyle';

export const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    border: solid 1px #dcdcdc;
    outline: none;
    margin: 2px 0;

    color: #797979;
    text-align: left;
    background-color: #ffffff;
    padding: 0;
    margin-bottom: 10px;
    border-radius: ${ props => props.color.material ? 
        props.hasPrefix ? "0 4px 4px 0" : "4px" :
        props.hasPrefix ? "0 10px 10px 0" : "10px"
    };
    width: 100%;
    box-sizing: border-box;
    box-shadow: ${ props => props.color && props.color.material ? "box-shadow: inset 0 0px 0 0 #fff;" :  "0 4px 11px 0 rgba(0, 0, 0, 0.1);"  };

    overflow: hidden;

    transition: all 0.2s;

    &:last-child{
        margin-bottom: 20px;
    }

    &.selected,
    &:focus{
        border: 3px solid ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        margin: -2px 0 8px 0;
        font-style: italic;

        &:last-child{
            margin-bottom: 18px;
        }
    }

    div{
        ${ props => props.color && props.color.material ? "box-shadow: inset 0 0 0 0 #ccc;" : "" }
        padding: 15px 45px 15px 30px;


        transition: all 0.2s;
        background-color: #ffffff;

        p:first-child{
            margin-top: 0;
        }
        p:last-child{
            margin-bottom: 0;
        }

        &:hover,
        &:focus{
            ${ props => props.color && props.color.material ? "box-shadow: inset 0 -2px 0 0 #ccc;" : "" }
            outline: none;
            background-color: #eeeeee;
        }
    }

    button{
        &:before{
            content: url(/img/delete.svg);
            opacity: .35;
            display: block;
        }
        ${ props => props.color && props.color.material ? "box-shadow: inset 0 0px 0 0 #ccc;" : "" }

        position: absolute;
        right: 0px;
        top: 0px;
        width: 60px;
        height: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        background: #fff;

        padding: 0;

        &:hover,
        &:focus{
            ${ props => props.color && props.color.material ? "box-shadow: inset 0 -2px 0 0 #ccc;" : "" }

            background: #eeeeee;
        }
    }
`

export const SimpleLoopButton = styled.button`
    position: relative;
    cursor: pointer;
    border: solid 1px #dcdcdc;
    outline: none;
    margin: 2px 0;

    color: #797979;
    text-align: left;
    background-color: #ffffff;
    padding: 15px 45px 15px 30px;
    margin-bottom: 10px;
    border-radius: ${ props => props.color.material ? 
        props.hasPrefix ? "0 4px 4px 0" : "4px" :
        props.hasPrefix ? "0 10px 10px 0" : "10px"
    };
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.1);

    transition: all 0.2s;

    &:last-child{
        margin-bottom: 20px;
    }

    &:focus{
        border: 3px solid ${ props => props.color && props.color.main ? props.color.main : globalStyle.default };
        margin: -2px 0 8px 0;

        &:last-child{
            margin-bottom: 18px;
        }
    }

    &:hover{
        color: #3a3939;
        background-color: #eeeeee;
        box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
    }

    &:before{
        position: absolute;
        content: url(/img/delete.svg);
        right: 10px;
        top: 9px;
        opacity: .35;
    }

    &.fail{
        background: #d8a8a8;
        font-style: italic;
        color: #fff;
    }
`
