
import isMobile from './mobileDetect';
import lStorage from './localStorageService';

export default class TutorialService {

  static instance = undefined;

  constructor () {
    if (!TutorialService.instance) {
      this.tips = [ ];
      this.disabled = isMobile;
      this.actualTip = undefined;
      TutorialService.instance = this;
    }
    return TutorialService.instance;
  }

  registerTip(id, name, show, delay, id2) {
    if (!this.disabled) {
      if (!this.isRegistered(name) && !this.getView(name)) {
        this.tips.push({
          id,
          name,
          show,
          delay: delay !== undefined ? delay : 0
        });
      }
      if(!this.actualTip) {
        this.showNext();
      }
    }
  }

  unregisterTip(id) {
    if(this.actualTip && this.actualTip.id === id) {
      this.showNext();
    } else {
      this.tips = this.tips.filter(tip => tip.id !== id);
    }
  }

  isRegistered(name) {
    if (this.actualTip && this.actualTip.name === name) {
      return true;
    } else {
      return this.tips.findIndex(tip => tip.name === name) !== -1;
    }
  }

  onClose(id) {
    if (this.actualTip && this.actualTip.id === id) {
      this.setView(this.actualTip.name);
      this.showNext();
    }
  }

  showNext() {
    if(!this.disabled) {
      this.actualTip = this.tips.shift();

      // while the actual tooltip is seen, get next
      while(this.actualTip && this.getView(this.actualTip.name)) {
        this.actualTip = this.tips.shift();
      }

      if(this.actualTip) {
        let actualTip = this.actualTip;
        setTimeout(() => {
          actualTip.show();
        }, this.actualTip.delay);
      }
    }
  }

  disable() {
    this.disabled = true;
    this.tips = [ ];
    this.actualTip = undefined;
  }

  canShow(name) {
    return !this.disabled && !this.getView(name) && this.actualTip && this.actualTip.name === name;
  }

  setView(name) {
    lStorage.set(lStorage.INTERVIEW_TUTORIAL, true, name);
  }

  getView(name) {
    return lStorage.get(lStorage.INTERVIEW_TUTORIAL, name);
  }

  static getInstance() {
    return new TutorialService();
  }
}
