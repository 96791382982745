import styled from 'styled-components';
import globalStyle, { Button } from '../../globalStyle';

export const NavigatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 10px;
  left: 10px;
  bottom: 10px;
  z-index: 10;

  button{
    margin-bottom: 0;
  }
`

export const Arrows = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media screen and ( ${ globalStyle.desktop } ){
    display: block;
  }
`

export const NavigatorButton = styled(Button)`

  border-width: 1px;
  &:focus,
  &:hover{
    border-width: 1px;
  }

  margin-left: 5px;
  margin-bottom: 0px;

  span{
    display: block;
    width: 100%;
    height: 100%;
  }

  & object {
    pointer-events: none;
  }

  svg{
    transform-origin: center;
    transform: rotate(0deg);
    transition: all .5s;
    width: 30px;
    heigth: 30px;
    display: block;
  }

  &.previousBlock{
    svg{
      transform: rotate(-90deg) scale(1);
    }
  }

  &.nextBlock{
    svg{
      transform: rotate(-90deg) scale(1);
    }
  }

`

export const UnansweredContainer = styled.div`
max-width: 300px;
text-align: center;
margin-bottom: 10px;
`

export const UnansweredMessage = styled.p`

`

export const UnansweredButton = styled(Button)`
height: 50px !important;
`
