import styled from 'styled-components';
import globalStyle from '../../globalStyle';

export const Block = styled.a`
    border: 0px solid transparent;

    text-decoration: none;

    color:  ${ props => props.color.material ? props.color.text : "white" };
    width: 100%;
    padding: 22.5px;
    min-height: 195px;
    border-radius: ${ props => props.color.material ? "4px" : "10px" };
    box-shadow: ${ props => props.color.material ? "0 4px 11px 0 rgba(0,0,0,0.1)" : "0 1px 3px 0 rgba(0, 0, 0, 0.12)" };
    background: ${ props => props.color.material ? "white" : "linear-gradient(135deg, rgba(255,255,255,0.08) 0%,rgba(255,255,255,0.15) 100%)" };
    box-sizing: border-box;
    margin-bottom: 20px;
    position relative;

    cursor: pointer;

    &.active{
        background-color: ${ props => props.color.material ? props.color.secondary : "white" };
        color: ${ props => props.color.material ? "white" : props.color && props.color.main ? props.color.main : globalStyle.default };

        .progressbar:after,
        .progressbar:before{
            background-color: ${ props => props.color.material ? "white" : props.color && props.color.main ? props.color.main : globalStyle.default };
        }
    }

    &.blocked,
    &[disabled]{
        opacity: .5;
        pointer-events: none;
    }

    &:hover:not(.blocked){
        box-shadow:  0 4px 11px 0 rgba(0,0,0,0.1), 0 0 0px 2px rgba(255, 255, 255, 0.4) inset;

    }

    &:focus{
        border: 0px solid #dcdcdc;
        box-shadow: 0 0 0px 2px rgba(255, 255, 255, 0.4) inset;
    }

    @media screen and ( ${ globalStyle.desktop } ){
        width: calc( 50% - 10px );
    }
`

export const Progress = styled.div`
    opacity: 0.6;
    font-size: 11px;
    line-height: 1.67;
    padding-bottom: 9px;
    position: absolute;
    bottom: 22.5px;
    right: 22.5px;
    left: 22.5px;

    &:before{
        content: '';
        display: block;
        height: 4px;
        width: 100%;
        position absolute;
        bottom: 0;
        background: ${ props => props.color.material ? props.color.text : "white" };
        opacity: 0.3;
        border-radius: 4px;
    }

    &:after{
        content: '';
        display: block;
        height: 4px;
        width: ${props => props.progress || "0"}%;
        position absolute;
        bottom: 0;
        background: ${ props => props.color.material ? props.color.text : "white" };
        border-radius: 4px;
    }
`

export const SubTitle = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
`

export const Title = styled.h2`
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    overflow-wrap: break-word;
    hyphens: auto;
    padding-bottom: 20px;
`

export const Icon = styled.div`

    position: absolute;
    right: 10px;
    top: 10px;

    span{
        border-radius: 50%;
        overflow: hidden;
        display: block;

        svg{
            width: 30px;
            height: 30px;
            display: block;
        }
    }
`