import React from 'react';
import KeyboardControlled from '../../keyboardControlled';
import {connect} from 'react-redux';
import { Question, Title, Description, Feedback } from '../style';

import isMobile from '../../../services/mobileDetect';

import QuestionDynamic from '../questionDynamic';

function mapStateToProps(state){
    const { interview, nav } = state;
    return { interview, nav };
}


const mapDispatchToProps = dispatch => {
    return {
        onChangeValue( variableId, val , status, reference ){
            return dispatch({
                type: "SET_ANSWER",
                payload: {
                    variableId,
                    answer: val,
                    reference,
                    status
                }
            });
        },
        dropIndex( variableId, index ){
            return dispatch({
                type: "DROP_COMPLEX_INDEX",
                payload: {
                    variableId,
                    index
                }
            });
        }
    }
}


class QuestionIntro extends KeyboardControlled {

    constructor(props){
        super(props);

        // let answers = this.props.interview.answers[ this.props.id ];
        /*if (answers && answers[0]) {
          propValue = answers[0].value;
        } else {
          // If allowLooping === false, set value to be the label of this variable
          // this is for maintain the patter of complex variables values has only
          // their labels.
          propValue = this.props.allowLooping ? [] : [this.props.label];
        }*/

        const propValue = this.props.values || {};

        let isValid = this.isValid(propValue);

        this.state = {
            status: isValid,
            values: propValue,
            newLabel: "",
            errorMsg: "",
            // confirm dialog
            showConfirm: false
        }

        this.updateDelayer = {};

        this.questionsContainer = React.createRef();

        this.registerKey('Tab', (e) => {
          if (e.shiftKey && e.target !== this.refs.plusBtn && e.target.nodeName !== 'BUTTON') {
            e.preventDefault();
          }
          this.renderCurrentError();
        }, false, true);

        this.onChildChange = this.onChildChange.bind(this);
        this.onChildStart = this.onChildStart.bind(this);
    }

    componentDidMount(){
        this.focus();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.nav.currentVariable !== this.props.nav.currentVariable) {
          this.focus();
        }
    }

    focus() {
      if(!isMobile && this.isCurrentVariable()) {
        this.questionsContainer.current.focus({preventScroll: true});
      }
    }

    isCurrentVariable () {
      return this.props.id === this.props.nav.currentVariable;
    }

    isValid ( forceValues ) {
        let status = true;
        let variablesData = this.props.questions;
        let iterations = variablesData.length > 0 ? variablesData.length : 0;
        let values = forceValues || ( this.state && this.state.values );

        for (var i = 0; i < iterations && status; i++) {
            //
            const variable = variablesData[i];
            const answer = this.state ? ( ( values[ variable.id ] ) ) : false;

            if (answer) {
                // if answered, answer status interfere in complex status
                status = status && ( ( !variable.mandatory && answer.status === undefined ) || answer.status );
            } else {
                //if not answered, only mandatory flag intefere in complex status
                status = status && !variable.mandatory;
            }
        }

        return status;
    }

    onChildStart(state){
        const valid = this.isValid();
        this.setState({status: valid});

        if(this.updateDelayer['childstart']) clearTimeout(this.updateDelayer['childstart']);
        this.updateDelayer['childstart'] = setTimeout(() => {
            if( this.props.onChange ) this.props.onChange(state);
        }, 1);
    }

    onChildChange(id, value, status, reference){
        const key = `${id}-${reference.index}`;
        const change = { id, value, status, reference };
        let values = this.state.values;
        values[change.id] = { id, value, status };
        this.setState({ values });

        if(this.updateDelayer[key]) clearTimeout(this.updateDelayer[key]);

        this.updateDelayer[key] = setTimeout(() => {
          //save child state
          this.props.onChangeValue( change.id, change.value, change.status, change.reference );

          // process actual complex variable state
          setTimeout(()=>{
              const valid = this.isValid();
              this.setState({status: valid});
              this.props.onChange( change.id, change.value, valid );
          }, 10 );
        }, 300);
    }


    renderAlert(){
        if( this.state.errorMsg ) return <Feedback>{(this.state.errorMsg || "")}</Feedback>
        return false;
    }

    render(){
        let variablesData = this.props.questions;

        return  <Question ref={this.questionsContainer} tabIndex="0" onKeyDown={this._keyDown} color={this.props.color} className={ 'active' }>
                    <header>
                        <Title color={this.props.color}>{this.props.label}{ this.props.mandatory ? "*" : "" }</Title>
                        <Description color={this.props.color}>{this.props.question}</Description>
                    </header>
                    {variablesData && variablesData.map((variable, i)=>{
                        // const variable = variablesData[ label ];
                        return <div key={"intro" + i || Math.random()} color={this.props.color}>
                                    <QuestionDynamic index={i} color={this.props.color} onChange={this.onChildChange} onStart={this.onChildStart} key={variable.id} active={ true } {...variable} navTree={variable.subVariables} />
                                </div>
                    })}
                    { this.renderAlert() }
                </Question>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionIntro);
