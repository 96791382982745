import React, { Component } from 'react';
import { connect } from 'react-redux';

import { IntroContainer } from './style';
import QuestionIntro from '../questions/questionIntro';

import globalStyle, { Button } from '../globalStyle';

import translateService from '../../services/translateService';

const translateDictionary = new translateService();
const t = translateDictionary.getTranslateFunction();

function mapStateToProps(state){
    const { nav, interview, app } = state;
    return { nav, interview, app };
}

const mapDispatchToProps = dispatch => {
    return {
        submitInterview( ){
            return dispatch({
                type: "SUBMIT_INTERVIEW",
                payload: { }
            });
        },
        doneLater( ){
            return dispatch({
                type: "DONE_LATER",
                payload: { }
            });
        },
        openLoading(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        showSuccess(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Interview saved!", text: "The interview was saved on your profile. You'll be redirected to iDox Explorer now.", type: "success" }
            })
        },
        showError(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Fail to save Interview!", text: "The interview can't be saved at this moment. Please, try again later.", type: "error" }
            })
        },
        hideSuccess(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Interview saved!", text: "The interview was saved on your profile. You'll be redirected to iDox Explorer now.", type: "success" }
            })
        },
        closeLoading(){
            return dispatch({
                type: "CLOSE_DIALOG",
                payload: { title: "Saving answers", text: "", type: "loading" }
            })
        },
        showCloseAlert(){
            return dispatch({
                type: "OPEN_DIALOG",
                payload: { title: "Edit section finished", text: "You can close this tab now or continue editing.", type: "modal" }
            })
        },
        prev(){
            return dispatch({
                type: "NAV_INTRO",
                payload: { }
            });
        },
        saveIntroInfo(){
            return dispatch({
                type: "SET_USER_INFO",
                payload: {}
            });
        }
    }
}

class InterviewFinish extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: true
        }

        this.finishInterview = this.finishInterview.bind(this);
        this.saveToFinishLater = this.saveToFinishLater.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.app.savingAnswers && !this.props.app.savingAnswers){
        this.updateSaveDialogContent()
      }
    }

    finishInterview(e) {
      setTimeout(()=>{
          this.props.openLoading();
          this.props.submitInterview();
      }, 1 );
    }

    saveToFinishLater(e) {
      e.preventDefault();

      setTimeout(()=>{
          this.props.openLoading();
          this.props.doneLater();
      }, 1 );
    }

    updateSaveDialogContent() {
        if(this.props.app.saveError) {
          this.props.showError();
        } else {
          this.props.showSuccess();
          setTimeout(() => {
            window.location.replace(this.props.app.redirectUrl);
          }, 2300);
        }
        setTimeout(()=>{
          this.props.closeLoading();
        }, 1000 );
    }

    getVariable(){
        let questions = [];

        const lastBlock = this.props.nav.currentNavTree[ this.props.nav.currentNavTree.length - 1 ];
        let last = lastBlock && lastBlock.subVariables[ lastBlock.subVariables.length - 1 ];
        if( last && last.id ){
            last = this.props.interview.variables[ last.id ];
            if( last.type === "MSG" || last.type === "MESSAGE" ) {
                questions = [last];
            }
        }
        const lastBlockObj = this.props.interview.blocks[ lastBlock.id ];

        return { questions, last, lastBlockObj };
    }

    render(){

        const { questions, lastBlockObj } = this.getVariable();
        const themeName = "white";
        const colorName = lastBlockObj.colorMode === "material" ? "material" : "white" ;
        const theme = {
            main: globalStyle[ colorName ],
            secondary: globalStyle[ colorName + "Secondary" ] || "white",
            gradient: globalStyle[ colorName + "Gradient" ],
            icon: globalStyle[ colorName + "Favicon" ],
            title: globalStyle[ themeName + "Title" ],
            text: globalStyle[ themeName + "Text" ],
            subText: globalStyle[ themeName + "SubText" ],
            button: globalStyle[ colorName + "Secondary" ] || globalStyle[ themeName ],
            buttonHover: globalStyle[ colorName + "Secondary" ] || globalStyle[ themeName ],
            material: ( lastBlockObj.colorMode === "material" )
        }

        return  <IntroContainer color={theme}>
                    <div>
                        <QuestionIntro color={theme} onStart={ e => {} } questions={questions} />
                        <div className="commands">
                            <div><Button className="ghost" onClick={this.props.prev} color={theme}>{ t("Edit interview") }</Button></div>
                            <div><Button onClick={this.finishInterview} color={theme}>{ t("Finish interview") }</Button></div>
                            <div><a onClick={this.saveToFinishLater} href="#save">{ t("Save to finish later") }</a></div>
                        </div>
                    </div>
                </IntroContainer>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewFinish);
