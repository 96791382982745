import React, { Component } from 'react';
import { connect } from 'react-redux';

import { IntroContainer } from './style';
import QuestionIntro from '../questions/questionIntro';

import globalStyle, { Button } from '../globalStyle';

function mapStateToProps(state){
    const { nav, interview } = state;
    return { nav, interview };
}

const mapDispatchToProps = dispatch => {
    return {
        saveIntroInfo(){
            return dispatch({
                type: "SET_USER_INFO",
                payload: {}
            });
        }
    }
}

class InterviewIntro extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: true
        }

        this.startInterview = this.startInterview.bind(this);
        // if dont have intro, go foward
        if( !this.getVariable().length ){ this.startInterview(); }
    }

    startInterview(e) {
      this.props.saveIntroInfo();
    }

    getVariable(){
        let questions = [];

        const firstBlock = this.props.nav.currentNavTree[0];
        let first = firstBlock && firstBlock.subVariables[0];
        if( first && first.id ){
            first = this.props.interview.variables[ first.id ];
            if( first.type === "MESSAGE" || first.type === "MSG" ){
                questions = [first];
            }
        }

        const firstBlockObj = this.props.interview.blocks[ firstBlock.id ];

        return { questions, first, firstBlockObj };
    }

    render(){

        const { questions, firstBlockObj } = this.getVariable();
        const themeName = "white";
        const colorName = firstBlockObj.colorMode === "material" ? "material" : "white" ;
        const theme = {
            main: globalStyle[ colorName ],
            secondary: globalStyle[ colorName + "Secondary" ] || "white",
            gradient: globalStyle[ colorName + "Gradient" ],
            icon: globalStyle[ colorName + "Favicon" ],
            title: globalStyle[ themeName + "Title" ],
            text: globalStyle[ themeName + "Text" ],
            subText: globalStyle[ themeName + "SubText" ],
            button: globalStyle[ colorName + "Secondary" ] || globalStyle[ themeName ],
            buttonHover: globalStyle[ colorName + "Secondary" ] || globalStyle[ themeName ],
            material: ( firstBlockObj.colorMode === "material" )
        }

        return  <IntroContainer color={theme}>
                    <div>
                        <QuestionIntro color={theme} onStart={ e => {} } questions={questions} />
                        <Button onClick={this.startInterview} disabled={!this.state.status} invert={true} color={theme}>Iniciar Entrevista</Button>
                    </div>
                </IntroContainer>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewIntro);
