import RcDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class DatePicker extends RcDatePicker {
  onInputKeyDown = (event) => {
    // Close datepicker
    if(['Esc', 'Tab', 'Enter'].indexOf(event.key) !== -1) {
      this.setOpen(false, true);
    }
  }
}
