
import ApiInterface from './ApiInterface';
import localApi from './LocalApi';

import DefaultRoutes from './DefaultRoutes';
import DataInterface from './DataInterface';

class RemoteAPI extends ApiInterface{
    constructor(routes){
        super();
        if (!RemoteAPI.instance) {
          this.localApi = localApi.getInstance();
          this.dataInterface = new DataInterface();
          this.routes = routes ? routes : DefaultRoutes;
          RemoteAPI.instance = this;
        }

        return RemoteAPI.instance;
    }

    _getUri(key, attrs) {
      let uri = this.routes[key].url;

      if(this.routes[key].route) uri = uri + this.routes[key].route;

      if(attrs) {
        uri = `${uri}?`

        Object.keys(attrs).forEach(attr => {
          uri = `${uri}${attr}=${attrs[attr]}&`;
        });
      }

      return encodeURI(uri);
    }

    _getFetchInit(key, headers, body) {
      let init = {
        method: this.routes[key].method
      }

      if(headers) init.headers = headers
      if(body) init.body = JSON.stringify(body)

      return init
    }

    _getDefaultHeaders() {
      return {
        'Content-Type': 'application/json'
      }
    }

    _noConnection() {
      return Promise.reject("No connection");
    }

    fetch(key, attrs, headers, body) {
      return fetch(this._getUri(key, attrs), this._getFetchInit(key, headers, body));
    }

    validateHash(hash){
        if( !navigator.onLine ) return this.localApi.validateHash(hash);

        const path = hash === 'debbug' ? `${process.env.PUBLIC_URL}/data/interview_example.json`  : this._getUri('form', {h: hash});

        return fetch(path)
                    .then((response)=>{
                        if (!response.ok || response.status !== 200) {
                            response.json().then(res => {
                                return this.redirectTo = res.url;
                            });
                            
                            throw Error(response.statusText);
                        }

                        return response.json();
                    })
                    .then((data) => {
                        data = this.dataInterface.interviewFromApi(data)

                        //setup remote api routes
                        if(data.setup) this.setApiRoutes(data.setup.routes)

                        return data;
                    })
                    .then((data)=>{
                        this.localApi.storeInterview(hash, data);
                        return data;
                    });
    }

    storeAnswers(h, answers, user, isComplete) {
      if(!navigator.onLine) return this._noConnection();

      let body = {
        h,
        answers,
        isComplete: isComplete ? true : false
      };

      if(user && user.name) body.name = user.name;
      if(user && user.email) body.email = user.email;

      let headers = this._getDefaultHeaders();

      return this.fetch('save', false, headers, body)
              .then((res) => {

                if (!res.ok || res.status !== 200) {
                    throw Error(res.statusText);
                }

                return res.json();
              })
    }

    setApiRoutes(routes) {
      if (routes && process.env.REACT_APP_DEPLOY_HOST !== 'development') {
        this.routes = routes;
      }
    }

    static getInstance() {
      return new RemoteAPI();
    }
}

export default RemoteAPI;
